import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import getPaymentCollectionMethod from "../../../../api/getPaymentCollectionMethod";
import {DetailsList, DetailsListLayoutMode, SelectionMode, Separator, Stack} from "@fluentui/react";
import Pagination from "../../../Theme/Pagination";
import {tableColumns} from "./tableColumns";
import Filters from "./Filters";

export default function OnlinePayments() {
    const navigate = useNavigate();

    const storedOnlinePaymentFilerSelection = localStorage.getItem('ONLINE_PAYMENTS_FILTER') ? JSON.parse(localStorage.getItem('ONLINE_PAYMENTS_FILTER')) : {paidAtFrom: null, paidAtTo: null, paidAtFastButtonId: null, sorting: 'PAID_AT_NEW', manager: 'ALL', status: 'ALL', text: '', services: []};
    const [filterSelection, setFilterSelection] = useState(storedOnlinePaymentFilerSelection);

    const [paymentCollection, setPaymentCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (!paymentCollection.loaded) {
            getPaymentCollectionMethod(
                filterSelection.text,
                filterSelection.status,
                filterSelection.manager,
                filterSelection.sorting,
                filterSelection.services,
                filterSelection.paidAtFrom,
                filterSelection.paidAtTo,
                page
            ).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setPaymentCollection({loaded: true, data: response});
            });
        }
    });

    const renewPage = (page) => {
        setPage(page);
        getPaymentCollectionMethod(
            filterSelection.text,
            filterSelection.status,
            filterSelection.manager,
            filterSelection.sorting,
            filterSelection.services,
            filterSelection.paidAtFrom,
            filterSelection.paidAtTo,
            page
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setPaymentCollection({loaded: true, data: response});
        });
    };

    const changeFilter = (newFilterSelection) => {
        localStorage.setItem('ONLINE_PAYMENTS_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);

        getPaymentCollectionMethod(
            newFilterSelection.text,
            newFilterSelection.status,
            newFilterSelection.manager,
            newFilterSelection.sorting,
            newFilterSelection.services,
            newFilterSelection.paidAtFrom,
            newFilterSelection.paidAtTo,
            page
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setPaymentCollection({loaded: true, data: response});
        });
    };

    return <>
        <Filters filterSelection={filterSelection} changeFilter={changeFilter} />
        <Stack horizontal tokens={{ childrenGap: 10 }} style={{marginTop: 20}}>
            <div>Найдено: <span style={{fontWeight: 600}}>{paymentCollection.data.count}</span> записей</div>
            <Separator vertical/>
            <div>Сумма поступлений: <span
                style={{fontWeight: 600}}>{new Intl.NumberFormat().format(paymentCollection.data.summaryIncome)}</span> руб.
            </div>
        </Stack>
        <DetailsList
            items={paymentCollection.data.collection}
            compact={false}
            columns={tableColumns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderRow={(props, Row) => {
                return <div style={{cursor: 'pointer'}} onClick={(e) => {
                    if (e.target.classList.contains('archive')) {
                        return;
                    }

                    navigate('/finance/payments/' + props.item.projectId + '/' + props.item.onlinePaymentId);
                }}>
                    <Row {...props} />
                </div>;
            }}
        />
        <div style={{marginTop: 20}}>
            <Pagination page={page} lastPage={paymentCollection.data.lastPage} renewPage={renewPage} />
        </div>
    </>;
}
