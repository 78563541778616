import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Breadcrumb, Pivot, PivotItem} from "@fluentui/react";
import PrepaidProjects from "../Finance/MainSection/PrepaidProjects/PrepaidProjects";
import WaitingClassicPaymentProjects
    from "../Finance/MainSection/WaitingClassicPaymentProjects/WaitingClassicPaymentProjects";
import WaitingClosingProjects from "../Finance/MainSection/WaitingClosingProjects/WaitingClosingProjects";
import OnlinePayments from "../Finance/MainSection/OnlinePayments/OnlinePayments";
import EmployeeCollection from "./Employee/EmployeeCollection";
import FreelancerCollection from "./Freelancer/FreelancerCollection";
import PCUserCollection from "./PCUser/PCUserCollection";
import TeamCollection from "../Team/TeamCollection";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";

export default function UserMainSection() {
    const navigate = useNavigate();
    const [activePivot, setActivePivot] = useState('paid');
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[]},profile:{specialization:null},type:null});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    let canSeeEmployees = false;
    let canSeeFreelancers = false;
    let canSeeTeams = false;
    let canSeePcUsers = false;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'see_employees') {
            canSeeEmployees = true;
        }
        if (permission.key === 'see_freelancers') {
            canSeeFreelancers = true;
        }
        if (permission.key === 'see_teams' && permission.type === 'ALL') {
            canSeeTeams = true;
        }
        if (permission.key === 'see_pc_users') {
            canSeePcUsers = true;
        }
    });

    const [breadcrumbs, setBreadcrumbs] = useState([
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Пользователи', key: 'users', href: '/users', isCurrentItem: true },
    ]);

    const hash = window.location.hash && window.location.hash.substring(1);
    if (activePivot !== 'employees' && hash === 'employees') {
        setActivePivot('employees');
    } else if (activePivot !== 'customers' && hash === 'customers') {
        setActivePivot('customers');
    } else if (activePivot !== 'teams' && hash === 'teams') {
        setActivePivot('teams');
    }

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 10}}
        />
        <Pivot aria-label="Меню" selectedKey={activePivot} linkFormat="tabs" styles={{link: {height: 32, marginRight: 10, backgroundColor: 'rgb(248 248 246)'}}} onLinkClick={(item) => {
            window.history.pushState('', '/', window.location.pathname);
            setActivePivot(item.props.itemKey);
        }}>
            {canSeeFreelancers && <PivotItem headerText="Исполнители" itemKey="freelancers">
                <div style={{marginTop: 20}}>
                    <FreelancerCollection />
                </div>
            </PivotItem>}
            {canSeeEmployees && <PivotItem headerText="Сотрудники" itemKey="employees">
                <div style={{marginTop: 20}}>
                    <EmployeeCollection />
                </div>
            </PivotItem>}
            {canSeePcUsers && <PivotItem headerText="Клиенты" itemKey="customers">
                <div style={{marginTop: 20}}>
                    <PCUserCollection />
                </div>
            </PivotItem>}
            {canSeeTeams && <PivotItem headerText="Команды" itemKey="teams">
                <div style={{marginTop: 20}}>
                    <TeamCollection />
                </div>
            </PivotItem>}
        </Pivot>
    </>);
};
