import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function getProjectWaitingRealCostsCollectionMethod(
    text,
    status,
    manager,
    startedAtFrom,
    startedAtTo,
    sorting,
    services = [],
    page,
    count = 10
) {
    const searchParams = new URLSearchParams({
        text,
        status,
        manager,
        startedAtFrom: startedAtFrom ? startedAtFrom : '',
        startedAtTo: startedAtTo ? startedAtTo : '',
        sorting,
        services: services.join(','),
        page,
        count
    });
    const url = new URL(serverUrl + '/api/v2/pc/waiting-real-costs-sign-projects?' + searchParams);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
