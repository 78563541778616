import * as React from "react";
import {Breadcrumb} from "@fluentui/react";

export default function Breadcrumbs({id, code, goBack = null, marginBottom = 20, onMain = null}) {
    const breadcrumbs = [
        { text: 'Brand Hub', key: 'main', onClick: onMain },
        { text: 'Проекты', key: 'projects', onClick: goBack },
        { text: code ?? id, key: 'project', href: '/projects/' + id, isCurrentItem: true },
    ];

    return <Breadcrumb
        items={breadcrumbs}
        maxDisplayedItems={10}
        ariaLabel="Навигация"
        overflowAriaLabel="Показать больше"
        style={{marginBottom: marginBottom}}
    />;
}
