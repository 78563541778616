import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import getProjectCollectionMethod from "../../../../api/getProjectCollectionMethod";
import Filters from "./Filters";
import {DetailsList, DetailsListLayoutMode, SelectionMode, Separator, Stack} from "@fluentui/react";
import Pagination from "../../../Theme/Pagination";
import {tableColumns} from "./tableColumns";

export default function PrepaidProjects() {
    const navigate = useNavigate();

    const storedFilterSelection = localStorage.getItem('FINANCE_FILTER') ? JSON.parse(localStorage.getItem('FINANCE_FILTER')) : {manager: 'ALL', artdir: 'ALL', status: 'ALL', milestone: 'ALL', startedAtFrom: null, startedAtTo: null, startedAtFastButtonId: null, presentationDateFrom: null, presentationDateTo: null, presentationDateFastButtonId: null, dueDateFrom: null, dueDateTo: null, dueDateFastButtonId: null, sorting: 'START_DATE_NEW', text: '', services: []};
    const [filterSelection, setFilterSelection] = useState(storedFilterSelection);

    const [page, setPage] = useState(1);
    const [projectCollection, setProjectCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1, sum: 0, deb: 0, spendingPlan: 0}});

    useEffect(() => {
        if (!projectCollection.loaded) {
            getProjectCollectionMethod(
                filterSelection.text,
                filterSelection.status,
                filterSelection.milestone,
                filterSelection.manager,
                filterSelection.artdir,
                filterSelection.startedAtFrom,
                filterSelection.startedAtTo,
                filterSelection.presentationDateFrom,
                filterSelection.presentationDateTo,
                filterSelection.dueDateFrom,
                filterSelection.dueDateTo,
                filterSelection.sorting,
                filterSelection.services,
                page,
                false,
                20
            ).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProjectCollection({loaded: true, data: response});
            });
        }
    });

    const reloadCollection = (freshFilterSelection, archived = false) => {
        getProjectCollectionMethod(
            freshFilterSelection.text,
            freshFilterSelection.status,
            freshFilterSelection.milestone,
            freshFilterSelection.manager,
            freshFilterSelection.artdir,
            freshFilterSelection.startedAtFrom,
            freshFilterSelection.startedAtTo,
            freshFilterSelection.presentationDateFrom,
            freshFilterSelection.presentationDateTo,
            freshFilterSelection.dueDateFrom,
            freshFilterSelection.dueDateTo,
            freshFilterSelection.sorting,
            freshFilterSelection.services,
            page,
            false,
            20
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getProjectCollectionMethod(
            filterSelection.text,
            filterSelection.status,
            filterSelection.milestone,
            filterSelection.manager,
            filterSelection.artdir,
            filterSelection.startedAtFrom,
            filterSelection.startedAtTo,
            filterSelection.presentationDateFrom,
            filterSelection.presentationDateTo,
            filterSelection.dueDateFrom,
            filterSelection.dueDateTo,
            filterSelection.sorting,
            filterSelection.services,
            page,
            false,
            20
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    const changeFilter = (newFilterSelection) => {
        localStorage.setItem('FINANCE_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    const projectsCountLastDigit = parseInt(projectCollection.data.count.toString().slice(-1));
    let projectsCountWord = '';
    if (projectsCountLastDigit === 1) {
        projectsCountWord = 'проект';
    } else if (projectsCountLastDigit > 1 && projectsCountLastDigit < 5) {
        projectsCountWord = 'проекта';
    } else {
        projectsCountWord = 'проектов';
    }

    return <>
        <Filters filterSelection={filterSelection} changeFilter={changeFilter} />
        <Stack horizontal tokens={{ childrenGap: 10 }} style={{marginTop: 20}}>
            <div>Найдено: <span style={{fontWeight: 600}}>{projectCollection.data.count}</span> {projectsCountWord} на сумму <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.sum)} руб.</span></div>
            {projectCollection.data.spendingPlan !== 0
                ? <>
                    <Separator vertical />
                    <div>План расходов: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.spendingPlan)} руб.</span></div>
                </>
                : <></>
            }
            <Separator vertical />
            <div>Дебиторка: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.deb)} руб.</span></div>
        </Stack>
        <DetailsList
            items={projectCollection.data.collection}
            compact={false}
            columns={tableColumns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderRow={(props, Row) => {
                return <div style={{cursor: 'pointer'}} onClick={(e) => {
                    if (e.target.classList.contains('archive')) {
                        return;
                    }

                    navigate('/finance/' + props.item.id);
                }}>
                    <Row {...props} />
                </div>;
            }}
        />
        <div style={{marginTop: 20}}>
            <Pagination page={page} lastPage={projectCollection.data.lastPage} renewPage={renewPage} />
        </div>
    </>;
};
