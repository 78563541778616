import * as React from "react";
import {useState} from "react";
import {Breadcrumb, Checkbox, DatePicker, DayOfWeek, PrimaryButton, Stack, TextField} from "@fluentui/react";
import addRequestMethod from "../../api/addRequestMethod";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {datePickerStringsRu} from "../data/datePickerStringsRu";

const initialForm = {
    contact: {
        name: null,
        email: null,
        phoneNumber: null,
        telegramUsername: null,
        instagramUsername: null
    },
    sourceType: null,
    companyName: null,
    createdAt: null
};

export default function AddRequest() {
    const [form, setForm] = useState(initialForm);
    const [sendNotification, setSendNotification] = useState(1);
    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Лиды', key: 'leads', href: '/leads' },
        { text: 'Добавить', key: 'lead', href: '/leads/new', isCurrentItem: true },
    ];

    const submit = () => {
        addRequestMethod(form.contact, form.sourceType, form.companyName, form.createdAt?.toLocaleString('sv-SE'), sendNotification).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            navigate('/leads/' + response.id);
        });
    };

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    return (<>
        <div style={{fontSize: '24px', fontWeight: 'bold', marginBottom: 10}}>Добавить лида</div>
        <Stack horizontal tokens={{ childrenGap: 50 }}>
            <div>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <Dropdown
                        placeholder="Выберите"
                        label="Источник"
                        options={[
                            {key: 'DEPOT', text: 'Depot'},
                            {key: 'CHROME', text: 'ХРОМ'},
                            {key: 'UPSELL', text: 'Upsell'},
                            {key: 'WEBSITE', text: 'Сайт Brand Hub'},
                            {key: 'WHATSAPP', text: 'Написали в WhatsApp'},
                            {key: 'TELEGRAM', text: 'Написали в Telegram'},
                            {key: 'INSTAGRAM', text: 'Написали в Instagram'},
                            {key: 'EMAIL', text: 'Написали на почту'},
                            {key: 'CALL', text: 'Позвонили'},
                            {key: 'TELEMARKETING', text: 'Телемаркетинг'},
                        ]}
                        style={{marginBottom: 10}}
                        selectedKey={form.sourceType}
                        onChange={(e, option) => setForm({...form, sourceType: option.key})}
                    />
                    <TextField label="Имя" value={form.contact.name} onChange={(e) => setForm({...form, contact: {...form.contact, name: e.target.value}})} />
                    <TextField label="Телефон" value={form.contact.phoneNumber} onChange={(e) => setForm({...form, contact: {...form.contact, phoneNumber: e.target.value}})} />
                    <TextField label="Email" value={form.contact.email} onChange={(e) => setForm({...form, contact: {...form.contact, email: e.target.value}})} />
                    <TextField label="Telegram username" value={form.contact.telegramUsername} onChange={(e) => setForm({...form, contact: {...form.contact, telegramUsername: e.target.value}})} />
                    <TextField label="Instagram username" value={form.contact.instagramUsername} onChange={(e) => setForm({...form, contact: {...form.contact, instagramUsername: e.target.value}})} />
                    <DatePicker
                        firstDayOfWeek={DayOfWeek.Monday}
                        showWeekNumbers={false}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Выберите дату"
                        ariaLabel="Выберите дату"
                        label="Дата заявки"
                        strings={datePickerStringsRu}
                        formatDate={formatDate}
                        value={form.createdAt ?? (new Date())}
                        onSelectDate={(date) => setForm({...form, createdAt: date})}
                        styles={{ textField: {color: 'black'}}}
                        maxDate={new Date()}
                    />
                    <Checkbox label="Отправить уведомление о заявке" checked={sendNotification} onChange={(e) => setSendNotification(e.target.checked ? 1 : 0)} />
                </Stack>
            </div>
        </Stack>
        <PrimaryButton style={{marginTop: 30, height: 40}} text="Сохранить" onClick={submit} />
    </>);
};
