import * as React from "react";
import {FontSizes} from "@fluentui/theme";
import {
    Breadcrumb, Callout, CommandBar,
    DefaultFontStyles,
    DetailsList,
    DetailsListLayoutMode,
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    getTheme, Icon, IconButton,
    Pivot,
    PivotItem,
    ProgressIndicator,
    SelectionMode,
    Separator, Shimmer,
    ShimmerElementsGroup, ShimmerElementType,
    Spinner,
    SpinnerSize,
    TooltipHost
} from "@fluentui/react";
import {useEffect, useState} from "react";
import getAnalyticMethod from "../api/getAnalyticMethod";
import {VerticalBarChart, DonutChart} from "@fluentui/react-charting";
import getCurrentUserMethod from "../api/getCurrentUserMethod";
import {useNavigate} from "react-router-dom";
import DateRangePicker from "./Shared/DateRangePicker";
import {AreaChart} from "@uifabric/charting";
import exportYearlyStatisticsToExcelMethod from "../api/exportYearlyStatisticsToExcelMethod";
import exportYearlyStatisticsByProjectsToExcelMethod from "../api/exportYearlyStatisticsByProjectsToExcelMethod";
import exportSalesStatisticsToExcelMethod from "../api/exportSalesStatisticsToExcelMethod";
import {useBoolean, useId} from "@fluentui/react-hooks";

const initialAnalytic = {
    // visitsToday: 0,
    // leadsToday: 0,
    // leadsIn30Days: 0,
    leadsCountPerEveryMonth: [],
    leadsBySources: [],
    currentYearProjectSum: 0,
    currentYearProjectCount: 0,
    yearlyPlan: 0,
    yearlyFact: 0,
    projectsInProgress: 0,
    projectsDone: 0,
    projectsCountByTypes: {},
    projectsOnSigning: 0,
    leadsMonthlyCountByStatuses: {},
    projectsCosts: 0,
    summarySales: {
        summarySalesSum: 0,
        clearContractsSum: 0,
        additionalWorksSum: 0,
        downgradeContracts: 0,
        lostSumOfDowngradeContracts: 0,
        canceledContracts: 0,
        lostSumOfCanceledContracts: 0,
    }
};

const theme = getTheme();

export default function MainPage() {
    const navigate = useNavigate();
    const [analytic, setAnalytic] = useState({loaded: false, data: initialAnalytic});
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[],status:''},type:''});
    const [dates, setDates] = useState([null, null, 'CURRENT_YEAR']);
    const [isSalesInfoVisible, { setTrue: showSalesInfo, setFalse: hideSalesInfo }] = useBoolean(false);
    const salesInfoButtonId = useId('sales-info-button');

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    useEffect(() => {
        if (!analytic.loaded) {
            getAnalyticMethod(dates[0], dates[1]).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setDates([response.fromDate, response.toDate, 'CURRENT_YEAR']);
                setAnalytic({loaded: true, data: response});
            });
        }
    });

    const changeDates = (fromDate, toDate, fastSelectButtonId) => {
        setDates([fromDate, toDate, fastSelectButtonId]);
        getAnalyticMethod(fromDate, toDate).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setAnalytic({loaded: true, data: response});
        });
    };

    const axisStyle = {
        stroke: '#ef5021',
    };
    const textStyle = {
        fill: '#ef5021',
        fontSize: '10px',
    };

    const customStyles = () => {
        return {
            chart: {
                paddingBottom: '25px',
            },
            chartLabel: {
                color: '#ef5021',
                ...DefaultFontStyles.large,
            },
            xAxisDomain: axisStyle,
            xAxisTicks: axisStyle,
            yAxisDomain: axisStyle,
            yAxisTicks: axisStyle,
            yAxisText: textStyle,
        };
    };

    const leadsBySourcesColumns = [
        {
            key: 'pageName',
            name: 'Страница',
            fieldName: 'pageName',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
        },
        {
            key: 'count',
            name: 'Кол-во лидов',
            fieldName: 'count',
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
        },
    ];

    const { palette, fonts } = theme;

    let hasAccess = null;
    let canSeeVisitStatistics = false;
    let canSeePageRating = false;
    let canSeeLeadStatistics = false;
    let canSeeContractStatistics = false;
    let canSeeContractStatisticsTeamId = null;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'see_visit_statistics' && permission.type === 'ALL') {
            canSeeVisitStatistics = true;
        }

        if (permission.key === 'see_page_rating' && permission.type === 'ALL') {
            canSeePageRating = true;
        }

        if (permission.key === 'see_leads_statistics') {
            canSeeLeadStatistics = true;
        }

        if (permission.key === 'see_contracts_statistics') {
            canSeeContractStatistics = true;
            if (permission.teamId !== 'null' && permission.teamId !== null) {
                canSeeContractStatisticsTeamId = permission.teamId;
            }
        }

        if (permission.key === 'analytic_module') {
            hasAccess = true;
        }
    });

    if (user.contact.email.length === 0 || !analytic.loaded) {
        return <div style={{marginTop: 25}}>
            <Shimmer shimmerElements={[
                { type: ShimmerElementType.line, height: 25 },
            ]} width={300} shimmerColors={{shimmer: '#fafafa'}} />
            <Shimmer style={{marginTop: 20}} shimmerElements={[
                { type: ShimmerElementType.line, height: 30 },
            ]} width={185} shimmerColors={{shimmer: '#fafafa'}} />

            <div style={{marginTop: 20, display: 'flex', gap: 16}}>
                <div>
                    <Shimmer shimmerElements={[
                        { type: ShimmerElementType.line, height: 218, width: 300 },
                        { type: ShimmerElementType.gap, height: 218, width: 16 },
                        { type: ShimmerElementType.line, height: 218, width: 300 },
                    ]} width={616} shimmerColors={{shimmer: '#fafafa'}} />
                    <Shimmer style={{marginTop: 16}} shimmerElements={[
                        { type: ShimmerElementType.line, height: 402 },
                    ]} width={616} shimmerColors={{shimmer: '#fafafa'}} />
                </div>
                <div>
                    <Shimmer shimmerElements={[
                        { type: ShimmerElementType.line, height: 310 },
                    ]} width={320} shimmerColors={{shimmer: '#fafafa'}} />
                    <Shimmer style={{marginTop: 16}} shimmerElements={[
                        { type: ShimmerElementType.line, height: 310 },
                    ]} width={320} shimmerColors={{shimmer: '#fafafa'}} />
                </div>
            </div>
        </div>
    }

    if (!hasAccess && user.type !== 'FREELANCER') {
        navigate('/projects');
    }

    const colors = ['#EF5021', '#F09A22', '#5F8082', '#9ED3D7', '#D7BF9E', '#1D1D1D'];
    //const colors = ['#EF5021', '#F02274', '#F07622', '#F02E22', '#F09A22', '#F08F71'];
    //const colors = ['#EF5021', '#3051DB', '#84DB30', '#719C49', '#86584A', '#4A5686'];
    //const colors = ['#EF5021', '#825F6D', '#5F826D', '#9ED7B5', '#D7AC9E', '#67FFA3'];
    //const colors = ['#EF5021', '#5F6682', '#82715F', '#5F7882', '#D7BC9E', '#91A7FF'];

    const hours = new Date().getHours();
    let greeting = 'Доброй ночи';
    let emoji = '🌙';

    if (hours >= 23) {
        greeting = 'Доброй ночи';
        emoji = '🌙';
    } else if (hours >= 17) {
        greeting = 'Добрый вечер';
        emoji = '🌙';
    } else if (hours >= 12) {
        greeting = 'Добрый день';
        emoji = '☀️';
    } else if (hours >= 5) {
        greeting = 'Доброе утро';
        emoji = '☀️';
    }

    if (user.type === 'FREELANCER') {
        return (<div>
            {user.contact.firstName && user.contact.firstName.length > 0
                ? <div style={{ fontSize: FontSizes.size20, marginBottom: 30, fontWeight: 600 }}>{greeting}, {user.contact.firstName} {emoji}</div>
                : <div style={{ fontSize: FontSizes.size20, marginBottom: 30, fontWeight: 600 }}>{greeting} {emoji}</div>
            }
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{paddingLeft: 0, paddingRight: 16}}>
                        <div style={{ fontSize: FontSizes.size18, marginBottom: 10, fontWeight: 600 }}>Проектная статистика</div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20}}>
                            <div>
                                <DocumentCard type={DocumentCardType.compact} style={{padding: 10, display: 'block', height: 'unset'}}>
                                    <div style={{marginBottom: 5}}>Всего проектов: <span style={{color: '#EF5121'}}>{analytic.data.currentYearProjectCount} шт.</span></div>
                                    <div style={{marginBottom: 5}}>Текущие: <span style={{color: '#EF5121'}}>{analytic.data.projectsInProgress} шт.</span></div>
                                    <div style={{marginBottom: 2}}>Завершенные: <span style={{color: '#EF5121'}}>{analytic.data.projectsDone} шт.</span></div>
                                </DocumentCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return (<div style={{marginTop: 20}}>
        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
            <div style={{width: '100%'}}>
                {user.contact.firstName && user.contact.firstName.length > 0
                    ? <div style={{ fontSize: FontSizes.size18, marginBottom: 35, fontWeight: 600, color: '#1d1d1d' }}>{greeting}, {user.contact.firstName} {emoji}</div>
                    : <div style={{ fontSize: FontSizes.size18, marginBottom: 35, fontWeight: 600, color: '#1d1d1d' }}>{greeting} {emoji}</div>
                }
            </div>
            <div>
                {canSeeContractStatistics &&
                <CommandBar
                    styles={{root: {paddingLeft: 0, paddingRight: 0}}}
                    overflowItems={[
                        {
                            key: 'statistics',
                            text: 'Суммарная статистика ' + (new Date()).getUTCFullYear() + ' года',
                            iconProps: { iconName: 'ExcelDocument' },
                            onClick: () => exportYearlyStatisticsToExcelMethod(),
                        },
                        {
                            key: 'byProjects',
                            text: 'Статистика по проектам ' + (new Date()).getUTCFullYear() + ' года',
                            iconProps: { iconName: 'ExcelDocument' },
                            onClick: () => exportYearlyStatisticsByProjectsToExcelMethod(),
                        },
                        {
                            key: 'sales',
                            text: 'Статистика NEWBIZ ' + (new Date()).getUTCFullYear() + ' года',
                            iconProps: { iconName: 'ExcelDocument' },
                            onClick: () => exportSalesStatisticsToExcelMethod(false),
                        },
                        {
                            key: 'salesUpsell',
                            text: 'Статистика UPSELL ' + (new Date()).getUTCFullYear() + ' года',
                            iconProps: { iconName: 'ExcelDocument' },
                            onClick: () => exportSalesStatisticsToExcelMethod(true),
                        },
                    ]}
                    items={[]}
                />}
            </div>
        </div>
        {/*<Breadcrumb*/}
        {/*    items={[*/}
        {/*        { text: 'Brand Hub', key: 'main', isCurrentItem: true },*/}
        {/*    ]}*/}
        {/*    maxDisplayedItems={10}*/}
        {/*    ariaLabel="Навигация"*/}
        {/*    overflowAriaLabel="Показать больше"*/}
        {/*    style={{marginBottom: 0}}*/}
        {/*/>*/}
        {/*<Separator style={{paddingTop: 0}} />*/}
        {/*<div>Brand Hub</div>*/}
        {/*<div style={{width: 950}}>*/}
        {/*    <Separator />*/}
        {/*</div>*/}

        <div className="ms-Grid" dir="ltr" style={{marginTop: 15, paddingLeft: 0}}>
            <div style={{paddingRight: 16, marginBottom: 10, marginTop: -10}}>
                <DateRangePicker
                    onChange={(dateFrom, dateTo, fastSelectButtonId) => changeDates(dateFrom, dateTo, fastSelectButtonId)}
                    defaultSelectedButtonId={dates[2]}
                    excludeButtons={['ALL']}
                />
            </div>

            <div style={{marginTop: 20, display: 'flex', gap: 16}}>
                <div>
                    <div style={{display: 'flex', gap: 16}}>
                        {canSeeContractStatistics && canSeeContractStatisticsTeamId === null && <div style={{width: 300}}>
                            <div style={{border: '1px solid #edebe9', padding: 10}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{color: 'gray'}}>Сумма контрактов</div>
                                    <Icon id={salesInfoButtonId} iconName='Info' style={{color: '#ef5021', verticalAlign: 'middle', cursor: 'pointer'}} onClick={showSalesInfo} />
                                </div>
                                {isSalesInfoVisible && <Callout
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${salesInfoButtonId}`}
                                    onDismiss={hideSalesInfo}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20, paddingBottom: 20}}>
                                        <div style={{width: 300}}>
                                            <div style={{color: 'gray', fontSize: 12}}>Сумма продаж за {(new Date()).getUTCFullYear()} год:</div>
                                            <div style={{padding: 0, paddingBottom: 5, paddingTop: 5}}>
                                                <div style={{color: '#999', fontSize: 16, fontWeight: 600, marginBottom: 0}}>{new Intl.NumberFormat().format(analytic.data.summarySales.summarySalesSum) + ' руб.'}</div>
                                                <div style={{color: '#999', marginTop: 5, fontSize: 12}}>
                                                    Сумма первичных контрактов: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(analytic.data.summarySales.clearContractsSum)} руб.</span>
                                                    <br />
                                                    Сумма доп. работ: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(analytic.data.summarySales.additionalWorksSum)} руб.</span>
                                                    <br />
                                                    {analytic.data.summarySales.downgradeContracts !== 0 && <>
                                                        Отказ от части работ: <span style={{fontWeight: 600}}>{analytic.data.summarySales.downgradeContracts} на сумму -{new Intl.NumberFormat().format(analytic.data.summarySales.lostSumOfDowngradeContracts)} руб.</span>
                                                        <br />
                                                    </>}
                                                    Расторжения: <span style={{fontWeight: 600}}>{analytic.data.summarySales.canceledContracts} на сумму -{new Intl.NumberFormat().format(analytic.data.summarySales.lostSumOfCanceledContracts)} руб.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Callout>}
                                <div style={{padding: 20, paddingBottom: 15}}>
                                    <div style={{display: 'flex', justifyContent: 'center', fontSize: 26, fontWeight: 600, marginBottom: 10, cursor: 'pointer'}} onClick={() => navigate(`/projects?startedAtFrom=${dates[0]}&startedAtTo=${dates[1]}`)}>{new Intl.NumberFormat().format(analytic.data.currentYearProjectSum) + ' руб.'}</div>
                                    <ProgressIndicator label="" description={new Intl.NumberFormat().format(analytic.data.currentYearProjectSum) + ' руб. / ' + new Intl.NumberFormat().format(analytic.data.yearlyPlan) + ' руб.'} percentComplete={analytic.data.currentYearProjectSum / analytic.data.yearlyPlan} />
                                    <div style={{color: '#999', marginTop: 30, fontSize: 12}}>
                                        Сумма поступлений: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(analytic.data.yearlyFact)} руб.</span>
                                        <br />
                                        Сумма списаний: <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(analytic.data.projectsCosts)} руб.</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {canSeeContractStatistics && <div style={{width: 300}}>
                            <div style={{border: '1px solid #edebe9', padding: 10}}>
                                <div style={{color: 'gray'}}>Кол-во контрактов</div>
                                <div style={{padding: 20, paddingBottom: 10}}>
                                    <div style={{display: 'flex', justifyContent: 'center', fontSize: 26, fontWeight: 600, marginBottom: 10}}>{analytic.data.currentYearProjectCount}</div>
                                    {canSeeContractStatisticsTeamId === null && <ProgressIndicator label="" description={analytic.data.currentYearProjectCount + ' / 210'} percentComplete={analytic.data.currentYearProjectCount / 210} />}
                                    <div style={{color: '#999', marginTop: 20, fontSize: 12}}>
                                        <div>Текущие: <span onClick={() => navigate(`/projects?startedAtFrom=${dates[0]}&startedAtTo=${dates[1]}&status=IN_PROGRESS`)} style={{fontWeight: 600, cursor: 'pointer'}}>{analytic.data.projectsInProgress}</span></div>
                                        {canSeeContractStatisticsTeamId === null
                                            ? <div>На подписании: <span onClick={() => navigate(`/leads?status=WIN&prepaymentStatus=WAITING_PAYMENT`)} style={{fontWeight: 600, cursor: 'pointer'}}>{analytic.data.projectsOnSigning}</span></div>
                                            : <></>
                                        }
                                        <div>Завершенные: <span onClick={() => navigate(`/projects?lastPaidAtFrom=${dates[0]}&lastPaidAtTo=${dates[1]}`)} style={{fontWeight: 600, cursor: 'pointer'}}>{analytic.data.projectsDone}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                    {canSeeLeadStatistics && <div style={{width: 616, marginTop: canSeeContractStatistics ? 16 : 0}}>
                        <div style={{border: '1px solid #edebe9', padding: 10, height: 380}}>
                            <div style={{color: 'gray'}}>Лиды</div>
                            <div style={{paddingTop: 10}}>
                                <VerticalBarChart
                                    chartTitle="Лиды"
                                    data={Object.values(analytic.data.leadsCountPerEveryMonth).map((item) => { return {x: item.date, y: item.count, color: '#ef5021'} })}
                                    hideLegend={true}
                                    styles={customStyles}
                                    barWidth={22}
                                    maxBarWidth={40}
                                    hideLabels={false}
                                    rotateXAxisLables={false}
                                    enableReflow={true}
                                />
                            </div>
                        </div>
                    </div>}
                </div>
                <div>
                    <div style={{border: '1px solid #edebe9', padding: 10, width: 300}}>
                        <div style={{color: 'gray'}}>Типы проектов</div>
                        <div style={{padding: 20, paddingBottom: 10}}>
                            <DonutChart
                                height={240}
                                data={{chartData: Object.entries(analytic.data.projectsCountByTypes).map((item, number) => {
                                        return { legend: item[0], data: item[1], color: colors[number]};
                                    })}}
                                innerRadius={100}
                                legendProps={{allowFocusOnLegends: true}}
                                hideLegend={false}
                                hideLabels={false}
                                showLabelsInPercent={true}
                            />
                        </div>
                    </div>

                    {canSeePageRating && <div style={{marginTop: 16}}>
                        <div style={{border: '1px solid #edebe9', padding: 10, height: 288, width: 300}}>
                            <div style={{color: 'gray'}}>Источники лидов</div>
                            <div style={{marginTop: 10, overflowY: 'auto', height: 260}}>
                                <DetailsList
                                    styles={{root: {maxWidth: 'unset'}}}
                                    items={analytic.data.leadsBySources}
                                    compact={true}
                                    columns={leadsBySourcesColumns}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item, index) => item.key}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={false}
                                    className="noBorderList noMarginTop"
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </div>)
};
