import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {InitialProjectData} from "../Project/ViewProject/Data/InitialProjectData";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";
import getSingleProjectMethod from "../../api/getSingleProjectMethod";
import {
    ActionButton,
    Breadcrumb,
    DetailsList, DetailsListLayoutMode, Icon,
    mergeStyleSets, ProgressIndicator,
    SelectionMode,
    Separator,
    Stack,
    TextField,
    TooltipHost
} from "@fluentui/react";
import changeRequestContactMethod from "../../api/changeRequestContactMethod";
import {FontSizes} from "@fluentui/theme";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import exportRequisitesToExcelMethod from "../../api/exportRequisitesToExcelMethod";
import changeRequestCompanyMethod from "../../api/changeRequestCompanyMethod";
import Notification from "../notifications/Notification";
import storeFileMethod from "../../api/storeFileMethod";
import attachDocumentMethod from "../../api/attachDocumentMethod";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeDocumentMethod from "../../api/removeDocumentMethod";
import sendPrepaymentDocumentsMethod from "../../api/sendPrepaymentDocumentsMethod";
import setDocsDoneMethod from "../../api/setDocsDoneMethod";
import {fileIconClassNames} from "../data/fileIconClassNames";
import {getFileIcon} from "../data/getFileIcon";
import removeCommercialOfferMethod from "../../api/removeCommercialOfferMethod";
import {datePickerStringsRu} from "../data/datePickerStringsRu";
import {RussianName} from "../data/RussianName";

export default function ViewMakeDocument() {
    const params = useParams();
    const [project, setProject] = useState({loaded: false, data: InitialProjectData});
    const [notification, setNotification] = useState({type: 'hidden', text: ''});
    const [percentComplete, setPercentComplete] = React.useState(0);

    const reloadData = () => setProject({loaded: false, data: {...project.data}});

    useEffect(() => {
        if (!project.loaded) {
            getSingleProjectMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProject({loaded: true, data: response});
            });
        }
    });

    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Документы', key: 'waitingDocumentsProjectCollection', href: '/waiting-docs-projects', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: project.data.code ?? project.data.id, key: 'waitingDocumentsProject', href: '/waiting-docs-projects/' + project.data.id, isCurrentItem: true },
    ];

    const getCommercialOffers = () => project.data.files.filter((file) => file.type === 'COMMERCIAL_OFFER');

    const commercialOfferFileColumns = [
        {
            key: 'fileType',
            name: 'Тип файла',
            className: fileIconClassNames.fileIconCell,
            iconClassName: fileIconClassNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => (
                <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    <img src={getFileIcon(item.extension).url} className={fileIconClassNames.fileIconImg} alt={`${item.extension} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.name}</span>,
        },
    ];

    const changeContact = (key, value) => {
        const contact = {...project.data.contact, [key]: value};
        setProject({...project, data: {...project.data, contact: contact}});

        changeRequestContactMethod(project.data.id, contact).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Контакты клиента сохранены'});
        });
    };

    const changeCompany = (key, value) => {
        const company = {...project.data.company, [key]: value};

        if (key !== 'taxIdentificationNumber' && key !== 'bandIdentificationCode') {
            setProject({...project, data: {...project.data, company: company}});

            changeRequestCompanyMethod(project.data.id, company).then(response => {
                if (response.exception || response.error) {
                    console.log(response.error ? response.error.message : response.exception);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setNotification({type: 'success', text: 'Данные компании сохранены'});
            });
        } else if ((key === 'taxIdentificationNumber' && (value.length === 10 || value.length === 12 || value.length === 0)) || (key === 'bandIdentificationCode' && (value.length === 9 || value.length === 0))) {
            changeRequestCompanyMethod(project.data.id, company).then(response => {
                if (response.exception || response.error) {
                    console.log(response.error ? response.error.message : response.exception);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setNotification({type: 'success', text: 'Данные компании сохранены'});
                reloadData();
            });
        } else {
            setProject({...project, data: {...project.data, company: company}});
        }
    };

    let documentsRender = '';

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });

    // DOCUMENTS

    const getDocuments = () => project.data.files.filter((file) => file.type === 'DOCUMENT');

    const handleDocumentChange = async (e) => {
        if (e.target.files) {
            for (const file of e.target.files) {
                let response = await storeFileMethod(file, setPercentComplete);
                response = await attachDocumentMethod(project.data.id, response.id);

                if (response.status === 'ok') {
                    reloadData();
                }
            }
        }
    };

    const getFileIcon = (docType) => {
        return {
            docType,
            url: `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/16/${docType}.svg`,
        };
    };

    const documentsColumns = [
        {
            key: 'fileType',
            name: 'Тип файла',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => (
                <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    <img src={getFileIcon(item.extension).url} className={classNames.fileIconImg} alt={`${item.extension} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.name}</span>,
        },
        // {
        //     key: 'status',
        //     name: 'Статус',
        //     fieldName: 'status',
        //     minWidth: 210,
        //     maxWidth: 350,
        //     isRowHeader: true,
        //     isResizable: true,
        //     data: 'string',
        //     isPadded: true,
        //     onRender: (item) => {
        //         if (item.type === 'SIGNED_DOCUMENT') {
        //             return <></>;
        //         }
        //
        //         return <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
        //         {item.status === 'ATTACHED'
        //             ? 'Ожидает отправки'
        //             : <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
        //                 <FontIcon aria-label="Accept" iconName="Accept" />
        //                 <div>Отправлено</div>
        //             </div>}
        //     </span>
        //     },
        // },
        {
            key: 'action',
            name: 'Действие',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                // if (project.data.finance.prepayment.status === 'PAID') {
                //     return <></>;
                // }

                return <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                    await removeDocumentMethod(project.data.id, item.id);
                    reloadData();
                }} />
            },
        },
    ];

    const sendDocuments = async () => {
        const response = await sendPrepaymentDocumentsMethod(project.data.id);
        if (response.exception || response.error) {
            console.log(response.error ? response.error.message : response.exception);
            setNotification({type: 'error', text: response.error});
            return;
        }

        if (response.status === 'ok') {
            reloadData();
        }
    };

    const hasNotSentDocuments = () => {
        let hasNotSent = false;

        getDocuments().forEach((item) => {
            if (item.status === 'ATTACHED') {
                hasNotSent = true;
            }
        });

        return hasNotSent;
    };

    documentsRender = <>
        {getDocuments().length > 0
            ? <DetailsList
                items={getDocuments()}
                compact={false}
                columns={documentsColumns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
                className="noBorderList noMarginTop"
            />
            : <></>
        }
        {getDocuments().length > 0
            ? <><div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 10}}>
                <div>
                    <input id="documentFileInput" type="file" onChange={handleDocumentChange} multiple={true} hidden />
                    {percentComplete > 0
                        ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            {percentComplete === 1
                                ? <ProgressIndicator description="Шаг 2 из 2. Обработка и сохранение файла в системе" />
                                : <ProgressIndicator description="Шаг 1 из 2. Загрузка файла" percentComplete={percentComplete} />
                            }
                        </Stack>
                        : <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={() => document.getElementById("documentFileInput").click()}>
                            Прикрепить документ
                        </ActionButton>
                    }
                </div>
                {/*{hasNotSentDocuments()*/}
                {/*    ? <PrimaryButton*/}
                {/*        text="Отправить документы клиенту"*/}
                {/*        iconProps={{ iconName: 'Mail' }}*/}
                {/*        allowDisabledFocus*/}
                {/*        onClick={sendDocuments}*/}
                {/*    />*/}
                {/*    : <></>}*/}
            </div>
                <div style={{marginTop: 40, marginBottom: 20}}>
                    <PrimaryButton text="Документы готовы" iconProps={{ iconName: 'Accept' }} onClick={async () => {
                        await setDocsDoneMethod(project.data.id);
                        navigate('/waiting-docs-projects')
                    }} />
                </div>
            </>
            : <div style={{marginTop: 10}}>
                <input id="documentFileInput" type="file" onChange={handleDocumentChange} multiple={true} hidden />
                {percentComplete > 0
                    ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                        {percentComplete === 1
                            ? <ProgressIndicator description="Шаг 2 из 2. Обработка и сохранение файла в системе" />
                            : <ProgressIndicator description="Шаг 1 из 2. Загрузка файла" percentComplete={percentComplete} />
                        }
                    </Stack>
                    : <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={() => document.getElementById("documentFileInput").click()}>
                        Прикрепить документ
                    </ActionButton>
                }
            </div>
        }
    </>;

    const formatDate = (date) => {
        if (!date) return '';
        const month = `${date.getMonth()}`.padStart(2, "0");
        const day = `${date.getDate()}`.padStart(2, "0");
        const year = date.getFullYear();

        return `${day}.${month}.${year} г.`;
    }

    let fullRussianName = '';
    if (project.data.company.type === 'LEGAL' && project.data.company.managerName) {
        const nameArr = project.data.company.managerName.split(' ');
        const name = nameArr[1]; const surname = nameArr[0]; const patronymic = nameArr[2];
        const russianName = new RussianName(surname, name, patronymic);
        fullRussianName = russianName.lastName('genitive') + ' ' + russianName.firstName('genitive') + ' ' + russianName.middleName('genitive');
    }

    const fields = [
        {
            param: null,
            value: project.data.company.name,
        },
        {
            param: 'ИНН',
            value: project.data.company.taxIdentificationNumber,
        }
    ];

    if (project.data.company.type !== 'INDIVIDUAL') {
        fields.push({
            param: 'КПП',
            value: project.data.company.registrationReasonCode,
        });
    }

    fields.push({
        param: project.data.company.type !== 'INDIVIDUAL' ? 'ОГРН' : 'ОГРНИП',
        value: project.data.company.mainRegistrationNumber,
    });
    fields.push({
        param: 'ОКПО',
        value: project.data.company.organizationsClassifierCode,
    });
    fields.push({
        param: 'Юридический адрес:',
        value: project.data.company.legalAddress,
    });
    fields.push({
        param: 'Почтовый адрес:',
        value: project.data.company.mailingAddress,
    });

    fields.push({
        param: 'Рассчётный счёт',
        value: project.data.company.checkingAccount,
    });
    fields.push({
        param: 'Банк',
        value: project.data.company.bankName,
    });
    fields.push({
        param: 'БИК',
        value: project.data.company.bandIdentificationCode,
    });
    fields.push({
        param: 'Корреспондентский счёт',
        value: project.data.company.correspondentAccount,
    });

    let customer = '';

    if (project.data.contact.name !== null) {
        customer = (project.data.contact.lastName ?? '') + ' ' + (project.data.contact.firstName ?? project.data.contact.name) + ' ' + (project.data.contact.middleName ?? '');
    }

    if (project.data.company !== null && project.data.company.name !== null) {
        customer = project.data.company.name;
    }

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 10}}
        />
        <div style={{marginBottom: 10}}>
            <div style={{ fontSize: FontSizes.size20, marginBottom: 0, fontWeight: 600, marginLeft: 0 }}>{customer}</div>
        </div>
        <Notification type={notification.type} text={notification.text} />
        <div style={{marginTop: 10}}>
            {/*{getDocuments().length > 0 && <div style={{marginTop: 20, marginBottom: 20}}>*/}
            {/*    <PrimaryButton text="Документы готовы" iconProps={{ iconName: 'Accept' }} onClick={async () => {*/}
            {/*        await setDocsDoneMethod(project.data.id);*/}
            {/*        navigate('/waiting-docs-projects')*/}
            {/*    }} />*/}
            {/*</div>}*/}
            {project.data.finance.prepayment.comment && project.data.finance.prepayment.comment.length > 0
                ? <>
                    <div style={{ fontSize: FontSizes.size14, marginTop: 10, fontWeight: 'bold', color: '#999999' }}>Комментарий</div>
                    <div style={{marginBottom: 10}} dangerouslySetInnerHTML={{__html: project.data.finance.prepayment.comment}} />
                </>
                : <></>
            }
            <div style={{ fontSize: FontSizes.size14, marginTop: 10, fontWeight: 'bold', color: '#999999' }}>Услуги</div>
            <div style={{marginBottom: 10}}>{project.data.services.map((item) => item.name).join(', ')}</div>
            <div style={{ fontSize: FontSizes.size14, marginTop: 10, fontWeight: 'bold', color: '#999999' }}>Сумма проекта</div>
            <div style={{marginBottom: 10}}>{new Intl.NumberFormat().format(project.data.finance.summaryPrice)} руб.</div>
            {getCommercialOffers().length === 0
                ? <></>
                : <div style={{marginBottom: 10}}>
                    <div style={{ fontSize: FontSizes.size14, marginBottom: 5, marginTop: 10, fontWeight: 'bold', color: '#999999' }}>Коммерческое предложение</div>
                    <DetailsList
                        items={getCommercialOffers()}
                        compact={false}
                        columns={commercialOfferFileColumns}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.key}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={false}
                        className="noBorderList noMarginTop"
                    />
                </div>
            }
        </div>
        <div>
            {/*<Separator />*/}
            <div style={{ fontSize: FontSizes.size14, marginTop: 10, fontWeight: 'bold', color: '#999999' }}>Реквизиты</div>
            <Stack horizontal tokens={{ childrenGap: 50 }} style={{marginTop: 5}}>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <TextField label="Фамилия" value={project.data.contact.lastName} onChange={(e) => changeContact('lastName', e.target.value)} />
                    <TextField label="Имя" value={project.data.contact.name} onChange={(e) => changeContact('name', e.target.value)} />
                    <TextField label="Отчество" value={project.data.contact.middleName} onChange={(e) => changeContact('middleName', e.target.value)} />
                    <TextField label="Телефон" value={project.data.contact.phoneNumber} onChange={(e) => changeContact('phoneNumber', e.target.value)} />
                    <TextField label="Email" value={project.data.contact.email} onChange={(e) => changeContact('email', e.target.value)} />
                    <TextField label="Telegram username" value={project.data.contact.telegramUsername} onChange={(e) => changeContact('telegramUsername', e.target.value)} />
                    <TextField label="Instagram username" value={project.data.contact.instagramUsername} onChange={(e) => changeContact('instagramUsername', e.target.value)} />
                </Stack>
                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    <TextField label={<span>ИНН <Icon iconName="Globe" style={{fontSize: 12, paddingLeft: 3, color: '#999999'}} /></span>} value={project.data.company.taxIdentificationNumber} onChange={(e) => changeCompany('taxIdentificationNumber', e.target.value)} />
                    <TextField label={<span>БИК <Icon iconName="Globe" style={{fontSize: 12, paddingLeft: 3, color: '#999999'}} /></span>} value={project.data.company.bandIdentificationCode} onChange={(e) => changeCompany('bandIdentificationCode', e.target.value)} />
                    <TextField label="Рассчётный счёт" value={project.data.company.checkingAccount} onChange={(e) => changeCompany('checkingAccount', e.target.value)} />
                    <TextField style={{color: 'black', height: 105}} multiline={true} rows={5} resizable={false} label="Юридический адрес" value={project.data.company.legalAddress} onChange={(e) => changeCompany('legalAddress', e.target.value)} />
                    <TextField style={{height: 105}} multiline={true} rows={5} resizable={false} label="Почтовый адрес" value={project.data.company.mailingAddress} onChange={(e) => changeCompany('mailingAddress', e.target.value)} />
                </Stack>
                <div>
                    {project.data.company.taxIdentificationNumber && <>
                        <div className="ms-fontWeight-semibold" style={{ marginBottom: 12, marginTop: 0, color: '#999999' }}>Карточка организации</div>
                        {fields.map((item) => <div style={{marginTop: 5, fontWeight: 500, width: 350}}>{item.param && (item.param + ' ')}<span style={{fontWeight: 600}}>{item.value}</span></div>)}
                    </>}
                    {project.data.company.type !== null && <div className="ms-fontWeight-semibold" style={{ marginBottom: 10, marginTop: 25, color: '#999999' }}>Подсказка для договора</div>}
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }} style={{color: 'gray'}}>
                        {project.data.company.type !== null && project.data.company.type === 'LEGAL' && project.data.company.managerName
                            ? <>
                                {project.data.company.name.replace('ООО', 'Общество с ограниченной ответственностью')} в лице Генерального директора {fullRussianName}, действующего на основании Устава
                            </>
                            : <></>
                        }
                        {project.data.company.type !== null && project.data.company.type === 'INDIVIDUAL'
                            ? <>
                                Индивидуальный предприниматель {project.data.company.entrepreneurName} действующий на основании листа записи ЕГРИП, ОГРНИП {project.data.company.mainRegistrationNumber} от {formatDate(new Date(project.data.company.registrationDate))}
                            </>
                            : <></>
                        }
                    </Stack>
                </div>
            </Stack>
        </div>
        {/*<Stack horizontal tokens={{ childrenGap: 50 }}>*/}
        {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
        {/*        <div style={{ fontSize: FontSizes.size14, marginTop: 10, fontWeight: 'bold' }}>Контакт</div>*/}
        {/*        <TextField label="Фамилия" value={project.data.contact.lastName} onChange={(e) => changeContact('lastName', e.target.value)} />*/}
        {/*        <TextField label="Имя" value={project.data.contact.name} onChange={(e) => changeContact('name', e.target.value)} />*/}
        {/*        <TextField label="Отчество" value={project.data.contact.middleName} onChange={(e) => changeContact('middleName', e.target.value)} />*/}
        {/*        <TextField label="Телефон" value={project.data.contact.phoneNumber} onChange={(e) => changeContact('phoneNumber', e.target.value)} />*/}
        {/*        <TextField label="Email" value={project.data.contact.email} onChange={(e) => changeContact('email', e.target.value)} />*/}
        {/*        <TextField label="Telegram username" value={project.data.contact.telegramUsername} onChange={(e) => changeContact('telegramUsername', e.target.value)} />*/}
        {/*        <TextField label="Instagram username" value={project.data.contact.instagramUsername} onChange={(e) => changeContact('instagramUsername', e.target.value)} />*/}
        {/*    </Stack>*/}
        {/*    <Separator vertical />*/}
        {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
        {/*        <div style={{ fontSize: FontSizes.size14, marginTop: 10, fontWeight: 'bold' }}>Компания</div>*/}
        {/*        <TextField label="ИНН" value={project.data.company.taxIdentificationNumber} onChange={(e) => changeCompany('taxIdentificationNumber', e.target.value)} />*/}
        {/*        <div>Заполните ИНН, данные организации подтянутся автоматически</div>*/}
        {/*        <TextField style={{color: 'black'}} disabled={true} label="Название компании" value={project.data.company.name} onChange={(e) => changeCompany('name', e.target.value)} />*/}
        {/*        {project.data.company.type !== 'INDIVIDUAL'*/}
        {/*            ? <TextField style={{color: 'black'}} disabled={true} label="КПП" value={project.data.company.registrationReasonCode} onChange={(e) => changeCompany('registrationReasonCode', e.target.value)} />*/}
        {/*            : <></>*/}
        {/*        }*/}
        {/*        <TextField style={{color: 'black'}} disabled={true} label={project.data.company.type !== 'INDIVIDUAL' ? 'ОГРН' : 'ОГРНИП'} value={project.data.company.mainRegistrationNumber} onChange={(e) => changeCompany('mainRegistrationNumber', e.target.value)} />*/}
        {/*        <TextField style={{color: 'black'}} disabled={true} label="ОКПО" value={project.data.company.organizationsClassifierCode} onChange={(e) => changeCompany('organizationsClassifierCode', e.target.value)} />*/}
        {/*        <TextField style={{color: 'black'}} multiline={true} rows={2} resizable={false} label="Юридический адрес" value={project.data.company.legalAddress} onChange={(e) => changeCompany('legalAddress', e.target.value)} />*/}
        {/*        <TextField multiline={true} rows={2} resizable={false} label="Почтовый адрес" value={project.data.company.mailingAddress} onChange={(e) => changeCompany('mailingAddress', e.target.value)} />*/}
        {/*        /!*<div style={{marginTop: 30}}>*!/*/}
        {/*        /!*    <DefaultButton text="Экспорт реквизитов в Excel" iconProps={{ iconName: 'ExcelDocument' }} onClick={async () => await exportRequisitesToExcelMethod(project.data.id)} />*!/*/}
        {/*        /!*</div>*!/*/}
        {/*    </Stack>*/}
        {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
        {/*        <div style={{ fontSize: FontSizes.size14, marginTop: 10, fontWeight: 'bold', color: 'white' }}>Банковские реквизиты</div>*/}
        {/*        <TextField label="БИК" value={project.data.company.bandIdentificationCode} onChange={(e) => changeCompany('bandIdentificationCode', e.target.value)} />*/}
        {/*        <div>Заполните БИК, данные банка подтянутся автоматически</div>*/}
        {/*        <TextField style={{color: 'black'}} disabled={true} label="Банк" value={project.data.company.bankName} onChange={(e) => changeCompany('bankName', e.target.value)} />*/}
        {/*        <TextField style={{color: 'black'}} disabled={true} label="Корреспондентский счёт" value={project.data.company.correspondentAccount} onChange={(e) => changeCompany('correspondentAccount', e.target.value)} />*/}
        {/*        <TextField label="Рассчётный счёт" value={project.data.company.checkingAccount} onChange={(e) => changeCompany('checkingAccount', e.target.value)} />*/}
        {/*    </Stack>*/}
        {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }} style={{color: 'gray'}}>*/}
        {/*        {project.data.company.type !== null && project.data.company.type === 'LEGAL' && project.data.company.managerName*/}
        {/*            ? <>*/}
        {/*                {project.data.company.name.replace('ООО', 'Общество с ограниченной ответственностью')} в лице Генерального директора {fullRussianName}, действующего на основании Устава*/}
        {/*            </>*/}
        {/*            : <></>*/}
        {/*        }*/}
        {/*        {project.data.company.type !== null && project.data.company.type === 'INDIVIDUAL'*/}
        {/*            ? <>*/}
        {/*                Индивидуальный предприниматель {project.data.company.entrepreneurName} действующий на основании листа записи ЕГРИП, ОГРНИП {project.data.company.mainRegistrationNumber} от {formatDate(new Date(project.data.company.registrationDate))}*/}
        {/*            </>*/}
        {/*            : <></>*/}
        {/*        }*/}
        {/*    </Stack>*/}
        {/*</Stack>*/}
        <div style={{marginTop: 10, marginBottom: 20}}>
            <Separator />
            <div style={{ fontSize: FontSizes.size14, marginBottom: 15, marginTop: 10, fontWeight: 'bold' }}>Прикрепите документы</div>
            {documentsRender}
            {/*{project.data.finance.summaryPrice === null ? <div>Если оплата будет по договору, нужно будет прикрепить договор и другие документы сюда.</div> : ''}*/}
            {/*{project.data.finance.summaryPrice !== null && project.data.finance.prepayment.type !== 'CLASSIC' ? <div>Оплата онлайн. Договор не нужен.</div> : ''}*/}
            {/*{project.data.finance.summaryPrice !== null && project.data.finance.prepayment.type === 'CLASSIC' ? documentsRender : ''}*/}
        </div>
    </>)
};
