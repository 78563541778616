import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    ActionButton,
    Breadcrumb, Callout, DetailsHeader,
    DetailsList,
    DetailsListLayoutMode, Pivot, PivotItem, Popup, PrimaryButton, Rating, RatingSize, Separator,
    Stack, TextField, Toggle,
    TooltipHost
} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import getSingleFreelancerMethod from "../../../api/getSingleFreelancerMethod";
import changeUserContactMethod from "../../../api/changeUserContactMethod";
import changeUserProfileMethod from "../../../api/changeUserProfileMethod";
import { SelectionMode } from '@fluentui/react/lib/Selection';
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import Notification from "../../notifications/Notification";
import getTeamCollectionMethod from "../../../api/getTeamCollectionMethod";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import regenerateAndSendPasswordMethod from "../../../api/regenerateAndSendPasswordMethod";
import inviteUserMethod from "../../../api/inviteUserMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import removeFreelancerMethod from "../../../api/removeFreelancerMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeProjectLinkMethod from "../../../api/removeProjectLinkMethod";
import removeFreelancerSpecializationMethod from "../../../api/removeFreelancerSpecializationMethod";
import addFreelancerSpecializationMethod from "../../../api/addFreelancerSpecializationMethod";
import updateFreelancerSpecializationMethod from "../../../api/updateFreelancerSpecializationMethod";
import {months} from "../../data/months";
import {shortMonths} from "../../data/shortMonths";
import deactivateEmployeeMethod from "../../../api/deactivateEmployeeMethod";
import {datePickerStringsRu} from "../../data/datePickerStringsRu";

const initialUser = {
    id: null,
    contact: {
        name: null,
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        telegramUsername: null
    },
    profile: {
        specialization: null,
        city: null,
        website: null,
        about: null,
        stars: 0,
        freelanceCard: {
            type: null,
            currentProject: null,
            name: null,
            website: null,
            experience: null,
            specializations: [],
            speedScore: null,
            communicationScore: null,
            projectsCountDone: null,
            projects: null,
            contacts: null,
            lawStatus: null,
            city: null,
            comment: null,
        },
    },
    status: null,
    permissions: [],
    hasPassword: false,
    isEmailVerified: false,
    createdAt: null,
    projects: [],
    projectsTotalCount: 0,
    openedProjectsCount: 0,
    scores: [],
};

export default function ViewFreelancer() {
    const params = useParams();
    const [user, setUser] = useState({loaded: false, data: initialUser});
    const [teams, setTeams] = useState([]);
    const [notification, setNotification] = useState({type: 'hidden', text: ''});
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const [isDeleteFreelancerCalloutVisible, { toggle: toggleDeleteFreelancerCallout }] = useBoolean(false);
    const deleteFreelancerButton = useId('delete-freelancer');
    const [isAddFreelancerSpecializationCalloutVisible, { toggle: toggleAddFreelancerSpecializationCallout }] = useBoolean(false);
    const addFreelancerSpecializationButton = useId('add-freelancer-specialization');
    const [selectedSpecialization, selectSpecialization] = useState(null);
    const [isDeactivateCalloutVisible, { toggle: toggleIsDeactivateCalloutVisible }] = useBoolean(false);
    const deactivateButtonId = useId('deactivate-button');

    const breadcrumbs = [
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Исполнители', key: 'freelancers', href: '/users#freelancers', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: (user.data.contact.firstName ?? user.data.contact.name) + ' ' + (user.data.contact.lastName ?? ''), key: 'freelancer', href: '/freelancers/' + params.id, isCurrentItem: true },
    ];

    useEffect(() => {
        if (!user.loaded) {
            getSingleFreelancerMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setUser({loaded: true, data: response});
            });

            getTeamCollectionMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    return;
                }

                setTeams(response);
            });

            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    const reloadData = () => setUser({loaded: false, data: {...user.data}});

    const changeContact = (key, value) => {
        const contact = {...user.data.contact, [key]: value};
        setUser({...user, data: {...user.data, contact: contact}});

        changeUserContactMethod(params.id, contact).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные контакта сохранены'});
        });
    };

    const changeProfile = (key, value) => {
        const profile = {...user.data.profile, [key]: value};
        setUser({...user, data: {...user.data, profile: profile}});

        changeUserProfileMethod(params.id, profile).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные профиля сохранены'});
        });
    };

    const changeFreelanceCard = (key, value) => {
        const profile = {...user.data.profile, freelanceCard: {...user.data.profile.freelanceCard, [key]: value}};
        setUser({...user, data: {...user.data, profile: profile}});

        changeUserProfileMethod(params.id, profile).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные профиля обновлены'});
        });
    };

    const selectedSpecializations = specializations.data.filter((item) => item.key === user.data.profile.specialization);

    const regenerateAndSendPassword = () => {
        regenerateAndSendPasswordMethod(params.id).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            reloadData();
            setNotification({type: 'success', text: 'Пароль сгенерирован и выслан'});
        });
    };

    const inviteUser = () => {
        inviteUserMethod(params.id).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            reloadData();
            setNotification({type: 'success', text: 'Пользователь приглашен в систему'});
        });
    };

    const columns2 = [
        {
            key: 'specialization',
            name: 'Специализация',
            fieldName: 'specialization',
            maxWidth: 200,
            minWidth: 160,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'qualityScore',
            name: 'Оценка',
            fieldName: 'qualityScore',
            maxWidth: 200,
            minWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                return <Rating
                    max={5}
                    size={RatingSize.Large}
                    rating={item.qualityScore}
                    allowZeroStars
                    ariaLabel="Оценка качества"
                    onChange={async (e, rating) => {
                        await updateFreelancerSpecializationMethod(user.data.id, item.specialization, rating);
                        reloadData();
                    }}
                />;
            }
        },
        {
            key: 'action',
            name: '',
            fieldName: 'name',
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <div style={{display: 'flex', height: '100%', alignItems: 'center'}}>
                    <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                        await removeFreelancerSpecializationMethod(user.data.id, item.specialization);
                        reloadData();
                    }} />
                </div>;
            },
        },
    ];

    const projectColumns = [
        {
            key: 'code',
            name: 'Номер',
            fieldName: 'code',
            maxWidth: 80,
            minWidth: 40,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            maxWidth: 180,
            minWidth: 120,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.name === null) {
                    return 'нет';
                }

                return item.name;
            }
        },
        {
            key: 'milestone',
            name: 'Этап',
            fieldName: 'milestone',
            maxWidth: 120,
            minWidth: 60,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'startedAt',
            name: 'Дата старта',
            fieldName: 'startedAt',
            maxWidth: 80,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.startedAt === null) {
                    return <div>не указано</div>;
                }

                const date = new Date(item.startedAt);

                if ((new Date()).getFullYear() === date.getFullYear()) {
                    return <div>{date.getDate()} {datePickerStringsRu.monthDeclinations[date.getMonth()]}</div>;
                }

                return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
            }
        },
        {
            key: 'dueDate',
            name: 'Дата закрытия',
            fieldName: 'dueDate',
            maxWidth: 80,
            minWidth: 60,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.dueDate === null) {
                    return <div>не указано</div>;
                }

                const date = new Date(item.dueDate);

                if ((new Date()).getFullYear() === date.getFullYear()) {
                    return <div>{date.getDate()} {datePickerStringsRu.monthDeclinations[date.getMonth()]}</div>;
                }

                return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
            }
        },
        {
            key: 'manager',
            name: 'Менеджер',
            fieldName: 'manager',
            maxWidth: 180,
            minWidth: 120,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.manager === null) {
                    return 'нет';
                }

                return item.manager;
            }
        },
        {
            key: 'services',
            name: 'Услуги',
            fieldName: 'services',
            maxWidth: 200,
            minWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.services.length === 0) {
                    return 'не указаны';
                }

                return item.services.map(service => service.name).join(', ');
            }
        },
    ];

    const navigate = useNavigate();

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year} года`;
    }

    return (
        <div>
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={10}
                ariaLabel="Навигация"
                overflowAriaLabel="Показать больше"
                style={{marginBottom: 20}}
            />
            <Notification type={notification.type} text={notification.text} />
            <div style={{marginBottom: 15}}>
                <div style={{ marginBottom: 15, fontSize: FontSizes.size24 }}>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        <Persona text={(user.data.contact.firstName ?? user.data.contact.name) + ' ' + (user.data.contact.lastName ?? '')} secondaryText={selectedSpecializations.length > 0 ? selectedSpecializations[0].text : null} size={PersonaSize.size56} />
                        {/*<div style={{fontSize: FontSizes.size24}}>{user.data.contact.firstName} {user.data.contact.lastName}</div>*/}
                    </Stack>
                </div>
            </div>
            {/*<div style={{fontSize: FontSizes.size18, fontWeight: 600, marginTop: 25, marginBottom: 20, opacity: 0.3}}>Исполнитель не имеет доступа к системе</div>*/}
            <Pivot aria-label="Меню исполнителя" linkFormat="tabs" styles={{link: {height: 32, marginRight: 10, backgroundColor: 'rgb(248 248 246)'}}}>
                <PivotItem headerText="Основная информация">
                    <div style={{marginTop: 20}}>
                        <Stack horizontal tokens={{ childrenGap: 50 }}>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <TextField label="Имя" value={user.data.contact.firstName ?? user.data.contact.name} onChange={(e) => changeContact('firstName', e.target.value)} />
                                <TextField label="Фамилия" value={user.data.contact.lastName} onChange={(e) => changeContact('lastName', e.target.value)} />
                                <TextField label="Телефон" value={user.data.contact.phoneNumber} onChange={(e) => changeContact('phoneNumber', e.target.value)} />
                                <TextField label="Email" value={user.data.contact.email} onChange={(e) => changeContact('email', e.target.value)} />
                                <TextField label="Telegram username (без @)" value={user.data.contact.telegramUsername} onChange={(e) => changeContact('telegramUsername', e.target.value)} />
                                <TextField label="Город" value={user.data.profile.freelanceCard.city} onChange={(e) => changeFreelanceCard('city', e.target.value)} />
                                {/*<Dropdown*/}
                                {/*    placeholder="Выберите"*/}
                                {/*    label="Проверенный/Новый"*/}
                                {/*    options={[{key: 'Проверенный', text: 'Проверенный'}, {key: 'Новый', text: 'Новый'}]}*/}
                                {/*    selectedKey={user.data.profile.freelanceCard.type}*/}
                                {/*    onChange={(e, option) => changeFreelanceCard('type', option.key)}*/}
                                {/*/>*/}
                                {/*<Dropdown*/}
                                {/*    placeholder="Выберите"*/}
                                {/*    label="Базовая специализация"*/}
                                {/*    options={specializations.data}*/}
                                {/*    selectedKey={user.data.profile.specialization}*/}
                                {/*    onChange={(e, option) => changeProfile('specialization', option.key)}*/}
                                {/*/>*/}
                                {/*<TextField label="Опыт" multiline rows={5} value={user.data.profile.freelanceCard.experience} onChange={(e) => changeFreelanceCard('experience', e.target.value)} />*/}
                                {/*<TextField label="Ссылка на портфолио" value={user.data.profile.freelanceCard.website} onChange={(e) => changeFreelanceCard('website', e.target.value)} />*/}
                                {/*<TextField label="Доп. информация" multiline rows={5} value={user.data.profile.freelanceCard.comment} onChange={(e) => changeFreelanceCard('comment', e.target.value)} />*/}
                                <TextField label="Юр. статус" value={user.data.profile.freelanceCard.lawStatus} onChange={(e) => changeFreelanceCard('lawStatus', e.target.value)} />
                            </Stack>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                {user.data.scores.map(score => {
                                    const scoreCountLastDigit = parseInt(score.count.toString().slice(-1));
                                    let scoreCountWord = '';
                                    if (scoreCountLastDigit === 1) {
                                        scoreCountWord = 'оценка';
                                    } else if (scoreCountLastDigit > 1 && scoreCountLastDigit < 5) {
                                        scoreCountWord = 'оценки';
                                    } else {
                                        scoreCountWord = 'оценок';
                                    }

                                    return <div>
                                        <div style={{fontWeight: 600}}>{score.name}:</div>
                                        {score.count > 0
                                            ? <div style={{fontSize: 10, opacity: 0.5}}>{score.count} {scoreCountWord}</div>
                                            : <></>
                                        }
                                        <Rating
                                            max={5}
                                            size={RatingSize.Large}
                                            rating={score.value}
                                            allowZeroStars
                                            ariaLabel={score.name}
                                            style={{pointerEvents: 'none'}}
                                        />
                                    </div>
                                })}
                            </Stack>
                        </Stack>
                    </div>
                </PivotItem>
                {/*<PivotItem headerText="Компетенции">*/}
                {/*    <div style={{marginTop: 20}}>*/}
                {/*        <DetailsList*/}
                {/*            items={user.data.profile.freelanceCard.specializations}*/}
                {/*            compact={false}*/}
                {/*            columns={columns2}*/}
                {/*            selectionMode={SelectionMode.none}*/}
                {/*            getKey={(item, index) => item.key}*/}
                {/*            setKey="none"*/}
                {/*            layoutMode={DetailsListLayoutMode.justified}*/}
                {/*            isHeaderVisible={true}*/}
                {/*        />*/}
                {/*        <DefaultButton text="Добавить компетенцию" id={addFreelancerSpecializationButton} onClick={toggleAddFreelancerSpecializationCallout} iconProps={{ iconName: 'Add' }} style={{marginTop: 12}} />*/}
                {/*        {isAddFreelancerSpecializationCalloutVisible && (*/}
                {/*            <Callout*/}
                {/*                role="dialog"*/}
                {/*                gapSpace={0}*/}
                {/*                target={`#${addFreelancerSpecializationButton}`}*/}
                {/*                onDismiss={toggleAddFreelancerSpecializationCallout}*/}
                {/*                setInitialFocus*/}
                {/*            >*/}
                {/*                <div style={{padding: 20}}>*/}
                {/*                    <Stack horizontal tokens={{ childrenGap: 20 }}>*/}
                {/*                        <Dropdown*/}
                {/*                            placeholder="Выберите из списка"*/}
                {/*                            options={[*/}
                {/*                                {key: 'арт-директоринг', text: 'арт-директоринг'},*/}
                {/*                                {key: 'айдентика', text: 'айдентика'},*/}
                {/*                                {key: 'упаковка', text: 'упаковка'},*/}
                {/*                                {key: 'web', text: 'web'},*/}
                {/*                                {key: 'иллюстрация', text: 'иллюстрация'},*/}
                {/*                                {key: 'бренд-стратегия', text: 'бренд-стратегия'},*/}
                {/*                                {key: 'нейминг', text: 'нейминг'},*/}
                {/*                                {key: 'копирайт', text: 'копирайт'},*/}
                {/*                            ]}*/}
                {/*                            styles={dropdownStyles}*/}
                {/*                            selectedKey={selectedSpecialization}*/}
                {/*                            onChange={(e, option) => selectSpecialization(option.key)}*/}
                {/*                        />*/}
                {/*                        <PrimaryButton text="Добавить" onClick={async () => {*/}
                {/*                            await addFreelancerSpecializationMethod(user.data.id, selectedSpecialization);*/}
                {/*                            selectSpecialization(null);*/}
                {/*                            reloadData();*/}
                {/*                        }} />*/}
                {/*                    </Stack>*/}
                {/*                </div>*/}
                {/*            </Callout>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*</PivotItem>*/}
                <PivotItem headerText="Проекты">
                    <div style={{marginTop: 20}}>
                        <Stack horizontal tokens={{ childrenGap: 10 }} style={{marginTop: 20}}>
                            <div>Общее кол-во проектов: <span style={{fontWeight: 600}}>{user.data.projectsTotalCount}</span></div>
                            <Separator vertical />
                            <div>Выполнено: <span style={{fontWeight: 600}}>{user.data.projectsTotalCount - user.data.openedProjectsCount}</span></div>
                            <Separator vertical />
                            <div>В работе: <span style={{fontWeight: 600}}>{user.data.openedProjectsCount}</span></div>
                        </Stack>
                        <DetailsList
                            items={user.data.projects}
                            compact={false}
                            columns={projectColumns}
                            selectionMode={SelectionMode.none}
                            getKey={(item, index) => item.key}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            onRenderRow={(props, Row) => {
                                return <div style={{cursor: 'pointer'}} onClick={(e) => {
                                    navigate('/projects/' + props.item.id);
                                }}>
                                    <Row {...props} />
                                </div>;
                            }}
                        />
                    </div>
                </PivotItem>
                <PivotItem headerText="Профиль">
                    <div style={{marginTop: 20}}>
                        <Stack horizontal tokens={{ childrenGap: 50 }}>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <Dropdown
                                    placeholder="Выберите"
                                    label="Направление деятельности"
                                    options={[
                                        {key: 'DESIGNER', text: 'Дизайн'},
                                        {key: 'STRATEGIST', text: 'Стратегия'},
                                        {key: 'COPYWRITER', text: 'Копирайтинг'},
                                    ]}
                                    selectedKey={user.data.profile.specialization}
                                    onChange={(e, option) => changeProfile('specialization', option.key)}
                                />
                                <Dropdown
                                    placeholder="Не выбраны"
                                    label="Специализация"
                                    options={[
                                        {key: 'арт-директоринг', text: 'Арт-директоринг'},
                                        {key: 'айдентика', text: 'Айдентика'},
                                        {key: 'упаковка', text: 'Упаковка'},
                                        {key: 'web', text: 'WEB'},
                                        {key: 'иллюстрация', text: 'Иллюстрация'},
                                        {key: 'бренд-стратегия', text: 'Бренд-стратегия'},
                                        {key: 'нейминг', text: 'Нейминг'},
                                        {key: 'копирайт', text: 'Копирайт'},
                                    ]}
                                    selectedKeys={user.data.profile.freelanceCard.specializations.map((item) => item.specialization)}
                                    onChange={async (e, option) => {
                                        const freelanceCard = {...user.data.profile.freelanceCard, specializations: option.selected ? [...user.data.profile.freelanceCard.specializations, {specialization: option.key}] : user.data.profile.freelanceCard.specializations.filter(item => item.specialization !== option.key)}
                                        const profile = {...user.data.profile, freelanceCard: freelanceCard};
                                        setUser({...user, data: {...user.data, profile: profile}});

                                        if (option.selected) {
                                            await addFreelancerSpecializationMethod(user.data.id, option.key);
                                        } else {
                                            await removeFreelancerSpecializationMethod(user.data.id, option.key);
                                        }

                                        setNotification({type: 'success', text: 'Специализация обновлена'});
                                    }}
                                    multiSelect={true}
                                />
                                <TextField label="Опыт" multiline rows={5} value={user.data.profile.freelanceCard.experience} onChange={(e) => changeFreelanceCard('experience', e.target.value)} />
                                <TextField label="Ссылка на портфолио" value={user.data.profile.freelanceCard.website} onChange={(e) => changeFreelanceCard('website', e.target.value)} />
                                {/*<TextField label="Доп. информация" multiline rows={5} value={user.data.profile.freelanceCard.comment} onChange={(e) => changeFreelanceCard('comment', e.target.value)} />*/}
                            </Stack>
                            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                <TextField label="Заметка" multiline rows={14} value={user.data.profile.freelanceCard.comment} onChange={(e) => changeFreelanceCard('comment', e.target.value)} />
                            </Stack>
                        </Stack>
                        <div style={{marginTop: 50}}>
                            <Separator />
                            <div style={{opacity: 0.6}}>
                                <DefaultButton text="Удалить исполнителя" id={deleteFreelancerButton} onClick={toggleDeleteFreelancerCallout} iconProps={{ iconName: 'Delete' }} />
                            </div>
                            {isDeleteFreelancerCalloutVisible && (
                                <Callout
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${deleteFreelancerButton}`}
                                    onDismiss={toggleDeleteFreelancerCallout}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20}}>
                                        <Stack tokens={{ childrenGap: 10 }}>
                                            <div>Вы уверены? 🤔</div>
                                            <DefaultButton text="Да, удалить" onClick={async (e) => {
                                                const response = await removeFreelancerMethod(params.id);

                                                if (response.status === 'ok') {
                                                    navigate('/users');
                                                }

                                                if (response.error) {
                                                    setNotification({type: 'error', text: response.error ?? 'Ошибка сервера'});
                                                }
                                            }} />
                                        </Stack>
                                    </div>
                                </Callout>
                            )}
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Вход в систему">
                    <div style={{marginTop: 20}}>
                        {user.data.status === 'ACTIVE'
                            ? !user.data.hasPassword
                                ? <div style={{marginTop: 20}}>
                                    <div style={{ marginTop: 15 }}>
                                        <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> новый пользователь без доступа к системе</div>
                                    </div>
                                    <Separator />
                                    <div>
                                        <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Выслать приглашение на email с паролем?</div>
                                        {user.data.contact.email !== null && user.data.contact.email.length > 0
                                            ? <PrimaryButton text="Выслать" iconProps={{ iconName: 'Mail' }} onClick={inviteUser} />
                                            : <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                                                <PrimaryButton text="Выслать" iconProps={{ iconName: 'Mail' }} onClick={inviteUser} disabled={true} />
                                                <span style={{color: 'gray'}}>Укажите email пользователя в "Основная информация"</span>
                                            </Stack>
                                        }
                                    </div>
                                </div>
                                : <div style={{marginTop: 20}}>
                                    <div style={{ marginTop: 15 }}>
                                        <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> пароль обновлён <span style={{ fontWeight: 600 }}>{formatDate(new Date(user.data.passwordResetAt ? user.data.passwordResetAt : user.data.createdAt))}</span></div>
                                    </div>
                                    <Separator />
                                    <div style={{marginBottom: 90}}>
                                        <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Сгенерировать новый пароль и отправить на email?</div>
                                        <DefaultButton text="Сгенерировать и отправить" iconProps={{ iconName: 'PasswordField' }} onClick={regenerateAndSendPassword} />
                                    </div>
                                    <div style={{opacity: 0.3}}>
                                        <div style={{ fontSize: FontSizes.size14, marginBottom: 10 }}>Так же вы можете заблокировать этого пользователя.</div>
                                        <DefaultButton id={deactivateButtonId} text="Заблокировать" iconProps={{ iconName: 'Blocked' }} onClick={(e) => toggleIsDeactivateCalloutVisible()} />
                                    </div>
                                    {isDeactivateCalloutVisible && (
                                        <Callout
                                            role="dialog"
                                            gapSpace={0}
                                            target={`#${deactivateButtonId}`}
                                            onDismiss={toggleIsDeactivateCalloutVisible}
                                            setInitialFocus
                                        >
                                            <div style={{padding: 20}}>
                                                <Stack tokens={{ childrenGap: 10 }}>
                                                    <div style={{ fontSize: FontSizes.size14, fontWeight: 600 }}>Заблокировать этого пользователя?</div>
                                                    <DefaultButton text="Подтвердить действие" iconProps={{ iconName: 'Accept' }} onClick={async (e) => {
                                                        await deactivateEmployeeMethod(user.data.id);
                                                    }} />
                                                </Stack>
                                            </div>
                                        </Callout>
                                    )}
                                </div>
                            : <div style={{marginTop: 20}}>
                                <div style={{ marginTop: 15 }}>
                                    <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> пользователь заблокирован</div>
                                </div>
                            </div>
                        }
                    </div>
                </PivotItem>
            </Pivot>
        </div>
    );
};
