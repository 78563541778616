import * as React from "react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useEffect, useState} from "react";
import {CommandBarButton, DefaultButton, Icon, PrimaryButton, Stack, TextField} from "@fluentui/react";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";
import exportProjectsToExcelMethod from "../../api/exportProjectsToExcelMethod";
import DateRangePicker from "../Shared/DateRangePicker";
import {useBoolean, useId} from "@fluentui/react-hooks";
import exportRequestsToExcelMethod from "../../api/exportRequestsToExcelMethod";

export default function ProjectCollectionFilter({userType, artdirs, managers, filterSelection, setFilterSelection, reloadCollection}) {
    const [filters, setFilters] = useState({loaded: false, text: '', managers: [], artdirs: [], statuses: [], milestones: [], sorting: []});
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[]},profile:{specialization:null},type:null});

    const [isFilterVisible, { toggle: toggleIsFilterVisible }] = useBoolean(localStorage.getItem('PROJECT_FILTER_VISIBLE') === '1');
    const filterButtonId = useId('filter-button');

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    let canSeeOnlyAssignedToMe = false;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'see_projects' && permission.type === 'ONLY_ME') {
            canSeeOnlyAssignedToMe = true;
        }
    });

    if (!filters.loaded && managers.length !== 0 && artdirs.length !== 0 && user.type !== null) {
        const partdirs = artdirs.map((item) => {
            return {key: item.id, text: item.firstName + ' ' + (item.lastName ?? '')}
        });
        const managersFiltered = managers.filter((item) => {
            if (user.type === 'BRAND_HUB_EMPLOYEE') { // for brand hub employees show only his managers
                return item.type === 'BRAND_HUB_EMPLOYEE';
            }
            return true;
        });
        const pmanagers = managersFiltered.map((item) => {
            return {key: item.id, text: item.firstName + ' ' + (item.lastName ?? '')}
        });
        partdirs.unshift({key: 'ALL', text: 'Все'});
        pmanagers.unshift({key: 'ALL', text: 'Все'});

        const statuses = [
            { key: 'ALL', text: 'Все статусы' },
            { key: 'IN_PROGRESS', text: 'IN PROGRESS' },
            { key: 'HOLD', text: 'HOLD' },
            { key: 'DONE', text: 'DONE' },
            { key: 'CANCELED', text: 'CANCELED' },
        ];

        const milestones = [
            { key: 'ALL', text: 'Все этапы' },
            { key: 'Входящие', text: 'Входящие' },
            { key: 'Приемка в работу', text: 'Приемка в работу' },
            { key: 'Дебрифинг', text: 'Дебрифинг' },
            { key: 'Стратегия', text: 'Стратегия' },
            { key: 'Название', text: 'Название' },
            { key: 'Дизайн', text: 'Дизайн' },
            { key: 'Вёрстка', text: 'Вёрстка' },
            // { key: 'Холд', text: 'Холд' },
            { key: 'Закрытие проекта', text: 'Закрытие проекта' },
            { key: 'Закрыт', text: 'Закрыт' },
        ];

        const sorting = [
            { key: 'START_DATE_NEW', text: 'Дата старта', data: { icon: 'ChevronDownSmall' } },
            { key: 'START_DATE_OLD', text: 'Дата старта', data: { icon: 'ChevronUpSmall' } },
            { key: 'PRESENTATION_DATE_OLD', text: 'Дата през.', data: { icon: 'ChevronUpSmall' } },
        ];

        setFilters({loaded: true, managers: pmanagers, artdirs: partdirs, statuses: statuses, milestones: milestones, sorting: sorting, text: ''});
    }

    const onRenderTitle = (options) => {
        const option = options[0];

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {option.data && option.data.icon && (
                    <Icon style={{ marginRight: '8px', fontSize: 10 }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    const onRenderOption = (option) => {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {option.data && option.data.icon && (
                    <Icon style={{ marginRight: '8px', fontSize: 10 }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    const dropdownStyles = {
        dropdown: { width: 185 },
    };

    const changeFilter = (newFilterSelection) => {
        if (filterSelection.isOnlyMyProjects !== newFilterSelection.isOnlyMyProjects && newFilterSelection.isOnlyMyProjects === 1) {
            newFilterSelection.manager = 'ALL';
            newFilterSelection.artdir = 'ALL';
        }

        localStorage.setItem('PROJECT_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    // let itemsInRowCount = 3;
    // itemsInRowCount += userType !== 'FREELANCER' ? 1 : 0;
    // itemsInRowCount += canSeeOnlyAssignedToMe && user.profile.specialization === 'MANAGER' ? 0 : 1;
    // itemsInRowCount += canSeeOnlyAssignedToMe && user.profile.specialization === 'ART_DIRECTOR' ? 0 : 1;

    return  <>
        <Stack horizontal  style={{justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 10}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                <TextField style={{width: 185}} value={filterSelection.text ?? null} onChange={(e) => changeFilter({...filterSelection, text: e.target.value})} iconProps={{ iconName: 'Search', style: {color: 'gray'} }} />
                {/*{userType !== 'FREELANCER'*/}
                {/*    ? <Stack horizontal tokens={{ childrenGap: 20 }}>*/}
                {/*        <div style={{display: 'flex', alignItems: 'end', marginTop: 10}}>*/}
                {/*            {filterSelection.isOnlyMyProjects*/}
                {/*                ? <PrimaryButton style={{width: 185}} text="Мои проекты" iconProps={{ iconName: 'UserEvent' }} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 0})} />*/}
                {/*                : <DefaultButton style={{width: 185, borderColor: '#ef5021', color: '#ef5021' }} text="Мои проекты" iconProps={{ iconName: 'UserEvent' }} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 1})} />*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </Stack>*/}
                {/*    : <></>*/}
                {/*}*/}

                {userType !== 'FREELANCER'
                    ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <div style={{display: 'flex', alignItems: 'end'}}>
                            {filterSelection.isOnlyMyProjects
                                ? <CommandBarButton id={filterButtonId} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 0})} iconProps={{ iconName: 'UserEvent' }} style={{height: 32, color: '#ef5021'}} text="Мои проекты" />
                                : <CommandBarButton id={filterButtonId} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 1})} iconProps={{ iconName: 'UserEvent', style: {color: '#999999'} }} style={{height: 32}} text="Мои проекты" />
                            }
                        </div>
                    </Stack>
                    : <></>
                }

                <CommandBarButton id={filterButtonId} onClick={() => {
                    localStorage.setItem('PROJECT_FILTER_VISIBLE', isFilterVisible ? '0' : '1');
                    toggleIsFilterVisible();
                }} iconProps={{ iconName: isFilterVisible ? 'ChevronRight' : 'ChevronDown', style: {fontSize: 12, transition: '0.5s'} }} style={{height: 32}} text="Фильтры" />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                {userType !== 'FREELANCER' && <CommandBarButton onClick={() => exportProjectsToExcelMethod(filterSelection.text, filterSelection.status, filterSelection.milestone, filterSelection.manager, filterSelection.artdir, filterSelection.startedAtFrom, filterSelection.startedAtTo, filterSelection.presentationDateFrom, filterSelection.presentationDateTo, filterSelection.dueDateFrom, filterSelection.dueDateTo, filterSelection.services)} iconProps={{ iconName: 'ExcelDocument' }} style={{height: 32}} text="Excel экспорт" />}
                <Dropdown
                    options={filters.sorting}
                    onRenderTitle={onRenderTitle}
                    onRenderOption={onRenderOption}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.sorting}
                    iconProps={{ iconName: 'Sort', style: {color: 'gray'} }}
                    onRenderCaretDown={() => <Icon iconName="Sort" style={{color: 'gray'}} />}
                    onChange={(e, option) => changeFilter({...filterSelection, sorting: option.key})}
                />
            </Stack>
        </Stack>
        {isFilterVisible && <Stack tokens={{ childrenGap: 5 }} style={{marginBottom: 20}}>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <DateRangePicker
                    label="Дата старта"
                    onChange={(dateFrom, dateTo, fastSelectButtonId) => changeFilter({...filterSelection, startedAtFrom: dateFrom, startedAtTo: dateTo, startedAtFastButtonId: fastSelectButtonId})}
                    defaultDateFrom={filterSelection.startedAtFrom}
                    defaultDateTo={filterSelection.startedAtTo}
                    defaultSelectedButtonId={filterSelection.startedAtFastButtonId}
                />
                <Dropdown
                    placeholder="Все статусы"
                    label="Статус"
                    options={filters.statuses}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.status}
                    onChange={(e, option) => changeFilter({...filterSelection, status: option.key})}
                />
                <Dropdown
                    placeholder="Все этапы"
                    label="Этап"
                    options={filters.milestones}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.milestone}
                    onChange={(e, option) => changeFilter({...filterSelection, milestone: option.key})}
                />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                {userType !== 'FREELANCER'
                    ?  <Dropdown
                        placeholder="Все услуги"
                        label="Услуги"
                        selectedKeys={filterSelection.services}
                        multiSelect
                        options={[
                            { key: 'Бренд-платформа', text: 'Бренд-платформа' },
                            { key: 'Название', text: 'Название' },
                            { key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль' },
                            { key: 'Дизайн упаковки', text: 'Дизайн упаковки' },
                            { key: 'Дизайн сайта', text: 'Дизайн сайта' },
                            { key: 'Разработка сайта', text: 'Разработка сайта' },
                            { key: 'Дизайн презентации', text: 'Дизайн презентации' },
                            { key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю' },
                            { key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей' },
                            { key: 'PR', text: 'PR' },
                        ]}
                        styles={dropdownStyles}
                        onChange={(e, option) => changeFilter({...filterSelection, services: option.selected ? [...(filterSelection.services ?? []), option.key] : (filterSelection.services ?? []).filter(key => key !== option.key)})}
                    />
                    : <></>
                }
                {canSeeOnlyAssignedToMe && user.profile.specialization === 'MANAGER'
                    ? <></>
                    : <Dropdown
                        placeholder="Все"
                        label="Менеджер"
                        options={filters.managers}
                        styles={dropdownStyles}
                        selectedKey={filterSelection.manager}
                        onChange={(e, option) => changeFilter({...filterSelection, manager: option.key})}
                    />
                }
                {canSeeOnlyAssignedToMe && user.profile.specialization === 'ART_DIRECTOR'
                    ? <></>
                    : <Dropdown
                        placeholder="Все"
                        label="Арт-директор"
                        options={filters.artdirs}
                        styles={dropdownStyles}
                        selectedKey={filterSelection.artdir}
                        onChange={(e, option) => changeFilter({...filterSelection, artdir: option.key})}
                    />
                }
            </Stack>
        </Stack>}
    </>;

    // return (
    //     <Stack style={{marginBottom: 10}} tokens={{ childrenGap: 5 }}>
    //         <Stack horizontal tokens={{ childrenGap: 20 }}>
    //             {/*<Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 390 } }}>*/}
    //             {/*    <TextField label="Поиск по тексту" value={filterSelection.text ?? null} onChange={(e) => changeFilter({...filterSelection, text: e.target.value})} />*/}
    //             {/*</Stack>*/}
    //             {/*<Dropdown*/}
    //             {/*    placeholder="Все статусы"*/}
    //             {/*    label="Статус"*/}
    //             {/*    options={filters.statuses}*/}
    //             {/*    styles={dropdownStyles}*/}
    //             {/*    selectedKey={filterSelection.status}*/}
    //             {/*    onChange={(e, option) => changeFilter({...filterSelection, status: option.key})}*/}
    //             {/*/>*/}
    //             {/*<Dropdown*/}
    //             {/*    placeholder="Все этапы"*/}
    //             {/*    label="Этап"*/}
    //             {/*    options={filters.milestones}*/}
    //             {/*    styles={dropdownStyles}*/}
    //             {/*    selectedKey={filterSelection.milestone}*/}
    //             {/*    onChange={(e, option) => changeFilter({...filterSelection, milestone: option.key})}*/}
    //             {/*/>*/}
    //             {/*<Dropdown*/}
    //             {/*    placeholder="Дата старта убыв."*/}
    //             {/*    label="Сортировка"*/}
    //             {/*    options={filters.sorting}*/}
    //             {/*    onRenderTitle={onRenderTitle}*/}
    //             {/*    onRenderOption={onRenderOption}*/}
    //             {/*    styles={dropdownStyles}*/}
    //             {/*    selectedKey={filterSelection.sorting}*/}
    //             {/*    onChange={(e, option) => changeFilter({...filterSelection, sorting: option.key})}*/}
    //             {/*/>*/}
    //         </Stack>
    //         <Stack horizontal tokens={{ childrenGap: 20 }}>
    //             {/*<DateRangePicker*/}
    //             {/*    label="Дата старта"*/}
    //             {/*    onChange={(dateFrom, dateTo, fastSelectButtonId) => changeFilter({...filterSelection, startedAtFrom: dateFrom, startedAtTo: dateTo, startedAtFastButtonId: fastSelectButtonId})}*/}
    //             {/*    defaultDateFrom={filterSelection.startedAtFrom}*/}
    //             {/*    defaultDateTo={filterSelection.startedAtTo}*/}
    //             {/*    defaultSelectedButtonId={filterSelection.startedAtFastButtonId}*/}
    //             {/*/>*/}
    //             {/*<Dropdown*/}
    //             {/*    placeholder="Всё время"*/}
    //             {/*    label="Дата презентации"*/}
    //             {/*    options={filters.presentationDate}*/}
    //             {/*    styles={dropdownStyles}*/}
    //             {/*    selectedKey={filterSelection.presentationDate}*/}
    //             {/*    onChange={(e, option) => changeFilter({...filterSelection, presentationDate: option.key})}*/}
    //             {/*/>*/}
    //             {/*{userType !== 'FREELANCER'*/}
    //             {/*    ?  <Dropdown*/}
    //             {/*        placeholder="Все услуги"*/}
    //             {/*        label="Услуги"*/}
    //             {/*        selectedKeys={filterSelection.services}*/}
    //             {/*        multiSelect*/}
    //             {/*        options={[*/}
    //             {/*            { key: 'Бренд-платформа', text: 'Бренд-платформа' },*/}
    //             {/*            { key: 'Название', text: 'Название' },*/}
    //             {/*            { key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль' },*/}
    //             {/*            { key: 'Дизайн упаковки', text: 'Дизайн упаковки' },*/}
    //             {/*            { key: 'Дизайн сайта', text: 'Дизайн сайта' },*/}
    //             {/*            { key: 'Разработка сайта', text: 'Разработка сайта' },*/}
    //             {/*            { key: 'Дизайн презентации', text: 'Дизайн презентации' },*/}
    //             {/*            { key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю' },*/}
    //             {/*            { key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей' },*/}
    //             {/*            { key: 'PR', text: 'PR' },*/}
    //             {/*        ]}*/}
    //             {/*        styles={dropdownStyles}*/}
    //             {/*        onChange={(e, option) => changeFilter({...filterSelection, services: option.selected ? [...(filterSelection.services ?? []), option.key] : (filterSelection.services ?? []).filter(key => key !== option.key)})}*/}
    //             {/*    />*/}
    //             {/*    : <></>*/}
    //             {/*}*/}
    //             {/*{canSeeOnlyAssignedToMe && user.profile.specialization === 'MANAGER'*/}
    //             {/*    ? <></>*/}
    //             {/*    : <Dropdown*/}
    //             {/*        placeholder="Все"*/}
    //             {/*        label="Менеджер"*/}
    //             {/*        options={filters.managers}*/}
    //             {/*        styles={dropdownStyles}*/}
    //             {/*        selectedKey={filterSelection.manager}*/}
    //             {/*        onChange={(e, option) => changeFilter({...filterSelection, manager: option.key})}*/}
    //             {/*    />*/}
    //             {/*}*/}
    //             {/*{canSeeOnlyAssignedToMe && user.profile.specialization === 'ART_DIRECTOR'*/}
    //             {/*    ? <></>*/}
    //             {/*    : <Dropdown*/}
    //             {/*        placeholder="Все"*/}
    //             {/*        label="Арт-директор"*/}
    //             {/*        options={filters.artdirs}*/}
    //             {/*        styles={dropdownStyles}*/}
    //             {/*        selectedKey={filterSelection.artdir}*/}
    //             {/*        onChange={(e, option) => changeFilter({...filterSelection, artdir: option.key})}*/}
    //             {/*    />*/}
    //             {/*}*/}
    //             {/*{userType !== 'FREELANCER'*/}
    //             {/*    ? <div style={{display: 'flex', alignItems: 'end'}}>*/}
    //             {/*        <DefaultButton style={{width: 185}} text="Экспорт Excel" iconProps={{ iconName: 'ExcelDocument' }} onClick={() => exportProjectsToExcelMethod(filterSelection.text, filterSelection.status, filterSelection.milestone, filterSelection.manager, filterSelection.artdir, filterSelection.startedAtFrom, filterSelection.startedAtTo, filterSelection.presentationDateFrom, filterSelection.presentationDateTo, filterSelection.dueDateFrom, filterSelection.dueDateTo, filterSelection.services)} />*/}
    //             {/*    </div>*/}
    //             {/*    : <></>*/}
    //             {/*}*/}
    //         </Stack>
    //         {/*{userType !== 'FREELANCER'*/}
    //         {/*    ? <Stack horizontal tokens={{ childrenGap: 20 }}>*/}
    //         {/*        <div style={{display: 'flex', alignItems: 'end', marginTop: 10}}>*/}
    //         {/*            {filterSelection.isOnlyMyProjects*/}
    //         {/*                ? <PrimaryButton style={{width: 185}} text="Мои проекты" iconProps={{ iconName: 'UserEvent' }} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 0})} />*/}
    //         {/*                : <DefaultButton style={{width: 185, borderColor: '#ef5021', color: '#ef5021' }} text="Мои проекты" iconProps={{ iconName: 'UserEvent' }} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 1})} />*/}
    //         {/*            }*/}
    //         {/*        </div>*/}
    //         {/*    </Stack>*/}
    //         {/*    : <></>*/}
    //         {/*}*/}
    //     </Stack>
    // );
};
