import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    ActionButton,
    Breadcrumb,
    DetailsList,
    DetailsListLayoutMode, Popup,
    SelectionMode, Separator, Spinner, SpinnerSize, Stack, TextField,
    TooltipHost
} from "@fluentui/react";
import getSingleTeamMethod from "../../../api/getSingleTeamMethod";
import {FontSizes} from "@fluentui/theme";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeTeamMemberMethod from "../../../api/removeTeamMemberMethod";
import { useBoolean } from '@fluentui/react-hooks';
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import AddTeamMember from "./AddTeamMember";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import removeTeamMethod from "../../../api/removeTeamMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import getCurrentUserMethod from "../../../api/getCurrentUserMethod";

const initialTeam = {
    name: null,
    about: null,
    members: [
        {
            userId: null,
            type: null,
            contact: {
                firstName: null,
                lastName: null,
                email: null,
                phoneNumber: null,
                telegramUsername: null
            },
            profile: {
                specialization: null,
                city: null,
                about: null
            }
        }
    ],
    services: [],
};

export default function ViewTeam() {
    const navigate = useNavigate();
    const params = useParams();
    const [team, setTeam] = useState({loaded: false, data: initialTeam});
    const [isPopupVisible, { toggle: toggleIsPopupVisible }] = useBoolean(false);
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    const breadcrumbs = [
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Команды', key: 'teams', onClick: () => navigate('/users#teams')},
        { text: team.data.name, key: 'team', href: '/teams/' + params.id, isCurrentItem: true },
    ];

    let canChangeTeam = false;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'change_team' && (permission.type === 'ALL' || permission.type === 'TEAM')) {
            canChangeTeam = true;
        }
    });

    useEffect(() => {
        if (!team.loaded) {
            getSingleTeamMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setTeam({loaded: true, data: response});
            });

            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    let teamId = null;
    user.security.permissions.forEach((permission) => {
        if (teamId === null && permission.key === 'see_teams' && permission.type === 'TEAM') {
            teamId = permission.teamId;
        }
    });

    if (user.contact.email.length === 0) {
        return <Spinner size={SpinnerSize.large} />;
    }

    const reloadData = () => setTeam({loaded: false, data: {...team.data}});

    // const columns = [
    //     {
    //         key: 'name',
    //         name: 'Имя',
    //         fieldName: 'name',
    //         minWidth: 210,
    //         maxWidth: 350,
    //         isRowHeader: true,
    //         isResizable: true,
    //         data: 'string',
    //         isPadded: true,
    //         onRender: (item) => {
    //             return <Persona text={item.contact.firstName + ' ' + (item.contact.lastName ?? '')} size={PersonaSize.size32} />
    //         }
    //     },
    //     {
    //         key: 'type',
    //         name: 'Тип',
    //         fieldName: 'type',
    //         minWidth: 210,
    //         maxWidth: 350,
    //         isRowHeader: true,
    //         isResizable: true,
    //         data: 'string',
    //         isPadded: true,
    //     },
    //     {
    //         key: 'action',
    //         name: 'Действие',
    //         fieldName: 'name',
    //         minWidth: 210,
    //         maxWidth: 350,
    //         isRowHeader: true,
    //         isResizable: true,
    //         data: 'string',
    //         isPadded: true,
    //         onRender: (item) => {
    //             return <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
    //                 await removeTeamMemberMethod(params.id, item.userId);
    //                 reloadData();
    //             }} />
    //         },
    //     },
    // ];

    const columns = [
        {
            key: 'name',
            name: 'Имя',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 220,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (el) => {
                if (el.contact.firstName === null) {
                    return <Persona text="неизвестно" size={PersonaSize.size32} />;
                }

                const name = el.contact.firstName + ' ' + (el.contact.lastName ?? '');

                if (el.avatarId) {
                    return <Persona imageUrl={process.env.REACT_APP_API_URL + '/files/' + el.avatarId} text={name} size={PersonaSize.size32} />
                }

                return <Persona text={name} size={PersonaSize.size32} />
            }
        },
        {
            key: 'specialization',
            name: 'Профиль',
            fieldName: 'specialization',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (el) => {
                const selectedSpecializations = specializations.data.filter((item) => item.key === el.profile.specialization);

                if (selectedSpecializations.length > 0) {
                    return <div>{selectedSpecializations[0].text}</div>;
                }

                return <div>Не указан</div>
            }
        },
        {
            key: 'telegramUsername',
            name: 'Телеграм',
            fieldName: 'telegramUsername',
            minWidth: 100,
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            onRender: (el) => {
                if (el.contact.telegramUsername === null) {
                    return <div>Не указан</div>;
                }

                return <div>{'@' + el.contact.telegramUsername}</div>;
            }
        },
        {
            key: 'email',
            name: 'Email',
            fieldName: 'email',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            onRender: (el) => {
                return <div>{el.contact.email ?? 'Не указан'}</div>;
            }
        },
        {
            key: 'phoneNumber',
            name: 'Телефон',
            fieldName: 'phoneNumber',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            onRender: (el) => {
                return <div>{el.contact.phoneNumber ?? 'Не указан'}</div>;
            }
        },
        {
            key: 'action',
            name: '',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (el) => {
                if (el.userId === user.id || !canChangeTeam) {
                    return <></>;
                }

                return <FontIcon className="remove"  aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                    await removeTeamMemberMethod(params.id, el.userId);
                    reloadData();
                }} />
            },
        },
    ];

    let chiefUserId = null;
    const hasChief = team.data.members.filter((member) => member.type === 'CHIEF').length > 0;

    if (hasChief) {
        chiefUserId = team.data.members.filter((member) => member.type === 'CHIEF')[0].userId;
    }

    const removeTeam = async () => {
        const response = await removeTeamMethod(params.id);

        if (response.status === 'ok') {
            navigate('/teams');
        }
    };

    return (
        <div>
            {teamId !== null
                ? <div style={{ fontSize: FontSizes.size20, marginBottom: 0, fontWeight: 600 }}>Моя команда</div>
                : <>
                    <Breadcrumb
                        items={breadcrumbs}
                        maxDisplayedItems={10}
                        ariaLabel="Навигация"
                        overflowAriaLabel="Показать больше"
                        style={{marginBottom: 20}}
                    />
                    <div style={{marginBottom: 15, marginTop: 30}}>
                        <div style={{ fontSize: FontSizes.size24, marginBottom: 15, marginTop: 30 }}>{team.data.name}</div>
                    </div>
                    {!canChangeTeam
                        ? <></>
                        : <DefaultButton text="Удалить" onClick={removeTeam} />
                    }
                    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }} style={{marginBottom: 20}}>
                        <TextField value={team.data.id} disabled style={{color: 'black'}} label="Идентификатор" />
                    </Stack>
                    <div style={{marginTop: 30}}>
                        <div style={{ fontSize: FontSizes.size18, marginBottom: 15, marginTop: 15 }}>Участники команды</div>
                    </div>
                </>
            }
            {/*<Separator />*/}
            {team.data.members.length > 0
                ?  <DetailsList
                    items={team.data.members}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={(item, index) => item.key}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    // onActiveItemChanged={(item) => navigate('/teams/' + team.data.id + '/' + item.userId)}
                    onRenderRow={(props, Row) => {
                        return <div style={{cursor: 'pointer'}} onClick={(e) => {
                            if (e.target.classList.contains('remove')) {
                                return;
                            }

                            navigate('/teams/' + team.data.id + '/' + props.item.userId);
                        }}>
                            <Row {...props} />
                        </div>;
                    }}
                />
                : <></>
            }
            {!canChangeTeam
                ? <></>
                :  <ActionButton style={{marginTop: 10}} iconProps={{ iconName: isPopupVisible ? 'ChromeMinimize' : 'Add' }} allowDisabledFocus onClick={toggleIsPopupVisible}>
                    {isPopupVisible ? 'Скрыть' : 'Добавить человека в команду'}
                </ActionButton>
            }
            {isPopupVisible && (
                <Popup style={{marginTop: 20}}>
                    <AddTeamMember isMyTeam={teamId !== null} teamId={params.id} reloadData={reloadData} toggleIsPopupVisible={toggleIsPopupVisible} />
                </Popup>
            )}
        </div>
    );
};
