import * as React from "react";

export const tableColumns = [
    {
        key: 'date',
        name: 'Дата оплаты',
        fieldName: 'paidAt',
        minWidth: 100,
        maxWidth: 120,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        onRender: (item) => {
            return item.paidAt === '' ? 'Не указана' : item.paidAt;
        }
    },
    {
        key: 'projectCode',
        name: 'Проект',
        fieldName: 'projectCode',
        minWidth: 80,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => {
            return item.projectCode === null ? '' : item.projectCode;
        }
    },
    {
        key: 'title',
        name: 'Назначение платежа',
        fieldName: 'title',
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'customerFullName',
        name: 'ФИО клиента',
        fieldName: 'customerFullName',
        minWidth: 200,
        maxWidth: 360,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'status',
        name: 'Статус',
        fieldName: 'status',
        minWidth: 50,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => {
            if (item.status === 'PAID') {
                return <div style={{color: 'green'}}>Оплачен</div>
            } else if (item.status === 'CANCELED') {
                return <div style={{color: 'red'}}>Отменён</div>
            } else if (item.status === 'PENDING') {
                return <div style={{color: 'blue'}}>В обработке</div>
            }

            return <div>Не оплачен</div>
        }
    },
    {
        key: 'amount',
        name: 'Сумма',
        fieldName: 'amount',
        minWidth: 90,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
];
