import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    Breadcrumb, Callout,
    Pivot, PivotItem, PrimaryButton, Separator,
    Stack, TextField
} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import getSingleUserMethod from "../../../api/getSingleUserMethod";
import changeUserContactMethod from "../../../api/changeUserContactMethod";
import changeUserProfileMethod from "../../../api/changeUserProfileMethod";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import Notification from "../../notifications/Notification";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import regenerateAndSendPasswordMethod from "../../../api/regenerateAndSendPasswordMethod";
import inviteUserMethod from "../../../api/inviteUserMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import ChangePermissions from "../Shared/ChangePermissions";
import {DefaultButton} from "@fluentui/react/lib/Button";
import deactivateEmployeeMethod from "../../../api/deactivateEmployeeMethod";
import {datePickerStringsRu} from "../../data/datePickerStringsRu";
import {useBoolean, useId} from "@fluentui/react-hooks";

const initialUser = {
    contact: {
        firstName: '',
        lastName: '',
        email: null,
        phoneNumber: null,
        telegramUsername: null
    },
    profile: {
        avatarId: null,
        specialization: null,
        additionalSpecializations: [],
        city: null,
        website: null,
        about: null
    },
    status: null,
    permissions: [],
    hasPassword: false,
    passwordResetAt: null,
    isEmailVerified: false,
    createdAt: null,
};

export default function ViewEmployee() {
    const params = useParams();
    const [user, setUser] = useState({loaded: false, data: initialUser});
    const [notification, setNotification] = useState({type: 'hidden', text: ''});
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Сотрудники', key: 'employees', onClick: () => navigate('/users#employees') },
        { text: user.data.contact.firstName + ' ' + user.data.contact.lastName, key: 'employee', href: '/employees/' + params.id, isCurrentItem: true },
    ];

    useEffect(() => {
        if (!user.loaded) {
            getSingleUserMethod(params.id).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    setNotification({type: 'error', text: 'Ошибка сервера'});
                    navigate('/employees');
                    return;
                }

                setUser({loaded: true, data: response});
            });

            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    const reloadData = () => setUser({loaded: false, data: {...user.data}});

    const changeContact = (key, value) => {
        const contact = {...user.data.contact, [key]: value};
        setUser({...user, data: {...user.data, contact: contact}});

        changeUserContactMethod(params.id, contact).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные контакта сохранены'});
        });
    };

    const changeProfile = (key, value) => {
        if (key === 'additionalSpecializations') {
            const additionalSpecializations = [...user.data.profile.additionalSpecializations];
            let existentIndex = false;
            additionalSpecializations.forEach((item, index) => {
                if (item === value) {
                    additionalSpecializations.splice(index, 1);
                    existentIndex = true;
                }
            });
            if (!existentIndex) {
                additionalSpecializations.push(value);
            }
            value = additionalSpecializations;
        }

        const profile = {...user.data.profile, [key]: value};
        setUser({...user, data: {...user.data, profile: profile}});

        changeUserProfileMethod(params.id, profile).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные профиля сохранены'});
        });
    };

    const selectedSpecializations = specializations.data.filter((item) => item.key === user.data.profile.specialization);

    const regenerateAndSendPassword = () => {
        regenerateAndSendPasswordMethod(params.id).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            reloadData();
            setNotification({type: 'success', text: 'Пароль сгенерирован и выслан'});
        });
    };

    const inviteUser = () => {
        inviteUserMethod(params.id).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            reloadData();
            setNotification({type: 'success', text: 'Пользователь приглашен в систему'});
        });
    };

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year} года`;
    }

    const username = user.data.contact.firstName + ' ' + user.data.contact.lastName;
    const userspecialization = selectedSpecializations.length > 0 ? selectedSpecializations[0].text : null;

    const [isDeactivateCalloutVisible, { toggle: toggleIsDeactivateCalloutVisible }] = useBoolean(false);
    const deactivateButtonId = useId('deactivate-button');

    return (
        <div>
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={10}
                ariaLabel="Навигация"
                overflowAriaLabel="Показать больше"
                style={{marginBottom: 20}}
            />
            <Notification type={notification.type} text={notification.text} />
            <div style={{marginBottom: 15}}>
                <div style={{ marginBottom: 15, fontSize: FontSizes.size24 }}>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        {user.data.profile.avatarId
                            ? <Persona imageUrl={process.env.REACT_APP_API_URL + '/files/' + user.data.profile.avatarId} text={username} secondaryText={userspecialization} size={PersonaSize.size56} />
                            : <Persona text={username} secondaryText={userspecialization} size={PersonaSize.size56} />
                        }
                        {/*<div style={{fontSize: FontSizes.size24}}>{user.data.contact.firstName} {user.data.contact.lastName}</div>*/}
                    </Stack>
                </div>
            </div>
            <Pivot aria-label="Меню пользователя" linkFormat="tabs" styles={{link: {height: 32, marginRight: 10, backgroundColor: 'rgb(248 248 246)'}}}>
                <PivotItem headerText="Данные контакта">
                    <div style={{marginTop: 20}}>
                        <Stack horizontal tokens={{ childrenGap: 50 }}>
                            <div>
                                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                    <TextField label="Имя" value={user.data.contact.firstName} onChange={(e) => changeContact('firstName', e.target.value)} />
                                    <TextField label="Фамилия" value={user.data.contact.lastName} onChange={(e) => changeContact('lastName', e.target.value)} />
                                    <TextField label="Телефон" value={user.data.contact.phoneNumber} onChange={(e) => changeContact('phoneNumber', e.target.value)} />
                                    <TextField label="Email" value={user.data.contact.email} onChange={(e) => changeContact('email', e.target.value)} />
                                    <TextField label="Telegram username (без @)" value={user.data.contact.telegramUsername} onChange={(e) => changeContact('telegramUsername', e.target.value)} />
                                </Stack>
                            </div>
                        </Stack>
                    </div>
                </PivotItem>
                <PivotItem headerText="Профиль">
                    <div style={{marginTop: 20}}>
                        <Stack horizontal tokens={{ childrenGap: 50 }}>
                            <div>
                                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                                    <Dropdown
                                        placeholder="Выберите"
                                        label="Специализация"
                                        options={specializations.data}
                                        style={{marginBottom: 10}}
                                        selectedKey={user.data.profile.specialization}
                                        onChange={(e, option) => changeProfile('specialization', option.key)}
                                    />
                                    <Dropdown
                                        placeholder="Выберите"
                                        label="Доп. специализации"
                                        options={specializations.data}
                                        style={{marginBottom: 10}}
                                        selectedKeys={user.data.profile.additionalSpecializations}
                                        onChange={(e, option) => changeProfile('additionalSpecializations', option.key)}
                                        multiSelect={true}
                                    />
                                    <TextField label="Город" value={user.data.profile.city} onChange={(e) => changeProfile('city', e.target.value)} />
                                    <TextField label="Сайт" value={user.data.profile.website} onChange={(e) => changeProfile('website', e.target.value)} />
                                    <TextField label="О себе" multiline rows={5} value={user.data.profile.about} onChange={(e) => changeProfile('about', e.target.value)} />
                                </Stack>
                            </div>
                        </Stack>
                    </div>
                </PivotItem>
                {user.data.status === 'ACTIVE'
                    ? <PivotItem headerText="Доступы">
                        <div style={{marginTop: 20}}>
                            <ChangePermissions user={user} setUser={setUser} setNotification={setNotification} />
                        </div>
                    </PivotItem>
                    : <></>
                }
                <PivotItem headerText="Вход в систему">
                    <div style={{marginTop: 20}}>
                        {user.data.status === 'ACTIVE'
                            ? !user.data.hasPassword
                                ? <div style={{marginTop: 20}}>
                                    <div style={{ marginTop: 15 }}>
                                        <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> новый пользователь без доступа к системе</div>
                                    </div>
                                    <Separator />
                                    <div>
                                        <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Выслать приглашение на email с паролем?</div>
                                        <PrimaryButton text="Выслать" iconProps={{ iconName: 'Mail' }} onClick={inviteUser} />
                                    </div>
                                </div>
                                : <div style={{marginTop: 20}}>
                                    <div style={{ marginTop: 15 }}>
                                        <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> пароль обновлён <span style={{ fontWeight: 600 }}>{formatDate(new Date(user.data.passwordResetAt ? user.data.passwordResetAt : user.data.createdAt))}</span></div>
                                    </div>
                                    <Separator />
                                    <div style={{marginBottom: 90}}>
                                        <div style={{ fontSize: FontSizes.size14, marginBottom: 15, fontWeight: 600 }}>Сгенерировать новый пароль и отправить на email?</div>
                                        <DefaultButton text="Сгенерировать и отправить" iconProps={{ iconName: 'PasswordField' }} onClick={regenerateAndSendPassword} />
                                    </div>
                                    <div style={{opacity: 0.3}}>
                                        <div style={{ fontSize: FontSizes.size14, marginBottom: 10 }}>Так же вы можете отключить этого пользователя от системы.<br />Он навсегда потеряет доступ.</div>
                                        <DefaultButton id={deactivateButtonId} text="Отключить" iconProps={{ iconName: 'PlugDisconnected' }} onClick={(e) => toggleIsDeactivateCalloutVisible()} />
                                    </div>
                                    {isDeactivateCalloutVisible && (
                                        <Callout
                                            role="dialog"
                                            gapSpace={0}
                                            target={`#${deactivateButtonId}`}
                                            onDismiss={toggleIsDeactivateCalloutVisible}
                                            setInitialFocus
                                        >
                                            <div style={{padding: 20}}>
                                                <Stack tokens={{ childrenGap: 10 }}>
                                                    <div style={{ fontSize: FontSizes.size14, fontWeight: 600 }}>Отключить этого пользователя?<br />Действие нельзя будет отменить.</div>
                                                    <DefaultButton text="Подтвердить действие" iconProps={{ iconName: 'Accept' }} onClick={async (e) => {
                                                        await deactivateEmployeeMethod(user.data.id);
                                                        navigate('/users#employees')
                                                    }} />
                                                </Stack>
                                            </div>
                                        </Callout>
                                    )}
                                </div>
                            : <div style={{marginTop: 20}}>
                                <div style={{ marginTop: 15 }}>
                                    <div style={{ fontSize: FontSizes.size14}}><span style={{ fontWeight: 600 }}>Статус:</span> неактивный пользователь</div>
                                </div>
                            </div>
                        }
                    </div>
                </PivotItem>
            </Pivot>
        </div>
    );
};
