import * as React from "react";
import Header from "./Header";
import Menu from "./Menu";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import {useEffect, useState} from "react";

export default function Template({component}) {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    });

    const isMobile = width <= 1000;

    initializeIcons();

    return (
        <>
            {/*<Header/>*/}
            <div style={{display: isMobile ? 'block' : 'flex', columnGap: 20}}>
                <Menu isMobile={isMobile} />
                <div style={{overflowX: 'auto', paddingBottom: 20, width: '100%', paddingTop: 0, paddingRight: 20, marginLeft: isMobile ? 20 : 225}}>
                    {component}
                </div>
            </div>
        </>
    );
};
