import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function getProjectCollectionMethod(
    text,
    status,
    milestone,
    manager,
    artdir,
    startedAtFrom,
    startedAtTo,
    presentationDateFrom,
    presentationDateTo,
    dueDateFrom,
    dueDateTo,
    sorting,
    services = [],
    page,
    archived = false,
    count = 10,
    lastPaidAtFrom = null,
    lastPaidAtTo = null,
    isOnlyMyProjects = 0
) {
    const searchParams = new URLSearchParams({
        text,
        status,
        milestone,
        manager,
        artdir,
        startedAtFrom: startedAtFrom ? startedAtFrom : '',
        startedAtTo: startedAtTo ? startedAtTo : '',
        presentationDateFrom: presentationDateFrom ? presentationDateFrom : '',
        presentationDateTo: presentationDateTo ? presentationDateTo : '',
        dueDateFrom: dueDateFrom ? dueDateFrom : '',
        dueDateTo: dueDateTo ? dueDateTo : '',
        sorting,
        services: services.join(','),
        page,
        archived,
        count,
        lastPaidAtFrom: lastPaidAtFrom ? lastPaidAtFrom : '',
        lastPaidAtTo: lastPaidAtTo ? lastPaidAtTo : '',
        isOnlyMyProjects: isOnlyMyProjects ?? 0
    });

    const url = new URL(serverUrl + '/api/v2/pc/projects?' + searchParams);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
