import * as React from "react";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import signProjectRealCostsMethod from "../../../../api/signProjectRealCostsMethod";
import getProjectWaitingRealCostsCollectionMethod from "../../../../api/getProjectWaitingRealCostsCollectionMethod";

export const tableColumns = (setNotification, realCostsPage, setWaitingRealCostsCollection) => [
    {
        key: 'code',
        name: '№ проекта',
        fieldName: 'code',
        maxWidth: 80,
        minWidth: 80,
        isRowHeader: true,
        data: 'string',
    },
    {
        key: 'contact',
        name: 'Заказчик',
        fieldName: 'contact',
        minWidth: 120,
        maxWidth: 200,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => {
            if (item.company !== null && item.company.name !== null) {
                return <div>{item.company.name}</div>;
            }

            if (item.contact.name !== null) {
                return <div>{(item.contact.lastName ?? '') + ' ' + (item.contact.firstName ?? item.contact.name) + ' ' + (item.contact.middleName ?? '')}</div>;
            }

            return <div></div>;
        }
    },
    {
        key: 'manager',
        name: 'Менеджер',
        fieldName: 'manager',
        isRowHeader: true,
        data: 'string',
        minWidth: 120,
        isPadded: true,
        onRender: (item) => {
            if (item.manager === null) {
                return <div>нет</div>;
            }

            return <div>{item.manager}</div>;
        }
    },
    {
        key: 'summaryPrice',
        name: 'Доход план',
        fieldName: 'summaryPrice',
        isRowHeader: true,
        data: 'string',
        minWidth: 80,
        isPadded: true,
        onRender: (item) => {
            if (!item.summaryPrice) {
                return <div>не указан</div>;
            }

            return <div>{new Intl.NumberFormat().format(item.summaryPrice)} руб.</div>;
        }
    },
    {
        key: 'paid',
        name: 'Доход факт',
        fieldName: 'paid',
        isRowHeader: true,
        data: 'string',
        minWidth: 80,
        isPadded: true,
        onRender: (item) => {
            if (!item.paid) {
                return <div>не указан</div>;
            }

            return <div>{new Intl.NumberFormat().format(item.paid)} руб.</div>;
        }
    },
    {
        key: 'summarySpending',
        name: 'Расход факт',
        fieldName: 'summarySpending',
        isRowHeader: true,
        data: 'string',
        minWidth: 80,
        isPadded: true,
        onRender: (item) => {
            if (!item.summarySpending) {
                return <div>не указан</div>;
            }

            return <div>{new Intl.NumberFormat().format(item.summarySpending)} руб.</div>;
        }
    },
    {
        key: 'marginPercent',
        name: 'Маржа',
        fieldName: 'marginPercent',
        isRowHeader: true,
        data: 'string',
        minWidth: 80,
        isPadded: true,
        onRender: (item) => {
            if (!item.marginPercent) {
                return <div>не указана</div>;
            }

            return <div>{parseInt(item.marginPercent)}%</div>;
        }
    },
    {
        key: 'milestone',
        name: 'Статус',
        fieldName: 'milestone',
        isRowHeader: true,
        minWidth: 80,
        isPadded: true,
    },
    {
        key: 'acceptClosing',
        name: 'Подтвердить закрытие',
        isRowHeader: true,
        data: 'string',
        minWidth: 130,
        maxWidth: 140,
        isPadded: true,
        onRender: (item) => {
            return <PrimaryButton className="accept-closing" text="Подтвердить" onClick={async () => {
                const response = await signProjectRealCostsMethod(item.id);
                if (response.error) {
                    setNotification({type: 'error', text: response.error});
                    return;
                }

                if (response.status === 'ok') {
                    setNotification({type: 'success', text: `Закрытие проекта ${item.code} подтверждено`});
                    getProjectWaitingRealCostsCollectionMethod(realCostsPage).then(response => {
                        if (response.error) {
                            console.log(response.error.message);
                            return;
                        }

                        setWaitingRealCostsCollection({loaded: true, data: response});
                    });
                }
            }}/>;
        }
    },
];
