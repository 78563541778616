import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import getWaitingClassicPaymentProjectCollectionMethod
    from "../../../../api/getWaitingClassicPaymentProjectCollectionMethod";
import {DetailsList, DetailsListLayoutMode, SelectionMode, Separator, Stack} from "@fluentui/react";
import Pagination from "../../../Theme/Pagination";
import {tableColumns} from "./tableColumns";
import Filters from "./Filters";

export default function WaitingClassicPaymentProjects() {
    const navigate = useNavigate();

    const storedFilterSelection = localStorage.getItem('FINANCE_WAITING_PAYMENT_FILTER') ? JSON.parse(localStorage.getItem('FINANCE_WAITING_PAYMENT_FILTER')) : {manager: 'ALL', status: 'ALL', startedAtFrom: null, startedAtTo: null, startedAtFastButtonId: null, sorting: 'START_DATE_NEW', text: '', services: []};
    const [filterSelection, setFilterSelection] = useState(storedFilterSelection);

    const [projectCollection, setProjectCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1, incomePlanSum: 0}});
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (!projectCollection.loaded) {
            getWaitingClassicPaymentProjectCollectionMethod(
                filterSelection.text,
                filterSelection.status,
                filterSelection.manager,
                filterSelection.startedAtFrom,
                filterSelection.startedAtTo,
                filterSelection.sorting,
                filterSelection.services,
                page
            ).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProjectCollection({loaded: true, data: response});
            });
        }
    });

    const reloadCollection = (freshFilterSelection) => {
        getWaitingClassicPaymentProjectCollectionMethod(
            freshFilterSelection.text,
            freshFilterSelection.status,
            freshFilterSelection.manager,
            freshFilterSelection.startedAtFrom,
            freshFilterSelection.startedAtTo,
            freshFilterSelection.sorting,
            freshFilterSelection.services,
            page
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getWaitingClassicPaymentProjectCollectionMethod(
            filterSelection.text,
            filterSelection.status,
            filterSelection.manager,
            filterSelection.startedAtFrom,
            filterSelection.startedAtTo,
            filterSelection.sorting,
            filterSelection.services,
            page
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    const changeFilter = (newFilterSelection) => {
        localStorage.setItem('FINANCE_WAITING_PAYMENT_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    const waitingClassicPaymentCountLastDigit = parseInt(projectCollection.data.count.toString().slice(-1));
    let waitingClassicPaymentCountWord = '';
    if (waitingClassicPaymentCountLastDigit === 1) {
        waitingClassicPaymentCountWord = 'проект';
    } else if (waitingClassicPaymentCountLastDigit > 1 && waitingClassicPaymentCountLastDigit < 5) {
        waitingClassicPaymentCountWord = 'проекта';
    } else {
        waitingClassicPaymentCountWord = 'проектов';
    }

    return <>
        <Filters filterSelection={filterSelection} changeFilter={changeFilter} />
        <Stack horizontal tokens={{childrenGap: 10}} style={{marginTop: 20}}>
            <div>Найдено: <span style={{fontWeight: 600}}>{projectCollection.data.count}</span> {waitingClassicPaymentCountWord}</div>
            <Separator vertical/>
            <div>План поступлений: <span
                style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.incomePlanSum)}</span> руб.
            </div>
        </Stack>
        <DetailsList
            items={projectCollection.data.collection}
            compact={false}
            columns={tableColumns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderRow={(props, Row) => {
                return <div style={{cursor: 'pointer'}} onClick={(e) => {
                    if (e.target.classList.contains('archive')) {
                        return;
                    }

                    navigate('/finance/' + props.item.id + '/only-payment-steps');
                }}>
                    <Row {...props} />
                </div>;
            }}
        />
        <div style={{marginTop: 20}}>
            <Pagination page={page} lastPage={projectCollection.data.lastPage} renewPage={renewPage} />
        </div>
    </>;
};
