import * as React from "react";

export const tableColumns = [
    {
        key: 'code',
        name: 'Номер проекта',
        fieldName: 'code',
        maxWidth: 150,
        minWidth: 80,
        isRowHeader: true,
        data: 'string',
    },
    {
        key: 'paymentStepTitle',
        name: 'Тип платежа',
        isRowHeader: true,
        data: 'string',
        maxWidth: 160,
        minWidth: 80,
        isPadded: true,
        onRender: (item) => {
            if (item.prepayment.type === 'CLASSIC' && item.prepayment.status === 'WAITING_PAYMENT') {
                return <div>{item.prepayment.title}</div>;
            }

            if (item.postPayment.type === 'CLASSIC' && item.postPayment.status === 'WAITING_PAYMENT') {
                return <div>{item.postPayment.title}</div>;
            }

            let paymentStepTitle = null;
            item.paymentSteps.forEach((paymentStep) => {
                if (paymentStep.type === 'CLASSIC' && paymentStep.status === 'WAITING_PAYMENT') {
                    paymentStepTitle = paymentStep.title;
                }
            });

            return <div>{paymentStepTitle}</div>;
        }
    },
    {
        key: 'paymentSum',
        name: 'Сумма платежа, руб.',
        fieldName: 'paymentSum',
        isRowHeader: true,
        data: 'string',
        maxWidth: 180,
        minWidth: 80,
        isPadded: true,
        onRender: (item) => {
            if (item.prepayment.type === 'CLASSIC' && item.prepayment.status === 'WAITING_PAYMENT') {
                return <div>{new Intl.NumberFormat().format(item.prepayment.sum)} руб.</div>;
            }

            if (item.postPayment.type === 'CLASSIC' && item.postPayment.status === 'WAITING_PAYMENT') {
                return <div>{new Intl.NumberFormat().format(item.postPayment.sum)} руб.</div>;
            }

            let paymentStepSum = null;
            item.paymentSteps.forEach((paymentStep) => {
                if (paymentStep.type === 'CLASSIC' && paymentStep.status === 'WAITING_PAYMENT') {
                    paymentStepSum = new Intl.NumberFormat().format(paymentStep.sum) + ' руб.';
                }
            });

            return <div>{paymentStepSum}</div>;
        }
    },
    {
        key: 'customer',
        name: 'Заказчик',
        maxWidth: 200,
        minWidth: 80,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => {
            if (item.company !== null && item.company.name !== null) {
                return <div>{item.company.name}</div>;
            }

            if (item.contact.name !== null) {
                return <div>{(item.contact.lastName ?? '') + ' ' + (item.contact.firstName ?? item.contact.name) + ' ' + (item.contact.middleName ?? '')}</div>;
            }

            return <div></div>;
        }
    },
    {
        key: 'companyInn',
        name: 'ИНН Заказчика',
        maxWidth: 180,
        minWidth: 80,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => {
            if (item.company !== null && item.company.taxIdentificationNumber !== null) {
                return <div>{item.company.taxIdentificationNumber}</div>;
            }

            return <div>Не указан</div>;
        }
    },
    {
        key: 'name',
        name: 'Название проекта',
        fieldName: 'name',
        maxWidth: 350,
        minWidth: 120,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => {
            if (item.name === null) {
                return <div>не указано</div>;
            }

            return <div>{item.name}</div>;
        }
    },
    {
        key: 'manager',
        name: 'Менеджер',
        fieldName: 'manager',
        isRowHeader: true,
        data: 'string',
        minWidth: 120,
        isPadded: true,
        onRender: (item) => {
            if (!item.manager) {
                return <div></div>;
            }

            return <div>{item.manager}</div>;
        }
    },
    {
        key: 'status',
        name: 'Статус',
        fieldName: 'status',
        maxWidth: 150,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => {
            if (item.status === 'IN_PROGRESS') {
                return <div>В работе</div>;
            }

            if (item.status === 'HOLD') {
                return <div>Холд</div>;
            }

            return <div>Закрыт</div>;
        }
    },
];
