import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function getPaymentCollectionMethod(
    text,
    status,
    manager,
    sorting,
    services = [],
    paidAtFrom,
    paidAtTo,
    page
) {
    const searchParams = new URLSearchParams({
        text,
        status,
        manager,
        sorting,
        services: services.join(','),
        paidAtFrom: paidAtFrom ?? '',
        paidAtTo: paidAtTo ?? '',
        page
    });
    const url = new URL(serverUrl + '/api/v2/pc/payments?' + searchParams);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
