import * as React from "react";
import {Stack, TextField} from "@fluentui/react";
import changeRequestContactMethod from "../../../../api/changeRequestContactMethod";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {FontSizes} from "@fluentui/theme";

export default function Contact({project, setProject, setNotification}) {
    const change = (key, value) => {
        const contact = {...project.data.contact, [key]: value};
        setProject({...project, data: {...project.data, contact: contact}});

        changeRequestContactMethod(project.data.id, contact).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Контакты клиента сохранены'});
        });
    };

    return  <Stack horizontal tokens={{ childrenGap: 50 }}>
        <div>
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                <TextField label="Фамилия" value={project.data.contact.lastName} onChange={(e) => change('lastName', e.target.value)} />
                <TextField label="Имя" value={project.data.contact.name} onChange={(e) => change('name', e.target.value)} />
                <TextField label="Отчество" value={project.data.contact.middleName} onChange={(e) => change('middleName', e.target.value)} />
                <TextField label="Телефон" value={project.data.contact.phoneNumber} onChange={(e) => change('phoneNumber', e.target.value)} />
                <TextField label="Email" value={project.data.contact.email} onChange={(e) => change('email', e.target.value)} />
                <TextField label="Telegram username" value={project.data.contact.telegramUsername} onChange={(e) => change('telegramUsername', e.target.value)} />
                <TextField label="Instagram username" value={project.data.contact.instagramUsername} onChange={(e) => change('instagramUsername', e.target.value)} />
            </Stack>
        </div>
    </Stack>;
}
