import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function addRequestMethod(contact, sourceType, companyName, createdAt, sendNotification) {
    const url = new URL(serverUrl + '/api/v2/pc/add-request');

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({contact: contact, sourceType: sourceType, companyName: companyName, createdAt: createdAt, sendNotification: sendNotification}),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
