import * as React from "react";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {Stack} from "@fluentui/react";

export default function Pagination({page, lastPage, renewPage}) {
    let pages = [];

    const surroundingPages = [];

    for (let i = page; i > 0; i--) {
        if (page - i > 2) {
            break;
        }

        surroundingPages[i] = true;
    }

    for (let i = page; i <= lastPage; i++) {
        if (i - page > 2) {
            break;
        }

        surroundingPages[i] = true;
    }

    let lastStringType = '';

    if (lastPage > 5 && page === 4) {
        pages.push(<DefaultButton className='paginationButton' key={0} text="1" onClick={() => renewPage(1)} disabled={page === 1} />);
    }

    if (lastPage > 5 && page === 5) {
        pages.push(<DefaultButton className='paginationButton' key={0} text="1" onClick={() => renewPage(1)} disabled={page === 1} />);
        pages.push(<DefaultButton className='paginationButton' key={1} text="2" onClick={() => renewPage(1)} disabled={page === 1} />);
    }

    if (lastPage > 5 && page > 5) {
        pages.push(<DefaultButton className='paginationButton' key={0} text="1" onClick={() => renewPage(1)} disabled={page === 1} />);
        pages.push(<DefaultButton className='paginationButton' key={1} text="..." disabled={true} />);
    }

    for (let i = 1; i <= lastPage; i++) {
        if (surroundingPages[i]) {
            pages.push(<DefaultButton className='paginationButton' key={i} text={i} onClick={() => renewPage(i)} disabled={page === i} />);
            lastStringType = "number"
            continue
        }

        if (lastStringType === "number") {
            pages.push(<DefaultButton className='paginationButton' key={i} text="..." disabled={true} />);
            lastStringType = "ellipsis"
        }
    }

    return <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton className='paginationButton' iconProps={{iconName: 'ChevronLeftMed', style: {fontSize: 12}}} onClick={() => renewPage(page - 1)} disabled={page === 1} />
        {pages.map((item) => item)}
        <PrimaryButton className='paginationButton' iconProps={{iconName: 'ChevronRightMed', style: {fontSize: 12}}} onClick={() => renewPage(page + 1)} disabled={page === lastPage} />
    </Stack>;
}
