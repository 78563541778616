import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function exportProjectsToExcelMethod(text, status, milestone, manager, artdir, startedAtFrom, startedAtTo, presentationDateFrom, presentationDateTo, dueDateFrom, dueDateTo, services = []) {
    const searchParams = new URLSearchParams({
        text,
        status,
        milestone,
        manager,
        artdir,
        startedAtFrom: startedAtFrom ? startedAtFrom : '',
        startedAtTo: startedAtTo ? startedAtTo : '',
        presentationDateFrom: presentationDateFrom ? presentationDateFrom : '',
        presentationDateTo: presentationDateTo ? presentationDateTo : '',
        dueDateFrom: dueDateFrom ? dueDateFrom : '',
        dueDateTo: dueDateTo ? dueDateTo : '',
        services: services ? services.join(',') : null
    });
    const url = new URL(serverUrl + '/api/v2/pc/export-projects-to-excel?' + searchParams);
    let filename = '';

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => {
        const header = response.headers.get('Content-Disposition');
        const parts = header.split(';');
        filename = parts[1].split('=')[1].replaceAll("\"", "");
        return response.blob()
    }).then(data => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
    });
}
