import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function exportYearlyStatisticsByProjectsToExcelMethod() {
    const url = new URL(serverUrl + '/api/v2/pc/export-yearly-statistics-by-projects-to-excel');
    let filename = '';

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => {
        const header = response.headers.get('Content-Disposition');
        const parts = header.split(';');
        filename = parts[1].split('=')[1].replaceAll("\"", "");
        return response.blob()
    }).then(data => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
    });
}
