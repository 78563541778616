import * as React from "react";
import {
    ActionButton,
    Callout,
    Checkbox, ChoiceGroup,
    DatePicker,
    DayOfWeek,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    IconButton,
    Layer,
    mergeStyleSets,
    NormalPeoplePicker,
    Overlay,
    Pivot,
    PivotItem,
    Popup,
    PrimaryButton,
    SelectionMode,
    Separator,
    Stack,
    TextField, Toggle
} from "@fluentui/react";
import changeProjectFinanceMethod from "../../../../../api/changeProjectFinanceMethod";
import MoneyField from "../../../../MoneyField";
import {FontSizes} from "@fluentui/theme";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useEffect, useRef, useState} from "react";
import getUserCollectionMethod from "../../../../../api/getUserCollectionMethod";
import {datePickerStringsRu} from "../../../../data/datePickerStringsRu";
import updateFinanceWorkerMethod from "../../../../../api/updateFinanceWorkerMethod";
import makePrepaymentMethod from "../../../../../api/makePrepaymentMethod";
import addFinanceWorkerMethod from "../../../../../api/addFinanceWorkerMethod";
import {FontIcon} from "@fluentui/react/lib/Icon";
import removeFinanceWorkerMethod from "../../../../../api/removeFinanceWorkerMethod";
import PaymentSteps from "./PaymentSteps";
import Comments from "../../../../Shared/Comments";
import makeNewSpendingTableMethod from "../../../../../api/makeNewSpendingTableMethod";
import {useBoolean, useId} from "@fluentui/react-hooks";
import changeProjectSummaryPriceMethod from "../../../../../api/changeProjectSummaryPriceMethod";

let estSpendsBefore = null;
let spendsBefore = null;

const popupStyles = mergeStyleSets({
    root: {
        background: 'rgba(0, 0, 0, 0.2)',
        bottom: '0',
        left: '0',
        position: 'fixed',
        right: '0',
        top: '0',
    },
    content: {
        background: 'white',
        left: '50%',
        maxWidth: '80%',
        padding: '0 2em 3em',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

export default function Finance({project, setProject, setNotification, reloadData, canChangeFinanceFact = false, isShorty = false, canFreeChangeSpendingTablePlan = false, canMakeNewSpendingTable = false, isProject = false}) {
    const [users, setUsers] = useState({loaded: false, data: []});
    const [estSpendsChanged, setEstSpendsChanged] = useState(false);
    const [spendsChanged, setSpendsChanged] = useState(false);
    const [enableToChangePrepaymentDate, setEnableToChangePrepaymentDate] = useState(false);
    // const [isSelectInputCalloutVisible, { toggle: toggleSelectInputCallout, setFalse: hideSelectInputCallout }] = useBoolean(false);
    const [otherInput, setOtherInput] = useState({});
    const [isChangedAtVisible, setIsChangedAtVisible] = useState({});
    const [spendingTableId, setSpendingTableId] = useState('current');
    const [isSpendingTableInfoVisible, { setTrue: showSpendingTableInfo, setFalse: hideSpendingTableInfo }] = useBoolean(false);
    const spendingTableInfoBtn = useId('spending-table-info-btn');
    const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
    const popupRef = useRef(null);
    const [contractPriceChangeForm, setContractPriceChangeForm] = useState({status: 'INIT', type: null, upsellPrice: null, upsellPaymentType: null, upsellPaymentTitle: null, onlinePaymentId: null, newSum: null, isCanceled: false});

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.classList.contains('ms-Button-flexContainer') && !event.target.classList.contains('ms-Dropdown-optionText') && event.target.nodeName !== 'INPUT') {
            clearContractPriceChangeForm();
            hidePopup();
        }
    };

    if (!estSpendsChanged) {
        estSpendsBefore = project.data.finance.estimatedSummarySpending;
    }
    if (!spendsChanged) {
        spendsBefore = project.data.finance.summarySpending;
    }

    useEffect(() => {
        if (!users.loaded) {
            getUserCollectionMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUsers({loaded: true, data: response});
            });
        }

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const changeFinance = (key, value, value2 = null, workers = null) => {
        // setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value}}});

        let isSteppedPayment = project.data.finance.isSteppedPayment;
        let prepaymentTitle = project.data.finance.prepayment.title;
        let postPaymentTitle = project.data.finance.postPayment.title;
        let summaryPrice = project.data.finance.summaryPrice;
        let estimatedSummarySpending = project.data.finance.estimatedSummarySpending;
        let summarySpending = project.data.finance.summarySpending;
        let summaryMargin = project.data.finance.summaryMargin;
        let marginPercent = project.data.finance.marginPercent;
        let estimatedSummaryMargin = project.data.finance.estimatedSummaryMargin;
        let estimatedMarginPercent = project.data.finance.estimatedMarginPercent;

        let prepaymentType = project.data.finance.prepayment.type;
        let prepaymentSum = project.data.finance.prepayment.sum;
        let prepaymentStatus = project.data.finance.prepayment.status;
        let prepaymentPaidAt = project.data.finance.prepayment.paidAt;

        let postPaymentType = project.data.finance.postPayment.type;
        let postPaymentSum = project.data.finance.postPayment.sum;
        let postPaymentStatus = project.data.finance.postPayment.status;
        let postPaymentPaidAt = project.data.finance.postPayment.paidAt;

        let prepaymentPercent, postPaymentPercent;

        switch (key) {
            case 'isSteppedPayment':
                isSteppedPayment = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, isSteppedPayment: isSteppedPayment}}})
                break;
            case 'prepaymentTitle':
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'title': value}}}});
                break;
            case 'prepaymentType':
                prepaymentType = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'type': value}}}});
                break;
            case 'prepaymentSum':
                prepaymentSum = value;
                prepaymentPercent = parseInt(prepaymentSum / summaryPrice * 100);
                if (!isSteppedPayment) {
                    prepaymentTitle = prepaymentPercent === 100 ? 'Оплата' : 'Предоплата ' + prepaymentPercent + '%';
                }
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'sum': value, title: prepaymentTitle}}}});
                break;
            case 'prepaymentStatus':
                prepaymentStatus = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'status': value}}}});
                break;
            case 'prepaymentPaidAt':
                if (value === project.data.finance.prepayment.paidAt) {
                    prepaymentPaidAt = null;
                } else {
                    prepaymentPaidAt = value;
                }
                setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'paidAt': prepaymentPaidAt}}}});
                break;
            case 'postPaymentType':
                postPaymentType = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'type': value}}}});
                break;
            case 'postPaymentSum':
                postPaymentSum = value;
                postPaymentPercent = parseInt(postPaymentSum / summaryPrice * 100);
                if (!isSteppedPayment) {
                    postPaymentTitle = 'Постоплата ' + postPaymentPercent + '%';
                }
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'sum': value, title: postPaymentTitle}}}});
                break;
            case 'postPaymentStatus':
                postPaymentStatus = value;
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'status': value}}}});
                break;
            case 'postPaymentPaidAt':
                if (value === project.data.finance.postPayment.paidAt) {
                    postPaymentPaidAt = null;
                } else {
                    postPaymentPaidAt = value;
                }
                setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'paidAt': postPaymentPaidAt}}}});
                break;
            case 'summaryPrice':
                summaryPrice = value;

                if (estimatedSummarySpending) {
                    estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                    // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                    estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                    // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
                } else {
                    estimatedSummaryMargin = null;
                    estimatedMarginPercent = null;
                }

                if (summarySpending) {
                    summaryMargin = actualSummaryPrice - summarySpending;
                    // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                    marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                    // marginPercent = marginPercent < 0 ? null : marginPercent;
                } else {
                    summaryMargin = null;
                    marginPercent = null;
                }

                prepaymentPercent = parseInt(prepaymentSum / summaryPrice * 100);
                if (!isSteppedPayment) {
                    prepaymentTitle = prepaymentPercent === 100 ? 'Оплата' : 'Предоплата ' + prepaymentPercent + '%';
                }
                postPaymentPercent = parseInt(postPaymentSum / summaryPrice * 100);
                if (!isSteppedPayment) {
                    postPaymentTitle = 'Постоплата ' + postPaymentPercent + '%';
                }

                setProject({
                    ...project,
                    data: {
                        ...project.data,
                        finance: {
                            ...project.data.finance,
                            [key]: value,
                            'estimatedSummaryMargin': estimatedSummaryMargin,
                            'estimatedMarginPercent': estimatedMarginPercent,
                            'summaryMargin': summaryMargin,
                            'marginPercent': marginPercent,
                            prepayment: {...project.data.finance.prepayment, title: prepaymentTitle},
                            postPayment: {...project.data.finance.postPayment, title: postPaymentTitle},
                        }
                    }
                });
                break;
            case 'estimatedSummarySpending':
                estimatedSummarySpending = value;

                if (estimatedSummarySpending) {
                    estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                    // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                    estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                    // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
                } else {
                    estimatedSummaryMargin = null;
                    estimatedMarginPercent = null;
                }
                setEstSpendsChanged(true);
                setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value, 'estimatedSummaryMargin': estimatedSummaryMargin, 'estimatedMarginPercent': estimatedMarginPercent}}});
                break;
            case 'summarySpending':
                summarySpending = value;

                if (summarySpending) {
                    summaryMargin = actualSummaryPrice - summarySpending;
                    // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                    marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                    // marginPercent = marginPercent < 0 ? null : marginPercent;
                } else {
                    summaryMargin = null;
                    marginPercent = null;
                }
                setSpendsChanged(true);
                setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value, 'summaryMargin': summaryMargin, 'marginPercent': marginPercent}}});
                break;
            // case 'summaryMargin':
            //     summaryMargin = value;
            //     break;
            // case 'marginPercent':
            //     marginPercent = value;
            //     break;
            // case 'estimatedSummaryMargin':
            //     estimatedSummaryMargin = value;
            //     break;
            // case 'estimatedMarginPercent':
            //     estimatedMarginPercent = value;
            //     break;
            case 'estimatedSummarySpending|summarySpending':
                estimatedSummarySpending = value;
                if (estimatedSummarySpending) {
                    estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                    // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                    estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                    // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
                } else {
                    estimatedSummaryMargin = null;
                    estimatedMarginPercent = null;
                }

                summarySpending = value2;
                if (summarySpending) {
                    summaryMargin = actualSummaryPrice - summarySpending;
                    // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                    marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                    // marginPercent = marginPercent < 0 ? null : marginPercent;
                } else {
                    summaryMargin = null;
                    marginPercent = null;
                }

                setEstSpendsChanged(true);
                setSpendsChanged(true);
                setProject({...project, data: {...project.data, finance: {...project.data.finance, estimatedSummarySpending: value, 'estimatedSummaryMargin': estimatedSummaryMargin, 'estimatedMarginPercent': estimatedMarginPercent, summarySpending: value2, 'summaryMargin': summaryMargin, 'marginPercent': marginPercent, workers: workers}}});
                break;
            default:
                break;
        }

        const data = {
            id: project.data.id,
            summaryPrice: summaryPrice,
            estimatedSummarySpending: estimatedSummarySpending,
            summarySpending: summarySpending,
            summaryMargin: summaryMargin,
            marginPercent: marginPercent,
            estimatedSummaryMargin: estimatedSummaryMargin,
            estimatedMarginPercent: estimatedMarginPercent,
            prepaymentType: prepaymentType,
            prepaymentSum: prepaymentSum,
            prepaymentTitle: prepaymentTitle,
            prepaymentStatus: prepaymentStatus,
            prepaymentPaidAt: prepaymentPaidAt,
            postPaymentType: postPaymentType,
            postPaymentSum: postPaymentSum,
            postPaymentTitle: postPaymentTitle,
            postPaymentStatus: postPaymentStatus,
            postPaymentPaidAt: postPaymentPaidAt,
            isSteppedPayment: isSteppedPayment,
        };

        changeProjectFinanceMethod(data).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные обновлены'});
        });
    };

    const getCurrentParticipantUsersByType = (type) => {
        const participants = project.data.participants.filter((participant) => participant.type === type);
        const collection = [];

        for (const participant of participants) {
            for (const user of users.data) {
                if (user.id === participant.userId) {
                    collection.push({
                        id: user.id,
                        projectsCount: user.projectsCount,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        isValid: true,
                        key: user.id,
                        text: user.firstName + ' ' + (user.lastName ?? ''),
                    })
                }
            }
        }

        return collection;
    };

    const changeWorker = async (id, key, value) => {
        let workerId;
        const workers = project.data.finance.workers.map((worker) => {
            if (worker.id === id) {
                if ((key === 'userId' && worker.userId === null) || (key === 'username' && worker.userId !== null)) {
                    setOtherInput({...otherInput, [worker.id]: false});
                }
                if (key === 'username') {
                    worker.userId = null;
                    workerId = worker.id;
                }
                worker[key] = value;
                if (key === 'grossPrice') {
                    worker['grossPriceUpdatedAt'] = Date.now().valueOf();
                }
                if (key === 'planPrice') {
                    worker['planPriceUpdatedAt'] = Date.now().valueOf();
                    if (!worker['planPriceCreatedAt']) {
                        worker['planPriceCreatedAt'] = Date.now().valueOf();
                    }
                }
            }
            return worker;
        });

        setProject({...project, data: {...project.data, finance: {...project.data.finance, workers: workers}}});
        await updateFinanceWorkerMethod(project.data.id, id, key, value);
        if (workerId) {
            document.getElementById('worker-inp-' + workerId).focus();
        }
        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const removeWorker = async (id) => {
        const workers = project.data.finance.workers.filter((worker) => worker.id !== id);

        await removeFinanceWorkerMethod(project.data.id, id);
        await changeFinance('estimatedSummarySpending|summarySpending', calculatePlanSpending(workers), calculateFactSpending(workers), workers);

        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const getCurrentParticipants = (type) => {
        const participants = project.data.participants.filter((participant) => participant.type === type);
        const collection = [];

        for (const participant of participants) {
            for (const user of users.data) {
                if (user.id === participant.userId) {
                    collection.push(user.avatarId !== null
                        ? {
                            isValid: true,
                            key: user.id,
                            imageUrl: process.env.REACT_APP_API_URL + '/files/' + user.avatarId,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        }
                        : {
                            isValid: true,
                            key: user.id,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        })
                }
            }
        }

        return collection;
    }

    const columns = [
        {
            key: 'work',
            name: 'Вид работ',
            fieldName: 'work',
            minWidth: 270,
            maxWidth: 270,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => <div><TextField style={{paddingLeft: 12}} type="text" value={worker.work} onChange={(e) => changeWorker(worker.id, 'work', e.target.value)} /></div>,
        },
        {
            key: 'username',
            name: 'Исполнитель',
            fieldName: 'username',
            minWidth: 235,
            maxWidth: 235,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (worker) => {
                return <div style={{display: 'flex', gap: 10, alignItems: 'center', backgroundColor: '#f3f2f1'}}>
                    {worker.userId
                        ? <>
                            <Dropdown
                                style={{width: 245}}
                                placeholder="Выберите исполнителя"
                                options={getCurrentParticipants('WORKER')}
                                selectedKey={worker.userId}
                                onChange={(e, option) => changeWorker(worker.id, 'userId', option.key)}
                            />
                            {/*<NormalPeoplePicker*/}
                            {/*    styles={{root: {width: 300}}}*/}
                            {/*    onResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                            {/*    onEmptyResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                            {/*    className={'ms-PeoplePicker'}*/}
                            {/*    key={'normal'}*/}
                            {/*    selectionAriaLabel={'Selected contacts'}*/}
                            {/*    removeButtonAriaLabel={'Remove'}*/}
                            {/*    selectedItems={getCurrentParticipants('WORKER').filter(item => item.key === worker.userId)}*/}
                            {/*    onChange={async (values) => {*/}
                            {/*        if (values.length > 0) {*/}
                            {/*            await changeWorker(worker.id, 'userId', values[0].key);*/}
                            {/*        } else {*/}
                            {/*            await changeWorker(worker.id, 'userId', null);*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    itemLimit={1}*/}
                            {/*/>*/}
                            <FontIcon id={'other-inp-btn-' + worker.id} aria-label="Edit" iconName="InsertTextBox" style={{cursor: 'pointer', verticalAlign: '-webkit-baseline-middle', fontSize: 16, width: 30}} onClick={() => setOtherInput({...otherInput, [worker.id]: true})} />
                            {otherInput[worker.id] && (
                                <Callout
                                    style={{width: 260}}
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${'other-inp-btn-' + worker.id}`}
                                    onDismiss={() => setOtherInput({...otherInput, [worker.id]: false})}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20}}>
                                        <TextField
                                            type="text"
                                            value={worker.username}
                                            onChange={(e) => changeWorker(worker.id, 'username', e.target.value)}
                                        />
                                    </div>
                                </Callout>
                            )}
                        </>
                        : <>
                            <TextField
                                style={{paddingLeft: 8, width: 245}}
                                // styles={{root: {width: 164}}}
                                type="text"
                                value={worker.username}
                                onChange={(e) => changeWorker(worker.id, 'username', e.target.value)}
                                id={'worker-inp-' + worker.id}
                            />
                            <FontIcon id={'other-inp-btn-' + worker.id} aria-label="BulletedList" iconName="PeopleAdd" style={{cursor: 'pointer', verticalAlign: '-webkit-baseline-middle', fontSize: 16, width: 30}} onClick={() => setOtherInput({...otherInput, [worker.id]: true})} />
                            {otherInput[worker.id] && (
                                <Callout
                                    styles={{root: {width: 260}}}
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${'other-inp-btn-' + worker.id}`}
                                    onDismiss={() => setOtherInput({...otherInput, [worker.id]: false})}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20}}>
                                        <Dropdown
                                            placeholder="Выберите исполнителя"
                                            options={getCurrentParticipants('WORKER')}
                                            selectedKey={worker.userId}
                                            onChange={(e, option) => changeWorker(worker.id, 'userId', option.key)}
                                        />
                                        {/*<NormalPeoplePicker*/}
                                        {/*    onResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                                        {/*    onEmptyResolveSuggestions={() => getCurrentParticipants('WORKER')}*/}
                                        {/*    className={'ms-PeoplePicker'}*/}
                                        {/*    key={'normal'}*/}
                                        {/*    selectionAriaLabel={'Selected contacts'}*/}
                                        {/*    removeButtonAriaLabel={'Remove'}*/}
                                        {/*    selectedItems={getCurrentParticipants('WORKER').filter(item => item.key === worker.userId)}*/}
                                        {/*    onChange={async (values) => {*/}
                                        {/*        if (values.length > 0) {*/}
                                        {/*            await changeWorker(worker.id, 'userId', values[0].key);*/}
                                        {/*        } else {*/}
                                        {/*            await changeWorker(worker.id, 'userId', null);*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*    itemLimit={1}*/}
                                        {/*/>*/}
                                    </div>
                                </Callout>
                            )}
                        </>
                    }
                </div>;
            },
        },
        {
            key: 'planPrice',
            name: 'Расходы (план)',
            fieldName: 'planPrice',
            minWidth: 250,
            maxWidth: 250,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => {
                let canChangeByTime = true;
                if (worker.planPriceCreatedAt) {
                    const hoursPassed = Math.abs(Date.now().valueOf() - new Date(worker.planPriceCreatedAt).valueOf()) / 3600000;
                    canChangeByTime = hoursPassed <= 24;
                }
                return <div>
                    <Stack horizontal>
                        <div style={{width: '100%'}}>
                            <MoneyField value={worker.planPrice ?? null} onChange={async (e) => {
                                await changeWorker(worker.id, 'planPrice', e.target.value);
                                await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                            }} disabled={(!canFreeChangeSpendingTablePlan && !canChangeByTime) || !canMakeNewSpendingTable} />
                        </div>
                        <Dropdown
                              style={{width: 110}}
                              disabled={(!canFreeChangeSpendingTablePlan && !canChangeByTime) || !canMakeNewSpendingTable}
                              options={[
                                  { key: 'без налога', text: 'без налога' },
                                  { key: 'с налогом', text: 'с налогом' },
                              ]}
                              selectedKey={worker.priceType ?? 'без налога'}
                              onChange={async (e, option) => {
                                  if (worker.priceType === undefined && option.key === 'без налога') {
                                      await changeWorker(worker.id, 'priceType', option.key);
                                      return;
                                  }

                                  if (option.key === 'с налогом' && worker.planPrice) {
                                      await changeWorker(worker.id, 'priceType', option.key);
                                      await changeWorker(worker.id, 'planPrice', Math.round(worker.planPrice / 0.94));
                                      await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                                      return;
                                  }

                                  if (option.key === 'без налога' && worker.planPrice) {
                                      await changeWorker(worker.id, 'priceType', option.key);
                                      await changeWorker(worker.id, 'planPrice', Math.round(worker.planPrice * 0.94));
                                      await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                                      return;
                                  }
                              }}
                        />
                    </Stack>
                </div>;
            },
        },
        {
            key: 'grossPrice',
            name: 'Расходы (факт)',
            fieldName: 'grossPrice',
            minWidth: 230,
            maxWidth: 230,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => <div>
                <MoneyField value={worker.grossPrice} onChange={async (e) => {
                    await changeWorker(worker.id, 'grossPrice', e.target.value);
                    await changeFinance('summarySpending', calculateFactSpending(project.data.finance.workers));
                }} disabled={!canChangeFinanceFact} />
            </div>,
        },
        {
            name: '',
            isRowHeader: true,
            minWidth: 90,
            maxWidth: 90,
            data: 'string',
            onRender: (worker) => {
                let canChangeByTime = true;
                if (worker.planPriceCreatedAt) {
                    const hoursPassed = Math.abs(Date.now().valueOf() - new Date(worker.planPriceCreatedAt).valueOf()) / 3600000;
                    canChangeByTime = hoursPassed <= 24;
                }

                // if (!canChangeFinanceFact && !canChangeByTime) {
                //     return <></>;
                // }

                const options = [{ key: 'info', text: 'История' }];

                if (canFreeChangeSpendingTablePlan || canChangeByTime) {
                    options.push({ key: 'remove', text: 'Удалить' });
                }

                return <>
                    <Dropdown
                        id={'changedAtButton' + worker.id}
                        style={{width: '100%'}}
                        placeholder="Действие"
                        selectedKey={null}
                        options={options}
                        onChange={async (e, option) => {
                            if (option.key === 'remove' && (canFreeChangeSpendingTablePlan || canChangeByTime)) {
                                await removeWorker(worker.id);
                                return;
                            }

                            if (option.key === 'info') {
                                setIsChangedAtVisible({...isChangedAtVisible, [worker.id]: true});
                            }
                        }}
                    />
                    {isChangedAtVisible[worker.id] && (
                        <Callout
                            role="dialog"
                            gapSpace={0}
                            target={`#${'changedAtButton' + worker.id}`}
                            onDismiss={() => setIsChangedAtVisible({...isChangedAtVisible, [worker.id]: false})}
                            setInitialFocus
                        >
                            <div style={{padding: 20, paddingBottom: 30, width: 200}}>
                                <div style={{fontSize: 12, fontWeight: 600}}>Расходы план</div>
                                {worker.planPriceCreatedAt ? <div style={{fontSize: 12, marginTop: 3, color: 'gray'}}>Внесено: {formatDateTime(new Date(worker.planPriceCreatedAt))}</div> : <></>}
                                {worker.planPriceUpdatedAt ? <div style={{fontSize: 12, marginTop: 3, color: 'gray'}}>Изменено: {formatDateTime(new Date(worker.planPriceUpdatedAt))}</div> : <></>}
                                <div style={{marginTop: 10, fontSize: 12, fontWeight: 600}}>Рассходы факт</div>
                                {worker.grossPriceUpdatedAt ? <div style={{fontSize: 12, marginTop: 3, color: 'gray'}}>Изменено: {formatDateTime(new Date(worker.grossPriceUpdatedAt))}</div> : <></>}
                            </div>
                        </Callout>
                    )}
                </>;
            }
        },
    ];

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    const spendingTableHistoryOptions = [];
    project.data.finance.spendingTableHistory.forEach((item) => {
        spendingTableHistoryOptions.unshift({ key: item.id, text: 'от ' + formatDate(new Date(item.date)) });
    });
    spendingTableHistoryOptions.unshift({ key: 'current', text: 'Текущая версия' });

    const formatDateTime = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.shortMonths[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = `${date.getHours()}`.padStart(2, "0");
        const minutes = `${date.getMinutes()}`.padStart(2, "0");

        return `${day} ${month} ${year} в ${hours}:${minutes}`;
    }

    const calculateFactSpending = (workers) => {
        let spendingOnWorkers = 0;
        workers.forEach((worker) => {
            if (worker.grossPrice !== '') {
                spendingOnWorkers += parseFloat(worker.grossPrice ?? 0);
            }
        });
        return spendingOnWorkers === 0 ? null : spendingOnWorkers;
    };

    const calculatePlanSpending = (workers) => {
        let spendingOnWorkers = 0;
        workers.forEach((worker) => {
            if (worker.planPrice !== '') {
                spendingOnWorkers += parseFloat(worker.planPrice ?? 0);
            }
        });
        return spendingOnWorkers === 0 ? null : spendingOnWorkers;
    };

    const financeDisabled = project.data.finance.prepayment === null || project.data.finance.postPayment === null;

    const [prepaymentForm, setPrepaymentForm] = useState({id: project.data.id, summaryPrice: null, prepaymentSum: null, paymentType: null, services: project.data.services.map((service) => service.name)});

    const makePrepayment = async () => {
        const response = await makePrepaymentMethod(prepaymentForm.id, prepaymentForm.paymentType, prepaymentForm.summaryPrice, prepaymentForm.prepaymentSum, prepaymentForm.services);

        if (response.status === 'ok') {
            reloadData();
        }
    };

    if (financeDisabled) {
        return <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 300 } }}>
                <Dropdown
                    placeholder="Онлайн или по документам"
                    label="Тип платежа"
                    options={[
                        { key: 'ONLINE', text: 'Онлайн' },
                        { key: 'CLASSIC', text: 'По документам' },
                    ]}
                    selectedKey={prepaymentForm.paymentType}
                    onChange={(e, option) => setPrepaymentForm({...prepaymentForm, paymentType: option.key})}
                />
                <MoneyField label="Стоимость проекта" value={prepaymentForm.summaryPrice} onChange={(e) => setPrepaymentForm({...prepaymentForm, summaryPrice: e.target.value})} />
                <MoneyField label="Сумма предоплаты" value={prepaymentForm.prepaymentSum} onChange={(e) => setPrepaymentForm({...prepaymentForm, prepaymentSum: e.target.value})} />
                <PrimaryButton style={{marginTop: 30, height: 40}} text="Продолжить" onClick={makePrepayment} />
            </Stack>
        </Stack>;
    }

    const isEstimatedMarginTooLow = project.data.finance.estimatedMarginPercent <= 40;

    let actualSummaryPrice = 0;
    if (project.data.finance.prepayment.status === 'PAID') {
        actualSummaryPrice += parseInt(project.data.finance.prepayment.sum);
    }

    if (project.data.finance.postPayment.status === 'PAID') {
        actualSummaryPrice += parseInt(project.data.finance.postPayment.sum);
    }

    project.data.finance.paymentSteps.forEach((payment, i) => {
        if (payment.status === 'PAID') {
            actualSummaryPrice += parseInt(payment.sum);
        }
    });

    const clearContractPriceChangeForm = () => {
        if (contractPriceChangeForm.status === 'DONE') {
            reloadData();
            setNotification({type: 'success', text: 'Стоимость проекта изменена'});
        }

        setContractPriceChangeForm({status: 'INIT', type: null, upsellPrice: null, upsellPaymentType: null, upsellPaymentTitle: null, onlinePaymentId: null, newSum: null, isCanceled: false})
    };

    const changeContractPrice = async () => {
        const response = await changeProjectSummaryPriceMethod(project.data.id, contractPriceChangeForm);
        if (response.status === 'ok') {
            changeFinance('summaryPrice', response.summaryPrice);
            setContractPriceChangeForm({...contractPriceChangeForm, status: 'DONE', onlinePaymentId: response.onlinePaymentId ?? null});
        }
    };

    return <div>
        <div style={{marginTop: 20, marginBottom: 20, width: 430}}>
            {/*<ActionButton style={{height: 32}} iconProps={{ iconName: 'Edit' }} allowDisabledFocus onClick={showPopup}>*/}
            {/*    Корректировать сумму контракта*/}
            {/*</ActionButton>*/}
            {isPopupVisible && <Layer>
                <Popup
                    className={popupStyles.root}
                    role="dialog"
                    aria-modal="true"
                    onDismiss={() => {
                        clearContractPriceChangeForm();
                        hidePopup();
                    }}
                    enableAriaHiddenSiblings={true}
                >
                    <Overlay />
                    <div ref={popupRef} role="document" className={popupStyles.content} style={{overflowY: 'auto', maxHeight: '90vh', paddingTop: 15, paddingLeft: 20, width: 350, height: 330, paddingBottom: 20}}>
                        <div style={{marginBottom: 1}}>
                            <h3 style={{marginTop: 0, marginBottom: 10}}>Изменение суммы контракта</h3>
                            <div style={{fontSize: 12, color: 'gray'}}>
                                {contractPriceChangeForm.type === null && 'Шаг 1 / 3'}
                                {contractPriceChangeForm.type !== null && contractPriceChangeForm.status === 'INIT' && 'Шаг 2 / 3'}
                                {contractPriceChangeForm.status === 'DONE' && 'Шаг 3 / 3'}
                            </div>
                            <IconButton aria-label="Close" iconProps={{ iconName: 'ChromeClose', style: {color: '#1d1d1d', fontSize: 12} }} style={{height: 30, width: 30, borderRadius: 100, position: 'absolute', right: 10, top: 10}} onClick={() => {
                                clearContractPriceChangeForm();
                                hidePopup();
                            }} />
                        </div>
                        <div style={{width: '100%', paddingTop: 15}}>
                            <Stack style={{width: contractPriceChangeForm.status === 'DONE' ? '95%' : 240}} tokens={{ childrenGap: 10 }}>
                                {contractPriceChangeForm.status === 'INIT' && <>
                                    {contractPriceChangeForm.type === null && <ChoiceGroup
                                        style={{marginTop: 40}}
                                        selectedKey={contractPriceChangeForm.type}
                                        options={[
                                            { key: 'UPSELL', text: 'Доп. работы' },
                                            { key: 'CUT', text: 'Отказ от услуг' },
                                        ]}
                                        onChange={(e, option) => setContractPriceChangeForm({...contractPriceChangeForm, type: option.key})}
                                        label="Выберите причину:"
                                    />}
                                    {contractPriceChangeForm.type === 'UPSELL' && <>
                                        <MoneyField label="Сумма доплаты" value={contractPriceChangeForm.upsellPrice} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, upsellPrice: e.target.value})} />
                                        <TextField label="Назначение платежа" value={contractPriceChangeForm.upsellPaymentTitle} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, upsellPaymentTitle: e.target.value})} />
                                        <Dropdown
                                            placeholder="Онлайн или по документам"
                                            label="Форма оплаты"
                                            options={[
                                                { key: 'ONLINE', text: 'Онлайн' },
                                                { key: 'CLASSIC', text: 'По документам' },
                                            ]}
                                            selectedKey={contractPriceChangeForm.upsellPaymentType}
                                            onChange={(e, option) => setContractPriceChangeForm({...contractPriceChangeForm, upsellPaymentType: option.key})}
                                        />
                                        <PrimaryButton text="Далее" style={{marginTop: 33, width: 120}} onClick={changeContractPrice} disabled={contractPriceChangeForm.upsellPrice === null || contractPriceChangeForm.upsellPaymentTitle === null || contractPriceChangeForm.upsellPaymentType === null} />
                                    </>}
                                    {contractPriceChangeForm.type === 'CUT' && <>
                                        <div style={{marginTop: 40}}>
                                            <MoneyField label="Новая стоимость контракта" value={contractPriceChangeForm.newSum ?? project.data.finance.summaryPrice} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, newSum: e.target.value})} />
                                        </div>
                                        <div style={{marginTop: 15}}>
                                            <Checkbox label="Расторжение контракта" checked={contractPriceChangeForm.isCanceled} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, isCanceled: e.target.checked})} />
                                        </div>
                                        <PrimaryButton text="Далее" style={{marginTop: 99, width: 120}} onClick={changeContractPrice} disabled={contractPriceChangeForm.newSum === null} />
                                    </>}
                                </>}
                                {contractPriceChangeForm.status === 'DONE' && <>
                                    <div style={{marginTop: 50}}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 5}}>
                                            <Icon iconName="Accept" style={{color: '#ef5021'}} />
                                            <div style={{color: 'gray'}}>Стоимость проекта изменена</div>
                                        </div>
                                        {contractPriceChangeForm.type === 'UPSELL' && <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20}}>
                                            <Icon iconName="Accept" style={{color: '#ef5021'}} />
                                            <div style={{color: 'gray'}}>Добавлен новый платёж</div>
                                        </div>}
                                        {contractPriceChangeForm.type === 'CUT' && contractPriceChangeForm.isCanceled && <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20}}>
                                            <Icon iconName="Accept" style={{color: '#ef5021'}} />
                                            <div style={{color: 'gray'}}>Статус проекта изменён на CANCELED</div>
                                        </div>}
                                        {contractPriceChangeForm.type === 'UPSELL' && contractPriceChangeForm.upsellPaymentType === 'ONLINE' && <>
                                            <div style={{fontWeight: 600}}>Скопируйте ссылку для онлайн оплаты</div>
                                            {contractPriceChangeForm.onlinePaymentId && <DefaultButton text="Скопировать ссылку" style={{marginTop: 20}} iconProps={{iconName: 'Copy'}} onClick={async () => {
                                                await navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + contractPriceChangeForm.onlinePaymentId);
                                                clearContractPriceChangeForm();
                                                hidePopup();
                                                setNotification({type: 'success', text: 'Данные обновлены. Ссылка на онлайн-оплату скопирована.'});
                                            }} />}
                                        </>}
                                        {contractPriceChangeForm.type === 'UPSELL' && contractPriceChangeForm.upsellPaymentType === 'CLASSIC' && <>
                                            <div style={{fontWeight: 600}}>Загрузите ДС в файлы проекта</div>
                                        </>}
                                        {contractPriceChangeForm.type === 'CUT' && <>
                                            <div style={{fontWeight: 600}}>Скорректируйте платежи и загрузите ДС в файлы проекта</div>
                                        </>}
                                    </div>
                                </>}
                            </Stack>
                        </div>
                    </div>
                </Popup>
            </Layer>}
            <div style={{display: 'flex', gap: 20}}>
                <div style={{width: 430}}>
                    <div className="ms-fontWeight-semibold" style={{marginBottom: 10}}>Бюджет проекта</div>
                    <DetailsList
                        styles={{ root: { paddingTop: 0 } }}
                        items={[
                            // {
                            //     type: 'План',
                            //     price: project.data.finance.summaryPrice,
                            //     spending: project.data.finance.estimatedSummarySpending,
                            //     margin: project.data.finance.estimatedSummaryMargin,
                            //     percent: project.data.finance.estimatedMarginPercent,
                            // },
                            // {
                            //     type: 'Факт',
                            //     price: actualSummaryPrice,
                            //     spending: project.data.finance.summarySpending,
                            //     margin: project.data.finance.summaryMargin,
                            //     percent: project.data.finance.marginPercent,
                            // },

                            {
                                name: 'Доходы',
                                estimated: project.data.finance.summaryPrice,
                                real: actualSummaryPrice,
                            },
                            {
                                name: 'Расходы',
                                estimated: project.data.finance.estimatedSummarySpending,
                                real: project.data.finance.summarySpending,
                            },
                            {
                                name: 'Маржа',
                                estimated: project.data.finance.estimatedSummaryMargin,
                                real: project.data.finance.summaryMargin,
                            },
                            {
                                name: 'Маржа %',
                                estimated: project.data.finance.estimatedMarginPercent,
                                real: project.data.finance.marginPercent,
                            },
                        ]}
                        compact={false}
                        columns={[
                            {
                                key: 'name',
                                name: '',
                                minWidth: 70,
                                maxWidth: 70,
                                isRowHeader: true,
                                data: 'string',
                                onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 10, color: 'gray'}}>
                                    {item.name}
                                </div>,
                            },
                            {
                                key: 'estimated',
                                name: 'План',
                                minWidth: 150,
                                maxWidth: 150,
                                isRowHeader: true,
                                data: 'string',
                                onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', color: 'gray'}}>
                                    {item.name === 'Доходы' && <>
                                        <MoneyField value={item.estimated} disabled={true} />
                                        <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40, cursor: 'pointer'}} onClick={showPopup}>
                                            <Icon iconName="Edit" style={{color: '#ef5021'}} />
                                        </div>
                                    </>}
                                    {item.name === 'Расходы' && <>
                                        <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} />
                                        <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                        </div>
                                    </>}
                                    {item.name === 'Маржа' && <>
                                        <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} />
                                        <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                        </div>
                                    </>}
                                    {item.name === 'Маржа %' && <>
                                        <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} suffix="%" />
                                        <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                        </div>
                                    </>}
                                </div>,
                            },
                            {
                                key: 'real',
                                name: 'Факт',
                                minWidth: 150,
                                maxWidth: 150,
                                isRowHeader: true,
                                data: 'string',
                                onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', color: 'gray'}}>
                                    {item.name === 'Доходы' && <>
                                        <MoneyField value={item.real} disabled={true} />
                                    </>}
                                    {item.name === 'Расходы' && <>
                                        <MoneyField style={{color: 'black'}} value={item.real} disabled={true} />
                                    </>}
                                    {item.name === 'Маржа' && <>
                                        <MoneyField style={{color: 'black'}} value={item.real} disabled={true} />
                                    </>}
                                    {item.name === 'Маржа %' && <>
                                        <MoneyField style={{color: 'black'}} value={item.real} disabled={true} suffix="%" />
                                    </>}
                                </div>,
                            },

                            // {
                            //     key: 'type',
                            //     name: '',
                            //     minWidth: 50,
                            //     maxWidth: 50,
                            //     isRowHeader: true,
                            //     data: 'string',
                            //     onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 10, color: 'gray'}}>
                            //         {item.type}
                            //     </div>,
                            // },
                            // {
                            //     key: 'price',
                            //     name: 'Доходы',
                            //     minWidth: 150,
                            //     maxWidth: 150,
                            //     isRowHeader: true,
                            //     data: 'string',
                            //     onRender: (item) => <div>
                            //         {item.type === 'План'
                            //             ? <MoneyField value={item.price} onChange={(e) => changeFinance('summaryPrice', e.target.value)} disabled={financeDisabled} />
                            //             : <MoneyField value={item.price} disabled={true} />
                            //         }
                            //     </div>,
                            // },
                            // {
                            //     key: 'spending',
                            //     name: 'Расходы',
                            //     minWidth: 150,
                            //     maxWidth: 150,
                            //     isRowHeader: true,
                            //     data: 'string',
                            //     onRender: (item) => <div>
                            //         {/*{new Intl.NumberFormat().format(item.spending) + ' руб.'}*/}
                            //         <MoneyField style={{color: item.type === 'План' && isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.spending} /*onChange={(e) => changeFinance('estimatedSummarySpending', e.target.value)}*/ disabled={true} />
                            //         {/*{estSpendsChanged && estSpendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(estSpendsBefore)} руб.</div> : <></>}*/}
                            //         {/*{spendsChanged && spendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(spendsBefore)} руб.</div> : <></>}*/}
                            //     </div>,
                            // },
                            // {
                            //     key: 'margin',
                            //     name: 'Маржа',
                            //     minWidth: 180,
                            //     maxWidth: 180,
                            //     isRowHeader: true,
                            //     data: 'string',
                            //     onRender: (item) => <div style={{backgroundColor: '#f3f2f1', color: item.type === 'План' && isEstimatedMarginTooLow ? 'red' : 'black', display: 'flex', justifyContent: 'space-between'}}>
                            //         <MoneyField style={{color: item.type === 'План' && isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.margin ?? 0} disabled={true} /><div style={{paddingTop: 6, height: 26, width: 70, display: 'flex', justifyContent: 'center'}}>{(item.percent ?? 0) + '%'}</div>
                            //         {/*<div>{(item.margin ? new Intl.NumberFormat().format(item.margin) : 0) + ' руб.'}</div><div>{'(' + (item.percent ?? 0) + '%)'}</div>*/}
                            //         {/*<MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} label="Маржа план руб." value={project.data.finance.estimatedSummaryMargin} onChange={(e) => changeFinance('estimatedSummaryMargin', e.target.value)} disabled={true} />*/}
                            //     </div>,
                            // },
                        ]}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.id}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        className="editableTable noMarginTop"
                    />
                </div>
                {project.data.finance.summaryPriceHistory.length > 0 && <div style={{width: 640}}>
                    <div className="ms-fontWeight-semibold" style={{marginBottom: 10}}>Изменение суммы контракта</div>
                    <DetailsList
                        styles={{ root: { paddingTop: 0 }, contentWrapper: { maxHeight: 132, overflowX: 'hidden' } }}
                        items={project.data.finance.summaryPriceHistory}
                        compact={false}
                        columns={[
                            {
                                key: 'name',
                                name: 'Причина',
                                minWidth: 200,
                                maxWidth: 200,
                                isRowHeader: true,
                                data: 'string',
                                onRender: (item) => <div style={{paddingLeft: 10, display: 'flex', alignItems: 'center', height: 32, backgroundColor: '#f3f2f1'}}>{item.name}</div>,
                            },
                            // {
                            //     key: 'delta',
                            //     name: 'Изменение',
                            //     minWidth: 100,
                            //     maxWidth: 100,
                            //     isRowHeader: true,
                            //     data: 'string',
                            //     onRender: (item) => <div style={{paddingLeft: 10, display: 'flex', alignItems: 'center', height: 30}}>{item.direction === 'up' ? <div style={{color: 'green'}}>+{new Intl.NumberFormat().format(item.delta)}</div> : <div style={{color: 'red'}}>-{new Intl.NumberFormat().format(item.delta)}</div>}</div>,
                            // },
                            {
                                key: 'oldSummaryPrice',
                                name: 'Старая сумма',
                                minWidth: 110,
                                maxWidth: 110,
                                isRowHeader: true,
                                data: 'string',
                                onRender: (item) => <MoneyField value={item.oldSummaryPrice} disabled={true} />,
                            },
                            {
                                key: 'newSummaryPrice',
                                name: 'Новая сумма',
                                minWidth: 110,
                                maxWidth: 110,
                                isRowHeader: true,
                                data: 'string',
                                onRender: (item) => <MoneyField value={item.newSummaryPrice} disabled={true} style={{color: item.direction === 'up' ? '#1b5700' : '#5f0000'}} />,
                            },
                            {
                                key: 'date',
                                name: 'Дата изменения',
                                minWidth: 140,
                                maxWidth: 140,
                                isRowHeader: true,
                                data: 'string',
                                onRender: (item) => <div style={{paddingLeft: 10, display: 'flex', alignItems: 'center', height: 32, backgroundColor: '#f3f2f1'}}>{formatDate(new Date(item.date))}</div>,
                            },
                        ]}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.id}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        className="editableTable noMarginTop"
                    />
                </div>}
            </div>
        </div>
        {/*<Pivot aria-label="Меню запроса" linkFormat="tabs" styles={{link: {height: 32, marginRight: 10, backgroundColor: 'rgb(248 248 246)'}}}>*/}
        {/*    <PivotItem headerText="Платежи клиента">*/}
                <div style={{marginTop: 20}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 9}}>
                        <div className="ms-fontWeight-semibold">Платежи</div>
                        <Toggle styles={{root: {marginBottom: 0}, pill: {height: 14, width: 32}, thumb: {height: 8, width: 8, borderWidth: 5}, container: {display: 'flex', alignItems: 'center'}}} role="checkbox" onText="Поэтапная оплата" offText="Поэтапная оплата" checked={project.data.finance.isSteppedPayment} onChange={(e, checked) => changeFinance('isSteppedPayment', checked)} />
                    </div>
                    {project.data.finance.prepayment && <PaymentSteps project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} changeFinance={changeFinance} enableToChangePrepaymentDate={enableToChangePrepaymentDate} setEnableToChangePrepaymentDate={setEnableToChangePrepaymentDate} />}
                </div>
            {/*</PivotItem>*/}
            {/*<PivotItem headerText="Раскладка">*/}
                <div style={{marginTop: 20}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5}}>
                        <div className="ms-fontWeight-semibold">Раскладка {(isProject && !canFreeChangeSpendingTablePlan) && <Icon onClick={showSpendingTableInfo} id={spendingTableInfoBtn} style={{ marginLeft: 4, verticalAlign: 'middle', cursor: 'pointer', color: '#ef5021' }} iconName="Info" aria-hidden="true" />}</div>
                        {isSpendingTableInfoVisible && <Callout
                            role="dialog"
                            gapSpace={0}
                            target={`#${spendingTableInfoBtn}`}
                            onDismiss={hideSpendingTableInfo}
                            setInitialFocus
                        >
                            <div style={{padding: 20, paddingBottom: 20, fontSize: 14, width: 500}}>
                                План расходов в раскладке при начале внесения данных нужно закончить в течении 24 часов, далее доступ к изменениям плана и удалению элемента блокируется.<br/><br/>Вы можете изменить заблокированные данные нажав кнопку "Внести изменения".
                            </div>
                        </Callout>}
                        <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                            {canMakeNewSpendingTable && spendingTableId === 'current' && project.data.finance.workers.filter((worker) => {
                                if (worker.planPriceCreatedAt) {
                                    const hoursPassed = Math.abs(Date.now().valueOf() - new Date(worker.planPriceCreatedAt).valueOf()) / 3600000;
                                    return hoursPassed > 24;
                                }
                                return false;
                            }).length > 0 && <ActionButton style={{height: 32}} iconProps={{ iconName: 'Unlock' }} allowDisabledFocus onClick={async () => {await makeNewSpendingTableMethod(project.data.id); reloadData();}}>
                                Внести изменения
                            </ActionButton>}
                            <Dropdown
                                style={{width: 172}}
                                placeholder="Выберите"
                                options={spendingTableHistoryOptions}
                                selectedKey={spendingTableId}
                                onChange={(e, option) => setSpendingTableId(option.key)}
                            />
                        </div>
                    </div>
                    {project.data.finance.workers.length > 0
                        ? <DetailsList
                            styles={{ root: { paddingTop: 0 } }}
                            items={spendingTableId === 'current' ? project.data.finance.workers : project.data.finance.spendingTableHistory.filter((item) => item.id === spendingTableId)[0].workers}
                            compact={false}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            getKey={(item, index) => item.id}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            className="editableTable noMarginTop"
                        />
                        : <></>
                    }
                    <div style={{marginTop: 10}}>
                        <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={async () => {await addFinanceWorkerMethod(project.data.id); reloadData();}}>
                            Добавить пункт
                        </ActionButton>
                    </div>
                </div>
        {/*    </PivotItem>*/}
        {/*</Pivot>*/}
        {/*<Separator/>*/}
        <div style={{marginTop: 40, marginBottom: 0}}>
            <div  className="ms-fontWeight-semibold" style={{ marginBottom: 10 }}>Комментарии</div>
            <Comments project={project} reloadData={reloadData} type="FINANCE" setNotification={setNotification} />
        </div>
    </div>;

    return <Stack horizontal tokens={{ childrenGap: 50 }} style={{paddingTop: 20}}>
        {isShorty
            ? <></>
            : <div style={{marginBottom: 10}}>
                <Stack horizontal tokens={{ childrenGap: 40 }} style={{alignItems: 'flex-start'}}>
                    {/*<div className="ms-fontWeight-semibold">Доходы план</div>*/}
                    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 185 } }}>
                        <MoneyField label="Доходы план" value={project.data.finance.summaryPrice} onChange={(e) => changeFinance('summaryPrice', e.target.value)} disabled={financeDisabled} />
                    </Stack>
                    <div style={{paddingTop: 5}}>
                         <div className="ms-fontWeight-semibold">Доходы факт <div style={{marginTop: 12}}>{new Intl.NumberFormat().format(actualSummaryPrice ?? 0)} руб.</div></div>
                    </div>
                    {/*<Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                    {/*    <MoneyField label="Доходы факт" value={actualSummaryPrice ?? null} disabled={true} />*/}
                    {/*</Stack>*/}
                </Stack>
                {/*<Separator />*/}
            </div>
        }
        {/*<div>*/}
        {/*    <div className="ms-fontWeight-semibold">Платежи</div>*/}
        {/*    <div>*/}
        {/*        {project.data.finance.prepayment && <PaymentSteps project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} changeFinance={changeFinance} enableToChangePrepaymentDate={enableToChangePrepaymentDate} setEnableToChangePrepaymentDate={setEnableToChangePrepaymentDate} />}*/}
        {/*    </div>*/}
        {/*</div>*/}
        <Stack horizontal tokens={{ childrenGap: 30 }}>
            <div>
                {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>{project.data.finance.prepayment.title ?? 'Предоплата'}</div>*/}
                {/*<Stack tokens={{ childrenGap: 10 }}>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <TextField label="Название" value={project.data.finance.prepayment.title ?? 'Предоплата'} disabled={true} style={{color: 'black'}} />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <Dropdown*/}
                {/*            placeholder="Выберите"*/}
                {/*            label="Тип"*/}
                {/*            options={[*/}
                {/*                { key: 'ONLINE', text: 'Онлайн' },*/}
                {/*                { key: 'CLASSIC', text: 'По документам' },*/}
                {/*            ]}*/}
                {/*            selectedKey={project.data.finance.prepayment.type}*/}
                {/*            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}*/}
                {/*            styles={{ title: {color: 'black'}}}*/}
                {/*            onChange={(e, option) => changeFinance('prepaymentType', option.key)}*/}
                {/*        />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <MoneyField label="Сумма предоплаты" value={project.data.finance.prepayment.sum} disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'} onChange={(e) => changeFinance('prepaymentSum', e.target.value)} />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <Dropdown*/}
                {/*            placeholder="Выберите"*/}
                {/*            label="Статус"*/}
                {/*            options={[*/}
                {/*                { key: 'NOT_NEED', text: 'Не нужна' },*/}
                {/*                { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },*/}
                {/*                { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },*/}
                {/*                { key: 'VERIFYING', text: 'Клиент сказал, что оплатил, проверяем' },*/}
                {/*                { key: 'PAID', text: 'Оплачено' },*/}
                {/*            ]}*/}
                {/*            selectedKey={project.data.finance.prepayment.status}*/}
                {/*            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}*/}
                {/*            styles={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' ? { title: {color: 'black'}} : {}}*/}
                {/*            onChange={(e, option) => changeFinance('prepaymentStatus', option.key)}*/}
                {/*        />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        {project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' && !enableToChangePrepaymentDate*/}
                {/*            ? <div onClick={() => setEnableToChangePrepaymentDate(true)}><TextField label="Дата оплаты" value={formatDate(new Date(project.data.finance.prepayment.paidAt))} disabled={true} style={{color: 'black'}} /></div>*/}
                {/*            : <DatePicker*/}
                {/*                firstDayOfWeek={DayOfWeek.Monday}*/}
                {/*                showWeekNumbers={false}*/}
                {/*                firstWeekOfYear={1}*/}
                {/*                showMonthPickerAsOverlay={true}*/}
                {/*                placeholder="Выберите дату"*/}
                {/*                ariaLabel="Выберите дату"*/}
                {/*                label="Дата оплаты"*/}
                {/*                strings={datePickerStringsRu}*/}
                {/*                formatDate={formatDate}*/}
                {/*                value={project.data.finance.prepayment.paidAt !== null ? new Date(project.data.finance.prepayment.paidAt) : null}*/}
                {/*                onSelectDate={(date) => changeFinance('prepaymentPaidAt', date.toDateString())}*/}
                {/*            />*/}
                {/*        }*/}
                {/*        {project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status !== 'PAID'*/}
                {/*            && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.prepayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />*/}
                {/*        }*/}
                {/*    </Stack>*/}
                {/*</Stack>*/}
            </div>
            {/*{project.data.finance.postPayment.status === 'NOT_NEED'*/}
            {/*    ? <></>*/}
            {/*    : <>*/}
            {/*        <Separator vertical />*/}
            {/*        <div>*/}
            {/*            /!*<div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>{project.data.finance.postPayment.title ?? 'Постоплата'}</div>*!/*/}
            {/*            <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>*/}
            {/*                <ActionButton style={{position: 'absolute', top: -20}} iconProps={{ iconName: 'Delete' }} allowDisabledFocus onClick={() => changeFinance('postPaymentStatus', 'NOT_NEED')} />*/}
            {/*            </div>*/}
            {/*            <Stack tokens={{ childrenGap: 10 }}>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <TextField label="Название" value={project.data.finance.postPayment.title ?? 'Постоплата'} disabled={true} style={{color: 'black'}} />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <Dropdown*/}
            {/*                        placeholder="Выберите"*/}
            {/*                        label="Тип"*/}
            {/*                        options={[*/}
            {/*                            { key: 'ONLINE', text: 'Онлайн' },*/}
            {/*                            { key: 'CLASSIC', text: 'По документам' },*/}
            {/*                        ]}*/}
            {/*                        selectedKey={project.data.finance.postPayment.type}*/}
            {/*                        styles={{ textField: {color: 'black'}}}*/}
            {/*                        onChange={(e, option) => changeFinance('postPaymentType', option.key)}*/}
            {/*                    />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <MoneyField label="Сумма постоплаты" value={project.data.finance.postPayment.sum} onChange={(e) => changeFinance('postPaymentSum', e.target.value)} />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <Dropdown*/}
            {/*                        placeholder="Выберите"*/}
            {/*                        label="Статус"*/}
            {/*                        options={[*/}
            {/*                            { key: 'NOT_NEED', text: 'Не нужна' },*/}
            {/*                            { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },*/}
            {/*                            { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },*/}
            {/*                            { key: 'VERIFYING', text: 'Клиент сказал, что оплатил, проверяем' },*/}
            {/*                            { key: 'PAID', text: 'Оплачено' },*/}
            {/*                        ]}*/}
            {/*                        selectedKey={project.data.finance.postPayment.status}*/}
            {/*                        onChange={(e, option) => changeFinance('postPaymentStatus', option.key)}*/}
            {/*                    />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <DatePicker*/}
            {/*                        firstDayOfWeek={DayOfWeek.Monday}*/}
            {/*                        showWeekNumbers={false}*/}
            {/*                        firstWeekOfYear={1}*/}
            {/*                        showMonthPickerAsOverlay={true}*/}
            {/*                        placeholder="Выберите дату"*/}
            {/*                        ariaLabel="Выберите дату"*/}
            {/*                        label="Дата оплаты"*/}
            {/*                        strings={datePickerStringsRu}*/}
            {/*                        formatDate={formatDate}*/}
            {/*                        value={project.data.finance.postPayment.paidAt !== null ? new Date(project.data.finance.postPayment.paidAt) : null}*/}
            {/*                        onSelectDate={(date) => changeFinance('postPaymentPaidAt', date.toDateString())}*/}
            {/*                        disabled={project.data.finance.postPayment.status === 'NOT_NEED'}*/}
            {/*                    />*/}
            {/*                    {project.data.finance.postPayment.type === 'ONLINE' && project.data.finance.postPayment.status !== 'PAID'*/}
            {/*                        && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.postPayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />*/}
            {/*                    }*/}
            {/*                </Stack>*/}
            {/*            </Stack>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*}*/}
            {/*<PaymentSteps project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} />*/}
        </Stack>
        {isShorty
            ? <></>
            : <>
                {/*<Separator />*/}
                <div style={{marginTop: 10}}>
                    <div className="ms-fontWeight-semibold" style={{ marginBottom: 6 }}>Расходы</div>
                    <div style={{fontSize: 14, marginTop: 3, marginBottom: 16, marginLeft: 0, fontWeight: 400, color: 'gray'}}>
                        Расходы считаются автоматически когда вносите данные в раскладку.
                        {isEstimatedMarginTooLow && <>
                            <br /><span style={{color: 'red'}}>Маржа должна быть выше 40%</span>
                        </>}
                        {/*<br/>Если вы случайно изменили начальное и корректное значение расходов, оно высветиться ниже - снова впишите его, чтобы вернуть.*/}
                    </div>
                    {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 0, fontWeight: '600' }}>План</div>*/}
                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                        <Stack styles={{ root: { width: 300 } }}>
                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} label="Расходы план" value={project.data.finance.estimatedSummarySpending} onChange={(e) => changeFinance('estimatedSummarySpending', e.target.value)} disabled={true} />
                            {estSpendsChanged && estSpendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(estSpendsBefore)} руб.</div> : <></>}
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} label="Маржа план руб." value={project.data.finance.estimatedSummaryMargin} onChange={(e) => changeFinance('estimatedSummaryMargin', e.target.value)} disabled={true} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <TextField label="Маржа план %" type="number" suffix="%" value={project.data.finance.estimatedMarginPercent} onChange={(e) => changeFinance('estimatedMarginPercent', e.target.value)} disabled={true} style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} />
                        </Stack>
                    </Stack>
                </div>
                <div>
                    {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 0, fontWeight: '600' }}>Факт</div>*/}
                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField label="Расходы факт" value={project.data.finance.summarySpending} onChange={(e) => changeFinance('summarySpending', e.target.value)} disabled={true} />
                            {spendsChanged && spendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(spendsBefore)} руб.</div> : <></>}
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField label="Маржа факт руб." value={project.data.finance.summaryMargin} onChange={(e) => changeFinance('summaryMargin', e.target.value)} disabled={true} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <TextField label="Маржа факт %" type="number" suffix="%" value={project.data.finance.marginPercent} onChange={(e) => changeFinance('marginPercent', e.target.value)} disabled={true} style={{color: 'black'}} />
                        </Stack>
                    </Stack>
                </div>
                {/*<Separator />*/}
                <div style={{marginTop: 40, marginBottom: 50}}>
                    <div style={{ fontSize: FontSizes.size14, marginBottom: 10, fontWeight: 'bold' }}>Комментарии</div>
                    <Comments project={project} reloadData={reloadData} type="FINANCE" setNotification={setNotification} />
                </div>
            </>
        }
    </Stack>;
}
