export const InitialProjectData = {
    id: null,
    teamId: null,
    code: null,
    status: 'IN_PROGRESS',
    contact: {
        name: null,
        firstName: null,
        lastName: null,
        middleName: null,
        email: null,
        phoneNumber: null,
        telegramUsername: null,
        instagramUsername: null,
    },
    company: {
        type: null,
        status: null,
        name: null,
        taxIdentificationNumber: null, // ИНН
        registrationReasonCode: null, // КПП
        mainRegistrationNumber: null, // ОГРН
        organizationsClassifierCode: null, // ОКПО
        legalAddress: null,
        mailingAddress: null,
        bankName: null,
        bandIdentificationCode: null, // БИК
        checkingAccount: null,
        correspondentAccount: null,
        entrepreneurName: null,
        managerName: null,
        managerJobTitle: null,
        registrationDate: null,
    },
    finance: {
        status: null,
        summaryPrice: null,
        paid: null,
        leftToPay: null,
        summaryPriceSubtractCommission: null,
        summarySpending: null,
        spendingPercent: null,
        estimatedSummarySpending: null,
        estimatedSpendingPercent: null,
        summaryMargin: null,
        marginPercent: null,
        estimatedSummaryMargin: null,
        estimatedMarginPercent: null,
        isSteppedPayment: false,
        commission: {
            percent: null,
            sum: null,
        },
        prepayment: {
            title: null,
            type: null,
            status: null,
            percent: null,
            sum: null,
            onlinePayment: null,
            statusHistory: null,
            paidAt: null,
        },
        postPayment: {
            title: null,
            type: null,
            status: null,
            percent: null,
            sum: null,
            onlinePayment: null,
            statusHistory: null,
            paidAt: null,
        },
        workers: [],
        paymentSteps: [],
        spendingTableHistory: [],
        summaryPriceHistory: [],
    },
    participants: [
        {
            type: 'MANAGER',
            userId: null,
        }
    ],
    comments: [

    ],
    startedAt: null,
    presentationDate: null,
    dueDate: null,
    services: [
        {
            name: null,
        }
    ],
    files: [
        {
            id: null,
            name: null,
            type: 'COMMERCIAL_OFFER',
            status: 'ATTACHED',
            sizeInBytes: null
        }
    ],
    links: [],
    hasCustomerCabinet: false,
    hasInCustomerCabinet: false,
    customerCabinetEmail: '',
    freelancerScores: [],
    subprojects: [],
};
