import * as React from "react";
import { useState } from "react";
import {Breadcrumb, Pivot, PivotItem} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import Notification from "../../notifications/Notification";
import PrepaidProjects from "./PrepaidProjects/PrepaidProjects";
import WaitingClassicPaymentProjects from "./WaitingClassicPaymentProjects/WaitingClassicPaymentProjects";
import WaitingClosingProjects from "./WaitingClosingProjects/WaitingClosingProjects";
import OnlinePayments from "./OnlinePayments/OnlinePayments";
import {useNavigate} from "react-router-dom";

export default function FinanceMainSection() {
    const [activePivot, setActivePivot] = useState('paid');
    const [notification, setNotification] = useState({type: 'hidden', text: ''});
    const navigate = useNavigate();

    const hash = window.location.hash && window.location.hash.substring(1);
    if (activePivot !== 'waiting' && hash === 'waiting') {
        setActivePivot('waiting');
    } else if (activePivot !== 'closing' && hash === 'closing') {
        setActivePivot('closing');
    } else if (activePivot !== 'online' && hash === 'online') {
        setActivePivot('online');
    }

    const [breadcrumbs, setBreadcrumbs] = useState([
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Финансы', key: 'finances', href: '/finance', isCurrentItem: true },
    ]);

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 10}}
        />
        <Notification type={notification.type} text={notification.text} />
        <Pivot aria-label="Меню" selectedKey={activePivot} linkFormat="tabs" styles={{link: {height: 32, marginRight: 10, backgroundColor: 'rgb(248 248 246)'}}} onLinkClick={(item) => {
            window.history.pushState('', '/', window.location.pathname);
            setActivePivot(item.props.itemKey);
        }}>
            <PivotItem headerText="Предоплаченные проекты" itemKey="paid">
                <PrepaidProjects />
            </PivotItem>
            <PivotItem headerText="Ожидают оплаты по документам" itemKey="waiting">
                <WaitingClassicPaymentProjects />
            </PivotItem>
            <PivotItem headerText="Подтвердить закрытие проекта" itemKey="closing">
                <WaitingClosingProjects setNotification={setNotification} />
            </PivotItem>
            <PivotItem headerText="Онлайн-платежи" itemKey="online">
               <OnlinePayments />
            </PivotItem>
        </Pivot>
    </>);
};
