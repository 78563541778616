import * as React from "react";
import storeFileMethod from "../../../../api/storeFileMethod";
import {
    ActionButton,
    DetailsList,
    DetailsListLayoutMode, ProgressIndicator,
    SelectionMode,
    Stack,
    TooltipHost
} from "@fluentui/react";
import {FontIcon} from "@fluentui/react/lib/Icon";
import {fileIconClassNames} from "../../../data/fileIconClassNames";
import {getFileIcon} from "../../../data/getFileIcon";
import attachProjectFileMethod from "../../../../api/attachProjectFileMethod";
import removeProjectFileMethod from "../../../../api/removeProjectFileMethod";

export default function Results({userType, project, reloadData, type}) {
    const [percentComplete, setPercentComplete] = React.useState(0);

    const getProjectFiles = () => project.data.files.filter((file) => file.type === type);

    const handleProjectFileChange = async (e) => {
        if (e.target.files) {
            for (const file of e.target.files) {
                let response = await storeFileMethod(file, setPercentComplete);
                response = await attachProjectFileMethod(project.data.id, response.id, type);

                if (response.status === 'ok') {
                    reloadData();
                }
            }
        }
    };

    let projectFilesColumns = [];
    if (userType === 'FREELANCER') {
        projectFilesColumns = [
            {
                key: 'fileType',
                name: 'Тип файла',
                className: fileIconClassNames.fileIconCell,
                iconClassName: fileIconClassNames.fileIconHeaderIcon,
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item) => (
                    <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                        <img src={getFileIcon(item.extension).url} className={fileIconClassNames.fileIconImg} alt={`${item.extension} file icon`} />
                    </TooltipHost>
                ),
            },
            {
                key: 'fileName',
                name: 'Название',
                fieldName: 'name',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.name}</span>,
            },
        ];
    } else {
        projectFilesColumns = [
            {
                key: 'fileType',
                name: 'Тип файла',
                className: fileIconClassNames.fileIconCell,
                iconClassName: fileIconClassNames.fileIconHeaderIcon,
                iconName: 'Page',
                isIconOnly: true,
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item) => (
                    <TooltipHost content={`${item.extension} file`} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                        <img src={getFileIcon(item.extension).url} className={fileIconClassNames.fileIconImg} alt={`${item.extension} file icon`} />
                    </TooltipHost>
                ),
            },
            {
                key: 'fileName',
                name: 'Название',
                fieldName: 'name',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>{item.name}</span>,
            },
            {
                key: 'status',
                name: 'Статус',
                fieldName: 'status',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    return <span style={{cursor: 'pointer'}} onClick={() => window.open(process.env.REACT_APP_API_URL + '/files/' + item.id)}>
                    {item.status === 'ATTACHED'
                        ? item.type === 'PROJECT_FILE' ? <span style={{color: '#ef5021'}}>Видно клиенту в ЛК</span> : <span style={{color: 'rgb(153, 153, 153)'}}>Видно только команде</span>
                        : <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                            <FontIcon aria-label="Accept" iconName="Accept" />
                            <div>Отправлено клиенту</div>
                        </div>}
                </span>
                },
            },
            {
                key: 'action',
                name: 'Действие',
                fieldName: 'name',
                minWidth: 20,
                maxWidth: 20,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    return <FontIcon aria-label="Delete" iconName="Delete" style={{cursor: 'pointer'}} onClick={async () => {
                        await removeProjectFileMethod(project.data.id, item.id);
                        reloadData();
                    }} />
                },
            },
        ];
    }

    const projectsCount = getProjectFiles().length;

    return <Stack tokens={{ childrenGap: 0 }}>
        {/*<div>*/}
            {/*<div style={{fontWeight: 'bold', marginBottom: 10}}>Файлы проекта</div>*/}
            {/*<div style={{color: 'gray'}}>*/}
            {/*    Здесь распологаются только результаты работы и файлы связанные с контентом проекта.*/}
            {/*</div>*/}
        {/*</div>*/}
        {/*<Separator />*/}
        {projectsCount !== 0
            ? <DetailsList
                items={getProjectFiles()}
                compact={false}
                columns={projectFilesColumns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.key}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
                className="noMarginTop noBorderList"
            />
            : <></>
        }
        <div>
            <input id={'projectFileInput' + type} type="file" onChange={handleProjectFileChange} multiple={true} hidden />
            {percentComplete > 0
                ? <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                    {percentComplete === 1
                        ? <ProgressIndicator description="Шаг 2 из 2. Обработка и сохранение файла в системе" />
                        : <ProgressIndicator description="Шаг 1 из 2. Загрузка файла" percentComplete={percentComplete} />
                    }
                </Stack>
                : <ActionButton style={{paddingLeft: 0, marginTop: projectsCount > 0 ? 0 : 5}} iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={() => document.getElementById('projectFileInput' + type).click()}>
                    Добавить файл
                </ActionButton>
            }
        </div>
    </Stack>;
}
