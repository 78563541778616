import * as React from "react";
import {CommandBarButton, DefaultButton, Icon, Stack, TextField} from "@fluentui/react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import DateRangePicker from "../../../Shared/DateRangePicker";
import exportFinancesToExcelMethod from "../../../../api/exportFinancesToExcelMethod";
import {useEffect, useState} from "react";
import getUserCollectionMethod from "../../../../api/getUserCollectionMethod";
import {useBoolean, useId} from "@fluentui/react-hooks";

const dropdownStyles = {
    dropdown: { width: 185 },
};

export default function Filters({filterSelection, changeFilter}) {
    const [managers, setManagers] = useState({loaded: false, data: []});

    const [isFilterVisible, { toggle: toggleIsFilterVisible }] = useBoolean(localStorage.getItem('FINANCE_FILTER_VISIBLE') === '1');
    const filterButtonId = useId('filter-button');

    useEffect(() => {
        if (!managers.loaded) {
            getUserCollectionMethod('MANAGER').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setManagers({loaded: true, data: response});
            });
        }
    });

    const managersList = managers.data.map((item) => {
        return {key: item.id, text: item.firstName + ' ' + (item.lastName ?? '')}
    });
    managersList.unshift({key: 'ALL', text: 'Все'});

    const filters = {text: '', managers: managersList, artdirs: [], statuses: [
            { key: 'ALL', text: 'Все статусы' },
            { key: 'IN_PROGRESS', text: 'IN PROGRESS' },
            { key: 'HOLD', text: 'HOLD' },
            { key: 'DONE', text: 'CLOSED' },
        ], milestones: [], sorting: [
            { key: 'START_DATE_NEW', text: 'Дата оплаты', data: { icon: 'ChevronDownSmall' } },
            { key: 'START_DATE_OLD', text: 'Дата оплаты', data: { icon: 'ChevronUpSmall' } },
        ]};

    const onRenderTitle = (options) => {
        const option = options[0];

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {option.data && option.data.icon && (
                    <Icon style={{ marginRight: '8px', fontSize: 10 }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    const onRenderOption = (option) => {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {option.data && option.data.icon && (
                    <Icon style={{ marginRight: '8px', fontSize: 10 }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    return (<>
        <Stack horizontal  style={{justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 10, marginTop: 20}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                <TextField style={{width: 185}} value={filterSelection.text ?? null} onChange={(e) => changeFilter({...filterSelection, text: e.target.value})} iconProps={{ iconName: 'Search', style: {color: 'gray'} }} />
                <CommandBarButton id={filterButtonId} onClick={() => {
                    localStorage.setItem('FINANCE_FILTER_VISIBLE', isFilterVisible ? '0' : '1');
                    toggleIsFilterVisible();
                }} iconProps={{ iconName: isFilterVisible ? 'ChevronRight' : 'ChevronDown', style: {fontSize: 12, transition: '0.5s'} }} style={{height: 32}} text="Фильтры" />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                <CommandBarButton onClick={() => exportFinancesToExcelMethod(filterSelection.startedAtFrom, filterSelection.startedAtTo, filterSelection.status, filterSelection.manager, filterSelection.services ?? [])} iconProps={{ iconName: 'ExcelDocument' }} style={{height: 32}} text="Excel экспорт" />
                <Dropdown
                    options={filters.sorting}
                    onRenderTitle={onRenderTitle}
                    onRenderOption={onRenderOption}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.sorting}
                    iconProps={{ iconName: 'Sort', style: {color: 'gray'} }}
                    onRenderCaretDown={() => <Icon iconName="Sort" style={{color: 'gray'}} />}
                    onChange={(e, option) => changeFilter({...filterSelection, sorting: option.key})}
                />
            </Stack>
        </Stack>
        {isFilterVisible && <Stack tokens={{ childrenGap: 5 }} style={{marginBottom: 20}}>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <DateRangePicker
                    label="Дата оплаты"
                    onChange={(dateFrom, dateTo, fastSelectButtonId) => changeFilter({...filterSelection, startedAtFrom: dateFrom, startedAtTo: dateTo, startedAtFastButtonId: fastSelectButtonId})}
                    defaultDateFrom={filterSelection.startedAtFrom}
                    defaultDateTo={filterSelection.startedAtTo}
                    defaultSelectedButtonId={filterSelection.startedAtFastButtonId}
                />
                <Dropdown
                    placeholder="Все статусы"
                    label="Статус проекта"
                    options={filters.statuses}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.status}
                    onChange={(e, option) => changeFilter({...filterSelection, status: option.key})}
                />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Dropdown
                    placeholder="Все услуги"
                    label="Услуги"
                    selectedKeys={filterSelection.services}
                    multiSelect
                    options={[
                        { key: 'Бренд-платформа', text: 'Бренд-платформа' },
                        { key: 'Название', text: 'Название' },
                        { key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль' },
                        { key: 'Дизайн упаковки', text: 'Дизайн упаковки' },
                        { key: 'Дизайн сайта', text: 'Дизайн сайта' },
                        { key: 'Разработка сайта', text: 'Разработка сайта' },
                        { key: 'Дизайн презентации', text: 'Дизайн презентации' },
                        { key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю' },
                        { key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей' },
                        { key: 'PR', text: 'PR' },
                    ]}
                    styles={dropdownStyles}
                    onChange={(e, option) => changeFilter({...filterSelection, services: option.selected ? [...(filterSelection.services ?? []), option.key] : (filterSelection.services ?? []).filter(key => key !== option.key)})}
                />
                <Dropdown
                    placeholder="Все"
                    label="Менеджер"
                    options={filters.managers}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.manager}
                    onChange={(e, option) => changeFilter({...filterSelection, manager: option.key})}
                />
            </Stack>
        </Stack>}
    </>);

    // return <Stack style={{marginBottom: 10, marginTop: 20}} tokens={{ childrenGap: 5 }}>
    //     <Stack horizontal tokens={{ childrenGap: 20 }}>
    //         {/*<Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 390 } }}>*/}
    //         {/*    <TextField label="Поиск по проектам" value={filterSelection.text ?? null} onChange={(e) => changeFilter({...filterSelection, text: e.target.value})} />*/}
    //         {/*</Stack>*/}
    //         {/*<Dropdown*/}
    //         {/*    placeholder="Все статусы"*/}
    //         {/*    label="Статус проекта"*/}
    //         {/*    options={filters.statuses}*/}
    //         {/*    styles={dropdownStyles}*/}
    //         {/*    selectedKey={filterSelection.status}*/}
    //         {/*    onChange={(e, option) => changeFilter({...filterSelection, status: option.key})}*/}
    //         {/*/>*/}
    //         {/*<Dropdown*/}
    //         {/*    placeholder="Сначала новые"*/}
    //         {/*    label="Сортировка"*/}
    //         {/*    options={filters.sorting}*/}
    //         {/*    onRenderTitle={onRenderTitle}*/}
    //         {/*    onRenderOption={onRenderOption}*/}
    //         {/*    styles={dropdownStyles}*/}
    //         {/*    selectedKey={filterSelection.sorting}*/}
    //         {/*    onChange={(e, option) => changeFilter({...filterSelection, sorting: option.key})}*/}
    //         {/*/>*/}
    //     </Stack>
    //     <Stack horizontal tokens={{ childrenGap: 20 }}>
    //         {/*<DateRangePicker*/}
    //         {/*    label="Дата оплаты"*/}
    //         {/*    onChange={(dateFrom, dateTo, fastSelectButtonId) => changeFilter({...filterSelection, startedAtFrom: dateFrom, startedAtTo: dateTo, startedAtFastButtonId: fastSelectButtonId})}*/}
    //         {/*    defaultDateFrom={filterSelection.startedAtFrom}*/}
    //         {/*    defaultDateTo={filterSelection.startedAtTo}*/}
    //         {/*    defaultSelectedButtonId={filterSelection.startedAtFastButtonId}*/}
    //         {/*/>*/}
    //         {/*<Dropdown*/}
    //         {/*    placeholder="Все услуги"*/}
    //         {/*    label="Услуги"*/}
    //         {/*    selectedKeys={filterSelection.services}*/}
    //         {/*    multiSelect*/}
    //         {/*    options={[*/}
    //         {/*        { key: 'Бренд-платформа', text: 'Бренд-платформа' },*/}
    //         {/*        { key: 'Название', text: 'Название' },*/}
    //         {/*        { key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль' },*/}
    //         {/*        { key: 'Дизайн упаковки', text: 'Дизайн упаковки' },*/}
    //         {/*        { key: 'Дизайн сайта', text: 'Дизайн сайта' },*/}
    //         {/*        { key: 'Разработка сайта', text: 'Разработка сайта' },*/}
    //         {/*        { key: 'Дизайн презентации', text: 'Дизайн презентации' },*/}
    //         {/*        { key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю' },*/}
    //         {/*        { key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей' },*/}
    //         {/*        { key: 'PR', text: 'PR' },*/}
    //         {/*    ]}*/}
    //         {/*    styles={dropdownStyles}*/}
    //         {/*    onChange={(e, option) => changeFilter({...filterSelection, services: option.selected ? [...(filterSelection.services ?? []), option.key] : (filterSelection.services ?? []).filter(key => key !== option.key)})}*/}
    //         {/*/>*/}
    //         {/*<Dropdown*/}
    //         {/*    placeholder="Все"*/}
    //         {/*    label="Менеджер"*/}
    //         {/*    options={filters.managers}*/}
    //         {/*    styles={dropdownStyles}*/}
    //         {/*    selectedKey={filterSelection.manager}*/}
    //         {/*    onChange={(e, option) => changeFilter({...filterSelection, manager: option.key})}*/}
    //         {/*/>*/}
    //         {/*<div style={{display: 'flex', alignItems: 'end'}}>*/}
    //         {/*    <DefaultButton style={{width: 185}} text="Экспорт Excel" iconProps={{ iconName: 'ExcelDocument' }} onClick={() => exportFinancesToExcelMethod(filterSelection.startedAtFrom, filterSelection.startedAtTo, filterSelection.status, filterSelection.manager, filterSelection.services)} />*/}
    //         {/*</div>*/}
    //     </Stack>
    // </Stack>;
};
