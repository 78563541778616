import * as React from "react";
import {useEffect, useState} from "react";
import {Breadcrumb, PrimaryButton, Rating, RatingSize, Separator, Stack, TextField} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import addEmployeeMethod from "../../../api/addEmployeeMethod";
import getSpecializationsMethod from "../../../api/getSpecializationsMethod";
import addFreelancerMethod from "../../../api/addFreelancerMethod";
import {FontSizes} from "@fluentui/theme";
import changeUserProfileMethod from "../../../api/changeUserProfileMethod";

const initialForm = {
    contact: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        telegramUsername: null
    },
    profile: {
        specialization: null,
        city: null,
        website: null,
        about: null,
        stars: 0,
        freelanceCard: {
            type: null,
            name: null,
            website: null,
            experience: null,
            specializations: [],
            speedScore: null,
            communicationScore: null,
            projectsCountDone: null,
            projects: null,
            contacts: null,
            lawStatus: null,
            city: null,
            comment: null,
        },
    }
};

export default function AddFreelancer() {
    const [form, setForm] = useState(initialForm);
    const [specializations, setSpecializations] = useState({loaded: false, data: []});
    const navigate = useNavigate();

    const breadcrumbs = [
        { text: 'Исполнители', key: 'freelancers', href: '/freelancers', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Добавить', key: 'freelancer', href: '/freelancers/new', isCurrentItem: true },
    ];

    useEffect(() => {
        if (!specializations.loaded) {
            getSpecializationsMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSpecializations({loaded: true, data: response});
            });
        }
    });

    const submit = () => {
        addFreelancerMethod(form.contact, form.profile).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            navigate('/freelancers/' + response.id);
        });
    };

    return (<>
        {/*<Breadcrumb*/}
        {/*    items={breadcrumbs}*/}
        {/*    maxDisplayedItems={10}*/}
        {/*    ariaLabel="Навигация"*/}
        {/*    overflowAriaLabel="Показать больше"*/}
        {/*    style={{marginBottom: 20}}*/}
        {/*/>*/}
        <div style={{fontSize: '24px', fontWeight: 'bold', marginBottom: 10}}>Добавить исполнителя</div>
        {/*<div style={{fontSize: FontSizes.size20, fontWeight: 600, marginTop: 20, marginBottom: 25, opacity: 0.3}}>Исполнители не имеют доступа к системе</div>*/}
        <Stack horizontal tokens={{ childrenGap: 50 }}>
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                <Dropdown
                    placeholder="Выберите"
                    label="Направление деятельности"
                    options={[
                        {key: 'DESIGNER', text: 'Дизайн'},
                        {key: 'STRATEGIST', text: 'Стратегия'},
                        {key: 'COPYWRITER', text: 'Копирайтинг'},
                    ]}
                    selectedKey={form.profile.specialization}
                    onChange={(e, option) => setForm({...form, profile: {...form.profile, specialization: option.key}})}
                />
                <TextField label="Имя" value={form.contact.firstName} onChange={(e) => setForm({...form, contact: {...form.contact, firstName: e.target.value}})} />
                <TextField label="Фамилия" value={form.contact.lastName} onChange={(e) => setForm({...form, contact: {...form.contact, lastName: e.target.value}})} />
                <TextField label="Телефон" value={form.contact.phoneNumber} onChange={(e) => setForm({...form, contact: {...form.contact, phoneNumber: e.target.value}})} />
                <TextField label="Email" value={form.contact.email} onChange={(e) => setForm({...form, contact: {...form.contact, email: e.target.value}})} />
                <TextField label="Telegram username (без @)" value={form.contact.telegramUsername} onChange={(e) => setForm({...form, contact: {...form.contact, telegramUsername: e.target.value}})} />
            </Stack>
        </Stack>
        <div style={{marginTop: 30}}>
            <PrimaryButton style={{marginTop: 10, height: 40}} text="Добавить" onClick={submit} />
        </div>
    </>);
};
