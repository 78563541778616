import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    Breadcrumb,
    CommandBar,
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode, Shimmer,
    ShimmeredDetailsList, ShimmerElementType,
    Stack
} from "@fluentui/react";
import getProjectCollectionMethod from "../../api/getProjectCollectionMethod";
import getUserCollectionMethod from "../../api/getUserCollectionMethod";
import {FontSizes} from "@fluentui/theme";
import ProjectCollectionFilter from "./ProjectCollectionFilter";
import {FontIcon} from "@fluentui/react/lib/Icon";
import archiveProjectMethod from "../../api/archiveProjectMethod";
import Breadcrumbs from "./ViewProject/Breadcrumbs";
import rearchiveProjectMethod from "../../api/rearchiveProjectMethod";
import Pagination from "../Theme/Pagination";
import {shortMonths} from "../data/shortMonths";
import {months} from "../data/months";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";
import {datePickerStringsRu} from "../data/datePickerStringsRu";
import {useBoolean} from "@fluentui/react-hooks";

export default function ProjectCollection() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const storedFilterSelection = localStorage.getItem('PROJECT_FILTER') ? JSON.parse(localStorage.getItem('PROJECT_FILTER')) : {manager: 'ALL', artdir: 'ALL', status: 'ALL', milestone: 'ALL', startedAtFrom: null, startedAtTo: null, startedAtFastButtonId: null, presentationDateFrom: null, presentationDateTo: null, presentationDateFastButtonId: null, dueDateFrom: null, dueDateTo: null, dueDateFastButtonId: null, sorting: 'START_DATE_NEW', text: '', services: [], isOnlyMyProjects: 0};
    const [filterSelection, setFilterSelection] = useState(searchParams.get('startedAtFrom') || searchParams.get('startedAtTo') || searchParams.get('status') || searchParams.get('lastPaidAtFrom') ? {...storedFilterSelection, lastPaidAtFrom: searchParams.get('lastPaidAtFrom') ? parseInt(searchParams.get('lastPaidAtFrom')) : null, lastPaidAtTo: searchParams.get('lastPaidAtTo') ? parseInt(searchParams.get('lastPaidAtTo')) : null, startedAtFrom: searchParams.get('startedAtFrom') ? parseInt(searchParams.get('startedAtFrom')) : null, startedAtTo: searchParams.get('startedAtTo') ? parseInt(searchParams.get('startedAtTo')) : null, startedAtFastButtonId: null, status: searchParams.get('status') ?? 'ALL'} : {...storedFilterSelection, lastPaidAtFrom: null, lastPaidAtTo: null});

    const [projectCollection, setProjectCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1, sum: 0}});
    const [partdirs, setArtdirs] = useState({loaded: false, data: []});
    const [pmanagers, setManagers] = useState({loaded: false, data: []});
    const [page, setPage] = useState(1);
    const [isArchived, setIsArchived] = useState(false);
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[],status:''},type:''});

    const goBack = async () => {
        setIsArchived(false);
        await reloadCollection(filterSelection, false);
        navigate('/projects');
    };

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
        if (!projectCollection.loaded) {
            getProjectCollectionMethod(
                filterSelection.text,
                filterSelection.status,
                filterSelection.milestone,
                filterSelection.manager,
                filterSelection.artdir,
                filterSelection.startedAtFrom,
                filterSelection.startedAtTo,
                filterSelection.presentationDateFrom,
                filterSelection.presentationDateTo,
                filterSelection.dueDateFrom,
                filterSelection.dueDateTo,
                filterSelection.sorting,
                filterSelection.services,
                page,
                isArchived,
                10,
                filterSelection.lastPaidAtFrom ?? null,
                filterSelection.lastPaidAtTo ?? null,
                filterSelection.isOnlyMyProjects ?? 0
            ).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                //setSearchParams();
                setProjectCollection({loaded: true, data: response});
            });
        }
        if (!partdirs.loaded) {
            getUserCollectionMethod('ART_DIRECTOR').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setArtdirs({loaded: true, data: response});
            });
        }
        if (!pmanagers.loaded) {
            getUserCollectionMethod('MANAGER').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setManagers({loaded: true, data: response});
            });
        }
    });

    const reloadCollection = (freshFilterSelection, archived = false) => {
        getProjectCollectionMethod(
            freshFilterSelection.text,
            freshFilterSelection.status,
            freshFilterSelection.milestone,
            freshFilterSelection.manager,
            freshFilterSelection.artdir,
            freshFilterSelection.startedAtFrom,
            freshFilterSelection.startedAtTo,
            freshFilterSelection.presentationDateFrom,
            freshFilterSelection.presentationDateTo,
            freshFilterSelection.dueDateFrom,
            freshFilterSelection.dueDateTo,
            freshFilterSelection.sorting,
            freshFilterSelection.services,
            page,
            archived,
            10,
            null,
            null,
            freshFilterSelection.isOnlyMyProjects ?? 0
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getProjectCollectionMethod(
            filterSelection.text,
            filterSelection.status,
            filterSelection.milestone,
            filterSelection.manager,
            filterSelection.artdir,
            filterSelection.startedAtFrom,
            filterSelection.startedAtTo,
            filterSelection.presentationDateFrom,
            filterSelection.presentationDateTo,
            filterSelection.dueDateFrom,
            filterSelection.dueDateTo,
            filterSelection.sorting,
            filterSelection.services,
            page,
            isArchived,
            10,
            filterSelection.lastPaidAtFrom ?? null,
            filterSelection.lastPaidAtTo ?? null,
            filterSelection.isOnlyMyProjects ?? 0
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setProjectCollection({loaded: true, data: response});
        });
    };

    let columns;
    if (user.contact.email.length === 0) {
        columns = [];
    } else if (user.type === 'FREELANCER') {
        columns = [
            {
                key: 'code',
                name: 'Номер',
                fieldName: 'code',
                maxWidth: 80,
                minWidth: 80,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
            },
            {
                key: 'startedAt',
                name: 'Дата старта',
                fieldName: 'startedAt',
                maxWidth: 20,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    if (item.startedAt === null) {
                        return <div>не указано</div>;
                    }

                    const date = new Date(item.startedAt);

                    if ((new Date()).getFullYear() === date.getFullYear()) {
                        return <div>{date.getDate()} {datePickerStringsRu.monthDeclinations[date.getMonth()]}</div>;
                    }

                    return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
                }
            },
            {
                key: 'name',
                name: 'Название',
                fieldName: 'name',
                maxWidth: 200,
                minWidth: 140,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    if (item.name === null) {
                        return <div>не указано</div>;
                    }

                    return <div>{item.name}</div>;
                }
            },
            {
                key: 'status',
                name: 'Статус',
                fieldName: 'status',
                minWidth: 110,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    let pillBackgroundColor = '#d3e5ef';
                    let textColor = '#1d3a4c';
                    let circleColor = '#5b97bd';
                    let name = 'IN PROGRESS';

                    if (item.status === 'HOLD') {
                        pillBackgroundColor = '#faeece';
                        textColor = '#857864';
                        circleColor = '#bf923d';
                        name = 'HOLD';
                    } else if (item.status === 'DONE') {
                        pillBackgroundColor = '#dbeddb';
                        textColor = '#213b2d';
                        circleColor = '#6c9b7d';
                        name = 'DONE';
                    } else if (item.status === 'CANCELED') {
                        pillBackgroundColor = '#ffe2dd';
                        textColor = '#611a18';
                        circleColor = '#e16e64';
                        name = 'CANCELED';
                    }

                    return <div style={{
                        backgroundColor: pillBackgroundColor,
                        color: textColor,
                        padding: '2px 8px',
                        borderRadius: 20,
                        // fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                        width: 'fit-content',
                        fontSize: 12
                    }}>
                        <div style={{
                            backgroundColor: circleColor,
                            width: 7,
                            height: 7,
                            borderRadius: 50
                        }}></div>
                        {name}
                    </div>
                }
            },
            {
                key: 'milestone',
                name: 'Этап',
                fieldName: 'milestone',
                maxWidth: 90,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    if (item.milestone === null) {
                        return <div>не указано</div>;
                    }

                    return <div>{item.milestone}</div>;
                }
            },
            {
                key: 'service',
                name: 'Услуги',
                fieldName: 'service',
                maxWidth: 90,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    if (item.services.length === 0) {
                        return <div>не выбраны</div>;
                    }

                    const services = item.services.map((service) => <div>{service.name}</div>);

                    return <div>{services}</div>
                }
            },
            {
                key: 'manager',
                name: 'Менеджер',
                fieldName: 'manager',
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                minWidth: 120,
                isPadded: true,
                onRender: (item) => {
                    if (item.manager === null) {
                        return <div>нет</div>;
                    }

                    return <div>{item.manager}</div>;
                }
            },
            {
                key: 'artdir',
                name: 'Арт-директор',
                fieldName: 'artdir',
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                minWidth: 120,
                isPadded: true,
                onRender: (item) => {
                    if (item.artdir === null) {
                        return <div>нет</div>;
                    }

                    return <div>{item.artdir}</div>;
                }
            },
        ];
    } else {
        columns = [
            {
                key: 'code',
                name: 'Номер',
                fieldName: 'code',
                maxWidth: 80,
                minWidth: 80,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
            },
            {
                key: 'startedAt',
                name: 'Дата старта',
                fieldName: 'startedAt',
                maxWidth: 20,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    if (item.startedAt === null) {
                        return <div>не указано</div>;
                    }

                    const date = new Date(item.startedAt);

                    if ((new Date()).getFullYear() === date.getFullYear()) {
                        return <div>{date.getDate()} {datePickerStringsRu.monthDeclinations[date.getMonth()]}</div>;
                    }

                    return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
                }
            },
            {
                key: 'presentationDate',
                name: 'Дата презентации',
                fieldName: 'presentationDate',
                maxWidth: 80,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    if (item.presentationDate === null) {
                        return <div>не указано</div>;
                    }

                    const date = new Date(item.presentationDate);

                    if ((new Date()).getFullYear() === date.getFullYear()) {
                        return <div>{date.getDate()} {datePickerStringsRu.monthDeclinations[date.getMonth()]}</div>;
                    }

                    return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
                }
            },
            {
                key: 'name',
                name: 'Название',
                fieldName: 'name',
                maxWidth: 200,
                minWidth: 140,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    if (item.name === null) {
                        return <div>не указано</div>;
                    }

                    return <div>{item.name}</div>;
                }
            },
            {
                key: 'status',
                name: 'Статус',
                fieldName: 'status',
                minWidth: 110,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    let pillBackgroundColor = '#d3e5ef';
                    let textColor = '#1d3a4c';
                    let circleColor = '#5b97bd';
                    let name = 'IN PROGRESS';

                    if (item.status === 'HOLD') {
                        pillBackgroundColor = '#faeece';
                        textColor = '#857864';
                        circleColor = '#bf923d';
                        name = 'HOLD';
                    } else if (item.status === 'DONE') {
                        pillBackgroundColor = '#dbeddb';
                        textColor = '#213b2d';
                        circleColor = '#6c9b7d';
                        name = 'DONE';
                    } else if (item.status === 'CANCELED') {
                        pillBackgroundColor = '#ffe2dd';
                        textColor = '#611a18';
                        circleColor = '#e16e64';
                        name = 'CANCELED';
                    }

                    return <div style={{
                        backgroundColor: pillBackgroundColor,
                        color: textColor,
                        padding: '2px 8px',
                        borderRadius: 20,
                        // fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                        width: 'fit-content',
                        fontSize: 12
                    }}>
                        <div style={{
                            backgroundColor: circleColor,
                            width: 7,
                            height: 7,
                            borderRadius: 50
                        }}></div>
                        {name}
                    </div>
                }
            },
            {
                key: 'milestone',
                name: 'Этап',
                fieldName: 'milestone',
                maxWidth: 90,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item) => {
                    if (item.milestone === null) {
                        return <div>не указано</div>;
                    }

                    return <div>{item.milestone}</div>;
                }
            },
            {
                key: 'contact',
                name: 'Контакт',
                fieldName: 'contact',
                maxWidth: 160,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    return <div>{item.contact.name ?? item.contact.firstName}<br/>{item.contact.phoneNumber}<br/>{item.contact.email}
                    </div>
                }
            },
            {
                key: 'service',
                name: 'Услуги',
                fieldName: 'service',
                maxWidth: 90,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item) => {
                    if (item.services.length === 0) {
                        return <div>не выбраны</div>;
                    }

                    const services = item.services.map((service) => <div>{service.name}</div>);

                    return <div>{services}</div>
                }
            },
            {
                key: 'summaryPrice',
                name: 'Стоимость',
                fieldName: 'summaryPrice',
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                minWidth: 120,
                isPadded: true,
                onRender: (item) => {
                    if (!item.summaryPrice) {
                        return <div>не указана</div>;
                    }

                    return <div>{new Intl.NumberFormat().format(item.summaryPrice)} руб.</div>;
                }
            },
            {
                key: 'manager',
                name: 'Менеджер',
                fieldName: 'manager',
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                minWidth: 120,
                isPadded: true,
                onRender: (item) => {
                    if (item.manager === null) {
                        return <div>нет</div>;
                    }

                    return <div>{item.manager}</div>;
                }
            },
            {
                key: 'artdir',
                name: 'Арт-директор',
                fieldName: 'artdir',
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                minWidth: 120,
                isPadded: true,
                onRender: (item) => {
                    if (item.artdir === null) {
                        return <div>нет</div>;
                    }

                    return <div>{item.artdir}</div>;
                }
            },
            {
                key: 'action',
                name: '',
                fieldName: 'name',
                minWidth: 20,
                maxWidth: 20,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: false,
                onRender: (item) => {
                    if (isArchived) {
                        return <div>
                            <FontIcon className="archive" aria-label="Rearchive" iconName="ReturnToSession"
                                      style={{cursor: 'pointer'}} onClick={async () => {
                                await rearchiveProjectMethod(item.id);
                                renewPage(page);
                            }}/>
                        </div>;
                    }

                    return <div>
                        <FontIcon className="archive" aria-label="Archive" iconName="Archive"
                                  style={{cursor: 'pointer'}} onClick={async () => {
                            await archiveProjectMethod(item.id);
                            renewPage(page);
                        }}/>
                    </div>;
                },
            },
        ];
    }

    const projectsCountLastDigit = parseInt(projectCollection.data.count.toString().slice(-1));
    let projectsCountWord = '';
    if (projectsCountLastDigit === 1) {
        projectsCountWord = 'проект';
    } else if (projectsCountLastDigit > 1 && projectsCountLastDigit < 5) {
        projectsCountWord = 'проекта';
    } else {
        projectsCountWord = 'проектов';
    }

    const [breadcrumbs, setBreadcrumbs] = useState([
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Проекты', key: 'projects', href: '/projects', isCurrentItem: true },
    ]);

    return (<>
        {isArchived
            ? <Breadcrumbs id="archived" code="Архив проектов" goBack={goBack} />
            : <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Breadcrumb
                    items={breadcrumbs}
                    maxDisplayedItems={10}
                    ariaLabel="Навигация"
                    overflowAriaLabel="Показать больше"
                    style={{marginBottom: 10}}
                />
                {user.type === 'FREELANCER'
                    ? <></>
                    : <CommandBar
                        styles={{root: {paddingLeft: 0}}}
                        items={[
                            // {
                            //     key: 'add',
                            //     text: 'Добавить проект',
                            //     iconProps: { iconName: 'Add' },
                            //     onClick: () => navigate('/projects/new'),
                            // }
                        ]}
                        farItems={[
                            {
                                key: 'archived',
                                text: 'Архив',
                                ariaLabel: 'Архив',
                                iconOnly: true,
                                iconProps: { iconName: 'Archive' },
                                onClick: async () => {
                                    setIsArchived(true);
                                    await reloadCollection(filterSelection, true);
                                    navigate('/projects/archived');
                                },
                            },
                        ]}
                    />
                }
            </div>
        }
        {isArchived
            ? <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 10}}>
                {/*<CommandBar*/}
                {/*    styles={{root: {paddingLeft: 0}}}*/}
                {/*    items={[{*/}
                {/*        key: 'back',*/}
                {/*        text: 'Назад',*/}
                {/*        iconProps: { iconName: 'ChevronLeft' },*/}
                {/*        onClick: async () => {*/}
                {/*            await reloadCollection(filterSelection, false);*/}
                {/*            navigate('/projects');*/}
                {/*        },*/}
                {/*    }]}*/}
                {/*/>*/}
                {/*<div style={{display: 'flex', alignItems: 'end'}}>*/}
                {/*    <DefaultButton iconProps={{iconName: 'ChevronLeft'}}  text="Назад" onClick={async () => {*/}
                {/*        await reloadCollection(filterSelection, false);*/}
                {/*        navigate('/projects');*/}
                {/*    }} />*/}
                {/*</div>*/}
            </Stack>
            : <ProjectCollectionFilter userType={user.type} artdirs={partdirs.data} managers={pmanagers.data} filterSelection={filterSelection} setFilterSelection={setFilterSelection} reloadCollection={reloadCollection} />
        }
        {!projectCollection.loaded
            ? <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 18 }]} width={300} shimmerColors={{shimmer: '#fafafa'}} />
            : user.type !== 'FREELANCER'
                ? <div>Найдено: <span style={{fontWeight: 600}}>{projectCollection.data.count}</span> {projectsCountWord} на сумму <span style={{fontWeight: 600}}>{new Intl.NumberFormat().format(projectCollection.data.sum)}</span> руб.</div>
                : <div>Найдено: <span style={{fontWeight: 600}}>{projectCollection.data.count}</span> {projectsCountWord}</div>
        }
        {!projectCollection.loaded
            ? <ShimmeredDetailsList
                setKey="items"
                items={[]}
                columns={columns}
                selectionMode={SelectionMode.none}
                enableShimmer={true}
                ariaLabelForShimmer="Загрузка..."
            />
            : projectCollection.data.collection.length === 0
                ? <div style={{marginTop: 20}}><span style={{fontSize: 24, verticalAlign: 'sub', marginRight: 8}}>🤷‍♂️</span>Проектов по заданным фильтрам не найдено</div>
                : <>
                    <DetailsList
                        items={projectCollection.data.collection}
                        compact={false}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        getKey={(item, index) => item.key}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onRenderRow={(props, Row) => {
                            return <div style={{cursor: 'pointer'}} onClick={(e) => {
                                if (e.target.classList.contains('archive')) {
                                    return;
                                }

                                navigate('/projects/' + props.item.id);
                            }}>
                                <Row {...props} />
                            </div>;
                        }}
                    />
                    <div style={{marginTop: 20}}>
                        <Pagination page={page} lastPage={projectCollection.data.lastPage} renewPage={renewPage} />
                    </div>
                </>
        }
    </>);
};
