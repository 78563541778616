import * as React from "react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {CommandBarButton, DefaultButton, Icon, Modal, Stack, TextField} from "@fluentui/react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import exportPCUsersToExcelMethod from "../../../api/exportPCUsersToExcelMethod";
import exportFreelancersToExcelMethod from "../../../api/exportFreelancersToExcelMethod";
import exportProjectsToExcelMethod from "../../../api/exportProjectsToExcelMethod";
import DateRangePicker from "../../Shared/DateRangePicker";
import {useBoolean, useId} from "@fluentui/react-hooks";
import AddRequest from "../../Request/AddRequest";
import AddFreelancer from "./AddFreelancer";

export default function FreelancerCollectionFilter({specializations, filterSelection, setFilterSelection, reloadCollection}) {
    const [filters, setFilters] = useState({loaded: false, specializations: [], types: [{key: 'Проверенный', text: 'Проверенный'},{key: 'Новый', text: 'Новый'}], competencies: []});
    const navigate = useNavigate();

    const [isFilterVisible, { toggle: toggleIsFilterVisible }] = useBoolean(localStorage.getItem('FREELANCE_FILTER_VISIBLE') === '1');
    const filterButtonId = useId('filter-button');

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    filterSelection.competencies = filterSelection.competencies ?? [];

    const specializations2 = [{key: 'ALL', text: 'Все'}, ...specializations.data];

    const dropdownStyles = {
        dropdown: { width: 180 },
    };

    const types = [...filters.types];

    types.unshift({key: 'ALL', text: 'Все'});

    const changeFilter = (newFilterSelection) => {
        localStorage.setItem('FREELANCE_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    return  <>
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={false}
        >
            <div style={{padding: 30}}>
                <AddFreelancer />
            </div>
        </Modal>
        <Stack horizontal  style={{justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 10}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                <TextField style={{width: 185}} value={filterSelection.name ?? null} onChange={(e) => changeFilter({...filterSelection, name: e.target.value})} iconProps={{ iconName: 'Search', style: {color: 'gray'} }} />
                {/*{userType !== 'FREELANCER'*/}
                {/*    ? <Stack horizontal tokens={{ childrenGap: 20 }}>*/}
                {/*        <div style={{display: 'flex', alignItems: 'end', marginTop: 10}}>*/}
                {/*            {filterSelection.isOnlyMyProjects*/}
                {/*                ? <PrimaryButton style={{width: 185}} text="Мои проекты" iconProps={{ iconName: 'UserEvent' }} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 0})} />*/}
                {/*                : <DefaultButton style={{width: 185, borderColor: '#ef5021', color: '#ef5021' }} text="Мои проекты" iconProps={{ iconName: 'UserEvent' }} onClick={() => changeFilter({...filterSelection, isOnlyMyProjects: 1})} />*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </Stack>*/}
                {/*    : <></>*/}
                {/*}*/}

                <CommandBarButton id={filterButtonId} onClick={() => {
                    localStorage.setItem('FREELANCE_FILTER_VISIBLE', isFilterVisible ? '0' : '1');
                    toggleIsFilterVisible();
                }} iconProps={{ iconName: isFilterVisible ? 'ChevronRight' : 'ChevronDown', style: {fontSize: 12, transition: '0.5s'} }} style={{height: 32}} text="Фильтры" />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                <CommandBarButton onClick={showModal} iconProps={{ iconName: 'Add' }} style={{height: 32}} text="Добавить" />
                <CommandBarButton onClick={() => exportFreelancersToExcelMethod()} iconProps={{ iconName: 'ExcelDocument' }} style={{height: 32}} text="Excel экспорт" />
            </Stack>
        </Stack>
        {isFilterVisible && <Stack tokens={{ childrenGap: 5 }} style={{marginBottom: 20}}>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Dropdown
                    placeholder="Все"
                    label="Направление"
                    options={[
                        {key: 'ALL', text: 'Все'},
                        {key: 'DESIGNER', text: 'Дизайн'},
                        {key: 'STRATEGIST', text: 'Стратегия'},
                        {key: 'COPYWRITER', text: 'Копирайтинг'},
                    ]}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.specialization}
                    onChange={(e, option) => changeFilter({...filterSelection, specialization: option.key})}
                />
                <Dropdown
                    placeholder="Не выбрана"
                    label="Специализация"
                    options={[
                        {key: 'арт-директоринг', text: 'Арт-директоринг'},
                        {key: 'айдентика', text: 'Айдентика'},
                        {key: 'упаковка', text: 'Упаковка'},
                        {key: 'web', text: 'WEB'},
                        {key: 'иллюстрация', text: 'Иллюстрация'},
                        {key: 'бренд-стратегия', text: 'Бренд-стратегия'},
                        {key: 'нейминг', text: 'Нейминг'},
                        {key: 'копирайт', text: 'Копирайт'},
                    ]}
                    styles={dropdownStyles}
                    selectedKeys={filterSelection.competencies}
                    onChange={(e, option) => changeFilter({...filterSelection, competencies: option.selected ? [...filterSelection.competencies, option.key] : filterSelection.competencies.filter(key => key !== option.key)})}
                    multiSelect={true}
                />
            </Stack>
        </Stack>}
    </>;

    // return (
    //     <Stack horizontal tokens={{ childrenGap: 20 }} style={{marginBottom: 10}}>
    //         {/*<TextField styles={{root: { width: 250 }}} label="Поиск" value={filterSelection.name} onChange={(e) => changeFilter({...filterSelection, name: e.target.value})} />*/}
    //         {/*<Dropdown*/}
    //         {/*    placeholder="Все"*/}
    //         {/*    label="Направление"*/}
    //         {/*    options={[*/}
    //         {/*        {key: 'ALL', text: 'Все'},*/}
    //         {/*        {key: 'DESIGNER', text: 'Дизайн'},*/}
    //         {/*        {key: 'STRATEGIST', text: 'Стратегия'},*/}
    //         {/*        {key: 'COPYWRITER', text: 'Копирайтинг'},*/}
    //         {/*    ]}*/}
    //         {/*    styles={dropdownStyles}*/}
    //         {/*    selectedKey={filterSelection.specialization}*/}
    //         {/*    onChange={(e, option) => changeFilter({...filterSelection, specialization: option.key})}*/}
    //         {/*/>*/}
    //         {/*<Dropdown*/}
    //         {/*    placeholder="Не выбрана"*/}
    //         {/*    label="Специализация"*/}
    //         {/*    options={[*/}
    //         {/*        {key: 'арт-директоринг', text: 'Арт-директоринг'},*/}
    //         {/*        {key: 'айдентика', text: 'Айдентика'},*/}
    //         {/*        {key: 'упаковка', text: 'Упаковка'},*/}
    //         {/*        {key: 'web', text: 'WEB'},*/}
    //         {/*        {key: 'иллюстрация', text: 'Иллюстрация'},*/}
    //         {/*        {key: 'бренд-стратегия', text: 'Бренд-стратегия'},*/}
    //         {/*        {key: 'нейминг', text: 'Нейминг'},*/}
    //         {/*        {key: 'копирайт', text: 'Копирайт'},*/}
    //         {/*    ]}*/}
    //         {/*    styles={dropdownStyles}*/}
    //         {/*    selectedKeys={filterSelection.competencies}*/}
    //         {/*    onChange={(e, option) => changeFilter({...filterSelection, competencies: option.selected ? [...filterSelection.competencies, option.key] : filterSelection.competencies.filter(key => key !== option.key)})}*/}
    //         {/*    multiSelect={true}*/}
    //         {/*/>*/}
    //
    //         {/*<Dropdown*/}
    //         {/*    placeholder="Все"*/}
    //         {/*    label="Тип"*/}
    //         {/*    options={types}*/}
    //         {/*    styles={dropdownStyles}*/}
    //         {/*    selectedKey={filterSelection.type}*/}
    //         {/*    onChange={(e, option) => changeFilter({...filterSelection, type: option.key})}*/}
    //         {/*/>*/}
    //         {/*<div style={{display: 'flex', alignItems: 'end'}}>*/}
    //         {/*    <DefaultButton iconProps={{iconName: 'Add'}} text="Добавить исполнителя" onClick={() => navigate('/freelancers/new')} />*/}
    //         {/*</div>*/}
    //         {/*<div style={{display: 'flex', alignItems: 'end'}}>*/}
    //         {/*    <DefaultButton text="Экспорт Excel" iconProps={{ iconName: 'ExcelDocument' }} onClick={() => exportFreelancersToExcelMethod()} />*/}
    //         {/*</div>*/}
    //     </Stack>
    // );
};
