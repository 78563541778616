import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {
    CommandBarButton,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    SelectionMode, Stack
} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import getPCUserCollectionMethod from "../../../api/getPCUserCollectionMethod";
import {datePickerStringsRu} from "../../data/datePickerStringsRu";
import Pagination from "../../Theme/Pagination";
import exportPCUsersToExcelMethod from "../../../api/exportPCUsersToExcelMethod";
import exportProjectsToExcelMethod from "../../../api/exportProjectsToExcelMethod";
import {Dropdown} from "@fluentui/react/lib/Dropdown";

export default function PCUserCollection() {
    const navigate = useNavigate();
    const [PCUserCollection, setPCUserCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (!PCUserCollection.loaded) {
            getPCUserCollectionMethod(page).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setPCUserCollection({loaded: true, data: response});
            });
        }
    });

    const renewPage = (page) => {
        setPage(page);
        getPCUserCollectionMethod(page).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setPCUserCollection({loaded: true, data: response});
        });
    };

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    }

    const columns = [
        {
            key: 'name',
            name: 'Имя',
            minWidth: 100,
            maxWidth: 220,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                let name = item.contact.firstName;

                if (item.contact.lastName !== null) {
                    name += ' ' + item.contact.lastName;
                }

                if (item.profile && item.profile.avatarId) {
                    return <Persona imageUrl={process.env.REACT_APP_API_URL + '/files/' + item.profile.avatarId} text={name} size={PersonaSize.size32} />
                }

                return <Persona text={name} size={PersonaSize.size32} />
            }
        },
        {
            key: 'email',
            name: 'Email',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => item.contact.email,
        },
        {
            key: 'phoneNumber',
            name: 'Телефон',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => item.contact.phoneNumber,
        },
        {
            key: 'companyName',
            name: 'Компания',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => item.company && item.company.name ? item.company.name : '',
        },
        {
            key: 'registrationDate',
            name: 'Дата регистрации',
            minWidth: 160,
            maxWidth: 300,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => formatDate(new Date(item.createdAt)),
        },
    ];

    return (<>
        {/*<div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Пользователи ЛК (клиенты)</div>*/}
        <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center', justifyContent: 'space-between'}}>
            <div>Найдено: <span style={{fontWeight: 600}}>{PCUserCollection.data.count}</span> записей</div>
            <CommandBarButton onClick={() => exportPCUsersToExcelMethod()} iconProps={{ iconName: 'ExcelDocument' }} style={{height: 32}} text="Excel экспорт" />
        </Stack>
        <DetailsList
            items={PCUserCollection.data.collection}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.key}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onActiveItemChanged={(item) => navigate('/pc-users/' + item.id)}
            onRenderRow={(props, Row) => {
                return <div style={{cursor: 'pointer'}}>
                    <Row {...props} />
                </div>;
            }}
        />
        <div style={{marginTop: 20}}>
            <Pagination page={page} lastPage={PCUserCollection.data.lastPage} renewPage={renewPage} />
        </div>
    </>);
};
